#ADHICS-main {
  position: relative;
  height: 40rem;
  overflow-x: hidden;
  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/ADHICS/Compliance.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0;

    .row p {
      font-weight: 400;
      font-size: 31px;
      line-height: 1.4;
      margin-left: 75px;
      width: 100%;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      font-size: 7em;
      margin-left: 74px;
      font-weight: 650;
      line-height: 1;
      margin-top: 170px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/ADHICS/pattern.png);
        position: absolute;
        top: -33px;
        left: -53px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }

    &:after {
      content: " ";
      background-image: url(../images/ADHICS/ADHICS-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(+50%) translateY(-50%);
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 2.3em !important;
        margin-top: 280px !important;
        margin-left: 0 !important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
      }

      div {
        padding-left: 25px !important;
      }

      .row p {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        margin-left: 5px !important;
      }
    }

    #left-square {
      &:after {
        top: 41% !important;
        right: 25% !important;
        width: 140px !important;
        height: 200px !important;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

.what-ADHICS {
  color: black;

  .container-xl {
    &:after {
      content: "";
      position: absolute;
      width: 39%;
      height: 115%;
      background-image: url(../images/ADHICS/2.jpeg);
      background-size: cover;
      right: 0;
      top: -40%;
      display: block;
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;
    }
  }

  .content {
    padding-left: 20px;

    h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650;

      .blue {
        color: #1e0094;
      }
    }

    ul {
      list-style: none;
      li {
        position: relative;
        width: 55%;
        font-size: 17px;
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #20a9df;
          left: -15px;
          display: block;
          top: 7px;
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
  }
  @include media-breakpoint-down(sm) {
  }
  @include media-breakpoint-only(xs) {
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 30px !important;

    .content h2 {
      font-size: 35px !important;
      width: 100% !important;
      font-weight: 650 !important;
      padding-left: 0;
    }

    .content ul {
      margin-left: -15px !important;

      li {
        position: relative !important;
        width: 100% !important;
        font-size: 13px !important;
      }
    }

    .container-xl:after {
      display: none !important;
    }
  }
}

#our-adhics {
  .col-md-3 {
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }

  overflow-x: hidden;
  .header {
    h2 {
      color: #1e0094;
      font-weight: 700;
      font-size: 35px;
      margin-bottom: 10px;
    }

    p {
      color: #000 !important;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .card {
    border-radius: 40px;
    background-color: #1e0094 !important;
    color: #20a9df;

    .hr {
      width: 70%;
      background-color: #20a9df;
      border: none;
      height: 2px;
      text-align: left;
      color: #20a9df;
      margin-left: 0 !important;
    }

    h3 {
      font-size: 27px;
      width: 90%;
      font-weight: 600 !important;

      span {
        color: #fff;
      }
    }

    .h32 {
      font-size: 23px !important;
    }

    .h33 {
      font-size: 27px;
      width: 97%;
      span {
        font-size: 24px;
      }
    }

    ul {
      list-style: none; /* Remove default bullets */
      margin-left: -38px;

      li {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
      }
    }

    ul li::before {
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #20a9df; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
  }
  @include media-breakpoint-down(sm) {
  }
  @include media-breakpoint-only(xs) {
    .row .col-md-12 {
      margin-left: 0 !important;
      padding-left: 0 !important;
      p {
        margin-left: 20px !important;
        padding-left: 20px !important;
      }

      h2 {
        margin-left: 20px !important;
        padding-left: 20px !important;
      }
    }

    .col-md-3 {
      margin-bottom: 30px !important;
    }
  }
}

#our-carosal {
  overflow-x: hidden;
  .container-xl {
    padding-top: 50px;
    background: #1e0094;
    transition: all 0.2 ease-in-out;
    border-radius: 60px;

    #carousel-custom-dots {
      list-style: none;
      text-align: center;
      display: flex;
      justify-content: space-evenly;
      position: relative;

      li {
        font-size: 30px !important;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        cursor: pointer;
        z-index: 5;
      }


      @include media-breakpoint-only(xs) {
        padding-left: 0 !important;
      }
    }

    .owl-item {
      height: 285px !important;
      background: rgba(119, 148, 255, 0.2);
      border-radius: 30px !important;
      opacity: 0.5;
    }

    .active {
      .item {
        h4 {
          font-size: 80px;
          font-weight: 600;
          margin-top: 50px !important;
          margin-left: 40px !important;
          color: #20a9df;
        }

        p {
          margin-left: 40px !important;
          font-size: 16px;
          width: 75%;
          color: white;
          font-weight: 400;
        }
      }
    }

    .owl-theme .owl-nav.disabled + .owl-dots {
      position: absolute;
      top: -100px;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      left: -2%;
    }

    .owl-dots {
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 88%;
        height: 1px;
        left: 6%;
        top: 48%;
        background-color: white;
        z-index: 0;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 10%;
        height: 3px;
        left: 6%;
        top: 44%;
        background-color: #20a9df;
        z-index: 0;
      }

      &.f0 {
        &:after {
          width: calc(100% / 8);
        }
      }

      &.f1 {
        &:after {
          width: calc(100% / 8 * 2);
        }
      }

      &.f2 {
        &:after {
          width: calc(100% / 8 * 3);
        }
      }

      &.f3 {
        &:after {
          width: calc(100% / 8 * 4);
        }
      }

      &.f4 {
        &:after {
          width: calc(100% / 8 * 5);
        }
      }

      &.f5 {
        &:after {
          width: calc(100% / 8 * 6);
        }
      }

      &.f6 {
        &:after {
          width: calc(88%);
        }
      }

      .owl-dot {
        span {
          background-color: white;
          width: 10px;
          height: 10px;
          background: #d6d6d6;
          display: block;
          border-radius: 50%;
        }
        &.active {
          span {
            position: relative;
            background: #20a9df !important;
            width: 20px !important;
            left: 50%;
            height: 20px !important;
            top: 50%;
            transform: translate(-50%, -44%);

            &:before {
              content: "";
              width: 30px !important;
              height: 30px !important;
              border-radius: 50% !important;
              position: absolute;
              left: 50%;
              top: 50%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              border: 1px solid white;
            }
          }
        }

        &.active1 {
          span {
            position: relative;
            background: #20a9df !important;
            top: -2px;
            &:before {
              content: "";
              width: 20px;
              height: 20px;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              top: 80%;
              background-color: #20a9df !important;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
            }

            &:after {
              content: "";
              width: 30px;
              height: 30px;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              top: 82%;
              -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              border: 1px solid white;
              z-index: 5;
            }
          }
        }
      }
    }

    .lifecycle {
      color: white;
      h3 {
        padding-left: 46px;
        font-size: 45px;
        font-weight: 600;
        @include media-breakpoint-only(xs) {
          padding-left: 8px !important;
        }
      }
    }
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
  }
  @include media-breakpoint-down(sm) {
  }
  @include media-breakpoint-only(xs) {
    padding-left: 0 !important;

    .owl-carousel {
      padding-left: 0 !important;
    }

    .item p {
      font-size: 14px !important;
    }
  }
}

.owl-item:not(.active) + .owl-item.active {
  opacity: 1 !important;
  background-color: white !important;
  .item {
    h4 {
      font-size: 80px;
      font-weight: 600;
      margin-top: 50px !important;
      margin-left: 40px !important;
      color: #20a9df;
    }

    p {
      margin-left: 40px !important;
      font-size: 18px;
      width: 75%;
      color: #1e0094 !important;
      font-weight: 500;
    }
  }
}

// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #our-adhics {
    .col-md-3 {
      height: 630px !important;
      .card {
        height: 100% !important;
      }
    }
  }
}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #our-adhics {
    .col-md-3 {
      height: 630px !important;
      .card {
        height: 100% !important;
      }
    }
  }
}

.main-2 {
  color: #1e0094 !important;
  margin-bottom: 0.4em !important;
  padding-bottom: 0;
  font-weight: 650;
  font-size: 29px;
  margin-left: -20px;
}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

  #our-adhics {
    .col-md-3 {
      height: 630px !important;
      .card {
        height: 100% !important;
      }
    }
  }
}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}
