#ICT-main {
  position: relative;
  height: 40rem;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/ICT-Professional-Solutions/header.png);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 0 40px 0;

    .row p {
      width: 87%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      margin-left: 74px;
      font-weight: 650;
      font-size: 6em;
      line-height: 0.8;
      margin-top: 300px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -41px;
        left: -58px;
        z-index: -1;
        width: 135px;
        height: 135px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }

    &:after {
      content: " ";
      background-image: url(../images/ICT-Professional-Solutions/ICT-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(+50%) translateY(-50%);
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }
  }

  @include media-breakpoint-only(lg) {
    height: 37rem;
  }

  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 2.3em!important;
        margin-top: 280px!important;
        margin-left: 0!important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
        width: 87%;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        margin-left: 5px !important;
      }

      div {
        padding-left: 25px !important;
      }

      &:after {
        top: 37%!important;
        right: 24%!important;
        width: 190px;
        height: 162px;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

#ict-solutions {
  position: relative;
  height: 1000px;
  padding-top: 300px;
  padding-bottom: 200px;

  .left-square {
    width: 55%;
    border-radius: 75px;
    background-color: #1e0094 !important;
    position: absolute;
    left: 100px;
    padding: 125px;

    h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 650;
      color: #20a9df;

      &:not(:first-of-type) {
        margin-top: 50px;
      }
    }
  }

  .right-square {
    position: absolute;
    z-index: -2;
    top: -153px;
    right: 157px;
    height: 88%;
    width: 32%;
    background-size: cover;
    background-image: url(../images/ICT-Professional-Solutions/up.png);
    background-repeat: no-repeat;
    border-radius: 50px;
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
    .left-square {
      padding: 70px !important;
    }

    .right-square {
      top: -188px;
      right: 100px;
      height: 104%;
      width: 40%;
    }
  }
  @include media-breakpoint-between(sm, md) {
    .left-square {
      padding: 50px !important;
      width: 60% !important;
      left: 40px !important;
    }

    .right-square {
      top: -153px;
      right: 52px;
      height: 88%;
      width: 45%;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 100px;

    .right-square {
      display: none !important;
    }

    .left-square {
      width: 88% !important;
    }
  }
  @include media-breakpoint-only(xs) {
    padding-top: 100px;

    .right-square {
      display: none !important;
    }

    .left-square {
      width: 100% !important;
      left: 0 !important;
      border-radius: 0;
      padding: 35px;
    }
  }

  @include media-breakpoint-only(lg) {
    height: 1214px;
    .left-square {
      padding: 118px !important;
    }
  }
}

#ict-solutions-s2 {
  position: relative;
  height: 1300px;
  padding-top: 300px;
  padding-bottom: 200px;

  .left-square {
    width: 55%;
    border-radius: 100px;
    background-color: #1e0094 !important;
    position: absolute;
    right: 100px;
    padding: 125px;

    h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 650;
      color: #20a9df;

      &:not(:first-of-type) {
        margin-top: 50px;
      }
    }
  }

  .right-square {
    position: absolute;
    z-index: -2;
    top: -153px;
    left: 157px;
    height: 88%;
    width: 32%;
    background-size: cover;
    background-image: url(../images/ICT-Professional-Solutions/down.png);
    background-repeat: no-repeat;
    border-radius: 50px;
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
    .left-square {
      padding: 70px !important;
    }

    .right-square {
      top: -153px;
      right: 100px;
      height: 88%;
      width: 40%;
    }
  }
  @include media-breakpoint-between(sm, md) {
    margin-top: 100px;
    .left-square {
      padding: 50px !important;
      width: 60% !important;
      right: 85px;
    }

    .right-square {
      top: -153px;
      right: 52px;
      height: 88%;
      width: 45%;
    }

    .right-square {
      left: 42px;
    }
  }
  @include media-breakpoint-down(sm) {
    padding-top: 100px;

    .right-square {
      display: none !important;
    }

    .left-square {
      width: 88% !important;
    }
  }
  @include media-breakpoint-only(xs) {
    padding-top: 100px;
    height: 1170px !important;
    padding-bottom: 0 !important;

    .right-square {
      display: none !important;
    }

    .left-square {
      width: 100% !important;
      padding: 25px;
      border-radius: 0 !important;
      left: 0 !important;
    }
  }

  @include media-breakpoint-only(lg) {
    height: 1300px;
    .left-square {
      padding: 118px !important;
      top: 45px;
    }

    .right-square {
      left: 95px;
    }
  }
}

#ict-vendor {
  margin-top: 100px;
}

// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #about-main {
    height: 42rem;
  }

  .mid {
    width: 377px !important;
    height: 88% !important;
  }

  #ict-solutions {
    height: 1300px !important;
  }
}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #industries-main {
    height: 48rem !important;

    #right-square1 {
      width: 600px;

      &:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px;
      }

      &:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px;
      }

      .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }

  #ict-solutions {
    height: 1300px !important;
  }
}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

  #ict-solutions {
    height: 1300px !important;
  }
}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}
