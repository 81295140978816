#meeting {

  .container-xl {

    .meeting__logo {

      img {
        width: 250px !important;
      }

      @include media-breakpoint-only(xs) {

        img {
          width: 160px!important;
        }
      }
    }

    .meeting__quote {
      text-align: center;

      blockquote {
        color: #1e0094;
        line-height: 1.8;
        font-size: 22px;
        font-weight: 600;
      }

      @include media-breakpoint-only(xs) {

        blockquote {
          font-size: 10px !important;
        }

      }
    }

    .meeting__events {
      .event {
        text-align: center;
        img {
          width: 50px;

          @include media-breakpoint-only(xs) {
            width: 38px;
          }
        }

        span {
          color: #d22ebd;
          font-size: 24px;
          text-align: right;
          margin-left: 12%;
          font-weight: 600;

          @include media-breakpoint-only(xs) {
            color: #d22ebd;
            font-size: 16px;
            text-align: right;
            margin-left: 12%;
            font-weight: 600;
          }
        }

        @include media-breakpoint-only(xs) {
          margin-bottom: 15px !important;
        }
      }
    }

    .meeting__button {
      text-align: center;

      button {
        cursor: pointer;
        background: #d22ebd;
        border-radius: 28px;
        color: #fff;
        border: #d22ebd;
        padding: 20px 35px;
        font-size: 27px;
        font-weight: 500;
        outline: none;
        transition: all 0.3s;

        &:hover {
          opacity: 0.8;
        }

        @include media-breakpoint-only(xs) {
          padding: 10px 21px !important;
          font-size: 18px !important;
        }
      }
    }

  }

  @include media-breakpoint-between(md, lg) {
    .meeting__content {
      &:before {
        width: 110px !important;
        height: 110px !important;
      }

      .meeting__content__text {
        left: 79px !important;
        top: 244px !important;

        h2 {
          font-size: 42px !important;
        }
      }
    }


  }

  @include media-breakpoint-between(sm, md) {
    .meeting__content__text {
      p {
        width: 325%;
      }
    }

  }

  @include media-breakpoint-down(sm) {

    .meeting__content {
      height: 32% !important;

      .meeting__content__text {
        position: absolute !important;
        left: 120px !important;
        top: 56px !important;

        h2 {
          font-size: 44px !important;
        }

        p {
          font-size: 14px !important;
          width: 92% !important;
        }
      }

      &:before {
        top: 23px !important;
        left: 45px !important;
        width: 150px !important;
        height: 150px !important;
      }
    }


  }

  @include media-breakpoint-only(xs) {

    .meeting__content {
      height: 46% !important;

      .meeting__content__text {
        position: absolute !important;
        left: 120px !important;
        top: 56px !important;

        h2 {
          font-size: 44px !important;
        }

        p {
          font-size: 14px !important;
          width: 92% !important;
        }
      }


    }


  }
}


// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }


}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }
}

// end big screens media query
@include media-breakpoint-between(md, lg) {}

@include media-breakpoint-between(sm, md) {}

@include media-breakpoint-down(sm) {}

@include media-breakpoint-only(xs) {}