/* start nav bullets */
.nav-bullets {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 1000000000;

  .bullet {
    width: 8px;
    height: 8px;
    margin: 15px auto;
    border-radius: 50%;
    cursor: pointer;
    background-color: #999;
    border: none;
    position: relative;
    transition: 0.2s;

    &.active,
    &:hover {
      background-color: var(--main--color);

      &::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        left: 50%;
        top: -8px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border: 1px dashed #999;
        border-radius: 50%;
      }
    }
  }
}

.nav-bullets .bullet::before {
  content: '';
  border-style: solid;
  border-width: 13px;
  height: 0;
  width: 0;
  position: absolute;
  top: -8px;
  border-color: transparent transparent transparent var(--main--color);
  left: -23px;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s;
}

.nav-bullets .bullet .tooltip {
  background-color: var(--main--color);
  position: absolute;
  width: 110px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  right: 31px;
  padding: 4px 4px;
  border-radius: 5px 0 0 5px;
  top: -8px;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.nav-bullets .bullet:hover .tooltip {
  opacity: 1;
}

.nav-bullets .bullet:hover::before {
  opacity: 1;
}

/* end nav bullets */
