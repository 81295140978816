@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.pointer {
  cursor: pointer;
}
.nav-item:after {
  display: block;
  transform: scaleX(0);
  content: "";
  border-bottom: solid 3px red;
  transition: transform 250ms ease-in-out;
}

.nav-item:hover:after {
  transform: scaleX(1);
}

// .container-fluid{
//   padding-right: 100px;
//   padding-left: 100px;
// }



.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .25rem 0;
  margin: .125rem 0 0;
  font-size: .9rem;
  /* color: #fff; */
  text-align: left;
  list-style: none;
  background-color: $primary;
  background-clip: padding-box;
  border: 1px solid #e4eaf2;
   border-radius: 0rem; 
}

.big-sub-title{
  @include media-breakpoint-down(sm) {
    font-size: 1.7rem;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 4rem;
  }
  //  font-weight: 900;
}

