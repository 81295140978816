.read-more-component {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
  transition: 0.4s;
  cursor: pointer;
  width: 175px;
  display: inline-block;
  position: relative;

  &:hover {
    text-decoration: none;
    color: inherit;

    .read-more__icon__span {
      animation-name: example;
      animation-timing-function: ease-in-out;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;  
      opacity: 1;
    }

    .read-more__divider {
      animation-name: example2;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;  
      animation-duration: 0.5s;
      opacity: 1;
    }
    .read-more__divider {
      animation-name: example3;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;  
      animation-duration: 0.5s;
    }
  }
}

.read-more__divider {
  left: 50%;
  display: block;
  width: 39%;
  height: 1px;
  background-color: #1f96d5;
  bottom: 10px;
  position: absolute;
  opacity: 0;
}

.read-more__icon__span {
  position: absolute;
  left: 75px;
  width: 40px;
  top: -10px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  font-size: 18px;
  transition: .4s;
}

.read-more-component {
  &:hover {
    .read-more__icon__span {
      opacity: 1;
      background-color: #20a9df;
    }

    .read-more__divider {
      opacity: 1;
    }
  }
}

@keyframes example {
  0% {
    opacity: 0;
  }
  100% {
    left: 150px;
    opacity: 1;
  }
}

@keyframes example2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes example3 {
 0% { width: 0;}
 10% { width: 5%;}
 30% { width: 10%;}
 40% { width: 15%;}
 50% { width: 20%;}
 60% { width: 25%;}
 70% { width: 35%;}
 100% { width: 49%;}
}
