.footer {
  background-color: white;
  .row {
    height: 400px;

    div {
      &:nth-child(3) {
        background-color: #1e0094;
        border-radius: 30px 0 0 0;
      }
    }

    .our-partners__left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border-radius: 0 0 50px 0;
      z-index: 1;

      &:after {
        content: " ";
        background-image: url(../images/Homepage/our-solutions/pattern.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: -1;
        width: 35%;
        height: 32%;
        border-radius: 0;
        background-size: contain;
      }

      .our-partners__content__text {
        position: absolute;
        left: 82px;
        top: 50px;

        h2 {
          font-weight: 650;
          font-size: 46px;
          margin-bottom: 8px;
          line-height: 48px;
          color: #1e0094;
        }
      }

      .send-email {
        position: absolute;
        top: 193px;
        left: 45px;
        width: 100%;
        height: 150px;

        form {
          height: 100%;
          width: 90%;

          label {
            color: #000;
            font-size: 20px;
            font-weight: 500;
          }

          input {
            height: 55px;
            padding-left: 22px;
            font-size: 18px;
            border-radius: 10px 0 0 10px;
            background-color: #ebebeb;
            outline: none;
            z-index: 0;
          }

          button {
            background-color: #1e0094;
            width: 130px;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 600;
            margin-left: -10px;
            z-index: 2;
          }
        }
      }
    }

    .as {
      color: black;
      padding: 100px 30px 40px 100px;

      a {
        display: block;
        text-align: left;
        color: black;
        text-decoration: none;
        font-size: 20px;
        margin-bottom: 10px;
        cursor: pointer;

        &.explore {
          font-weight: bold;
          color: #20a9df;
          font-size: 24px;
        }
      }
    }

    .ps {
      color: white;
      padding: 100px 20px 40px 90px;

      h3 {
        font-weight: 700;
        color: #20a9df;
        font-size: 23px;
      }

      p {
        margin-bottom: 0;
        font-size: 18px;
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    overflow: hidden;
    .our-partners__left {
      &:after {
        top: 40px !important;
        left: 40px !important;
        width: 35% !important;
        height: 32% !important;
      }

      .send-email {
        bottom: 55px !important;
        left: 45px !important;
      }
    }

    .as {
      padding: 50px 30px 40px 50px !important;

      a {
        font-size: 20px !important;
        margin-bottom: 15px !important;
      }
    }

    .row div:nth-child(3) {
      border-radius: 30px 0 0 0 !important;
    }

    .ps {
      padding: 50px 20px 40px 40px !important;
      h3 {
        font-size: 23px !important;
        margin-bottom: 42px !important;
      }

      p {
        font-size: 16px !important;
      }
    }

    .our-partners__content__text {
      left: 121px !important;
      top: 74px !important;
    }
  }
  @include media-breakpoint-between(sm, md) {
    .ps {
      padding: 50px 10px 40px 10px !important;

      p {
        font-size: 14px !important;
      }
    }

    .as {
      padding: 50px 10px 40px 27px !important;
    }

    .our-partners__content__text {
      top: 87px !important;

      h2 {
        font-size: 36px !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .footer__get_in {
      height: 400px !important;
    }

    .our-partners__left {
      .our-partners__content__text {
        top: 90px !important;
        left: 139px !important;
        h2 {
          font-size: 46px !important;
          margin-bottom: 8px !important;
        }
      }

      &:after {
        top: 45px !important;
        left: 10px !important;
        width: 35% !important;
        height: 32% !important;
      }

      .send-email {
        left: 50% !important;
        width: 75% !important;
        height: 150px !important;
        transform: translateX(-50%) !important;
      }
    }

    .row div:nth-child(3) {
      border-radius: 0;
    }
  }
  @include media-breakpoint-only(xs) {
    .row div:nth-child(3) {
      padding: 45px !important;
    }

    .row .as {
      padding: 30px 50px !important;
      font-size: 29px !important;
    }

    .our-partners__left {
      padding: 25px !important;
    }

    .row .our-partners__left .send-email form button {
      background-color: #1e0094;
      width: 120px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      margin-left: -10px;
      z-index: 2;
    }

    .row .our-partners__left .send-email form input {
      height: 40px;
      padding-left: 22px;
      font-size: 12px;
      border-radius: 10px 0 0 10px;
      background-color: #ebebeb;
      outline: 0;
      z-index: 0;
    }

    .our-partners__left .send-email {
      width: 100% !important;
      left: 0 !important;
      padding: 25px !important;
      transform: none !important;

      form {
        width: 100% !important;
      }
    }

    .our-partners__left:after {
      top: 45px !important;
      left: 7% !important;
      width: 35% !important;
      height: 32% !important;
    }

    .our-partners__left .our-partners__content__text {
      top: 90px !important;
      left: 80px !important;
    }

    .our-partners__left .our-partners__content__text h2 {
      font-size: 40px !important;
    }
  }
}
