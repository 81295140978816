.my-nav-overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background: #1e0094 !important;
  overflow-x: hidden;
  transition: 0.2s ease-in-out;

  .my-nav-overlay-content {
    height: 79%;
    position: relative;
    top: 13%;
    width: 100%;
    text-align: center;
    margin-top: 30px;

    .container-xl {
      height: 100%;

      .row {
        height: 100%;
        width: 100%;
        padding: 20px;
        margin: 0;
        overflow: hidden;

        .left {
          height: 100%;
          width: 34%;
          border-right: 1px solid #666;
          text-align: left;
          padding-left: 90px;
          padding-top: 35px;
        }

        .right {
          height: 100%;
          width: 66%;
          padding: 0 50px 50px 100px;
          flex-wrap: wrap;

          .item {
            height: 45%;
            padding: 20px 50px;
            text-align: left;
            color: white;

            a {
              font-size: 28px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .left {
    a {
      padding: 5px;
      text-decoration: none;
      font-size: 32px;
      color: #f1f3fa;
      display: block;
      transition: 0.4s;
      width: 260px;
      border-radius: 8px;
      margin-bottom: 5px;

      &:hover {
        background-color: #20a9df;
      }
    }
  }

  .right {
    a {
      padding: 5px;
      text-decoration: none;
      font-size: 32px;
      color: #f1f3fa;
      display: block;
      transition: 0.4s;
      width: 260px;
      margin-bottom: 5px;
      cursor: pointer;
      position: relative;

      &:before {
        position: absolute;
        left: -20px;
        top: 15px;
        font-size: 16px;
        color: #20a9df;
        font-weight: 700;
      }

      &:hover {
        color: #20a9df;
      }
    }

    .item {
      &:nth-child(1) {
        a {
          &:before {
            content: "";
            background-image: url(../images/small-icons/ICT-Professional.png);
            width: 30px;
            height: 30px;
            display: block;
            background-size: contain;
            position: absolute;
            left: -35px;
            background-repeat: no-repeat;
            top: 12px;
          }
        }
      }
      &:nth-child(2) {
        a {
          &:before {
            content: "";
            background-image: url(../images/small-icons/cyber-security.png);
            width: 35px;
            height: 35px;
            display: block;
            background-size: contain;
            position: absolute;
            left: -35px;
            background-repeat: no-repeat;
            top: 12px;
          }
        }
      }
      &:nth-child(3) {
        a {
          &:before {
            content: "";
            background-image: url(../images/small-icons/Advanced.png);
            width: 32px;
            height: 32px;
            display: block;
            background-size: contain;
            position: absolute;
            left: -35px;
            background-repeat: no-repeat;
            top: 12px;
          }
        }
      }
      &:nth-child(4) {
        a {
          &:before {
            content: "";
            background-image: url(../images/small-icons/Outsourcing-HR.png);
            width: 30px;
            height: 30px;
            display: block;
            background-size: contain;
            position: absolute;
            left: -35px;
            background-repeat: no-repeat;
            top: 12px;
          }
        }
      }

      &:nth-child(5) {
        a {
          &:before {
            content: "";
            background-image: url(../images/small-icons/Advanced.png);
            width: 30px;
            height: 30px;
            display: block;
            background-size: contain;
            position: absolute;
            left: -35px;
            background-repeat: no-repeat;
            top: 12px;
          }
        }
      }

      .small-anchor {
        font-size: 16px !important;
        margin-top: -10px !important;
        margin-left: 15px !important;
        &:before {
          display: none !important;
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .my-nav-overlay-content .container-xl .row {
      .left {
        padding-left: 42px;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    .left {
      padding-left: 0 !important;
    }

    .right {
      padding: 71px 2px 50px 0px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .left {
      padding-left: 0 !important;
      width: 50% !important;
    }

    .right {
      padding: 0 2px 50px 0 !important;
      width: 50% !important;
      .item {
        height: 26% !important;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    padding: 0 !important;

    .row {
      padding: 0 !important;
    }

    .left {
      padding-left: 0 !important;
      width: 45% !important;

      a {
        font-size: 20px !important;
        width: 97%;
      }
    }

    .right {
      padding: 0 2px 50px 0 !important;
      width: 55% !important;
      height: 82% !important;

      a {
        font-size: 18px !important;
      }
    }
  }
}

.my-nav-overlay a:hover,
.my-nav-overlay a:focus {
  .left {
    color: #f1f1f1;
  }
}

.my-nav-overlay .logo {
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 60px;
}

.my-nav-overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.my-nav-overlay .menu-img {
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  border: 10px transparent solid;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    margin-left: auto;
  }
}

.my-nav-overlay .menu-img:hover {
  border: 10px white solid;
  transition: 0.5s ease-in-out;
}

.my-nav-overlay .menu-title {
  margin-top: -100px;
  font-size: 2rem;
  transition: 0.3s ease-in-out;
}

@media screen and (max-height: 450px) {
  .my-nav-overlay a {
    font-size: 20px;
  }
  .my-nav-overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.navbar {
  z-index: 2;
  position: absolute;
  width: 100%;
  padding: 3rem 2rem;
  transition: 0.8s ease-in;
  // height: 20%;

  .container-xl {
    z-index: 999999;
  }
}

.smallfont {
  font-size: 1.7rem !important;
}

.estimate-project {
  margin-left: auto;
  margin-right: 50px;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.engagment-link {
  position: absolute;
  bottom: 20px;
  font-size: 1rem !important;
  right: 45px;
  z-index: 1000;
}

.closebtn {
  position: absolute;
  top: 56px;
  right: 45px;
  width: 36px;
  font-size: 38px;
  padding: 0;
  padding: 0 10px 10px 0;
  height: 32px;
  margin: 0;
  display: none;

  &:before {
    margin-top: -72px !important;
    top: 62px !important;
    position: absolute !important;
    top: 0;
    left: 1px;
    font-size: 34px;
  }
}
#main-nav {
  position: fixed !important;
  z-index: 5;
}



.left-hr {
  margin-left: 20% !important;
  height: 42% !important;
  padding: 0 50px !important;
  text-align: left !important;
  color: #fff !important;
}

// end big screens media query
@include media-breakpoint-between(md, lg) {
  
}

@include media-breakpoint-between(sm, md) {}

@include media-breakpoint-down(sm) {
  .left-hr {
    margin-left: 0 !important;
  }
  
}

@include media-breakpoint-only(xs) {
  .left-hr {
    margin-left: 0 !important;
  }
  
}