#industries-main {
  position: relative;
  height: 40rem;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/Our-industries/header.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0;

    .row p {
      width: 60%;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      margin-left: 65px;
      font-weight: 650;
      font-size: 8em;
      line-height: 0.9;
      margin-top: 140px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -53px;
        left: -58px;
        z-index: -1;
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 3.9em!important;
        margin-top: 52px!important;
        margin-left: 0!important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
      }

      div {
        padding-left: 25px !important;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

#industries-desc {
  height: 1100px;

  .row {
    height: 100%;

    #left-square {
      position: relative;
      height: 100%;

      .img1 {
        border-radius: 50px;
        content: "";
        position: absolute;
        left: 50px;
        top: 201px;
        width: 360px;
        height: 620px;
        overflow: hidden;

        // &:before {
        //   content: " ";
        //   background-image: url(../images/Our-industries/box.png);
        //   position: absolute;
        //   top: -16px;
        //   left: 0;
        //   width: 114%;
        //   height: 112%;
        //   background-repeat: no-repeat;
        //   background-size: cover;
        //   z-index: 2;
        //   opacity: .65;
        // }

        &:after {
          content: " ";
          background-image: url(../images/Our-industries/image.png);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .img2 {
        border-radius: 50px;
        content: "";
        position: absolute;
        left: 200px;
        bottom: 298px;
        width: 340px;
        height: 455px;
        overflow: hidden;
        z-index: 2;

        // &:before {
        //   content: " ";
        //   background-image: url(../images/Our-industries/box2.png);
        //   position: absolute;
        //   top: -20px;
        //   left: -16px;
        //   width: 105%;
        //   height: 105%;
        //   background-repeat: no-repeat;
        //   background-size: cover;
        //   z-index: 2;
        //   opacity: 0.65;
        // }

        &:after {
          content: " ";
          background-image: url(../images/Our-industries/image1.png);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    #right-square {
      padding-top: 200px;
      padding-bottom: 200px;
      padding-left: 25px;

      h3 {
        margin-bottom: 0;
        padding-bottom: 0;
        font-weight: 650;
        color: #2196f3;

        &:not(:first-of-type) {
          margin-top: 90px;
        }
      }

      p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        padding: 0;
        margin: 0;
        width: 90%;
      }

      ul {
        padding: 0;
        margin: 0;
        li {
          padding: 0;
          margin: 0;
          margin-left: 20px;
          &::marker {
            color: black;
          }
        }
      }
    }
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {

    #right-square {
      h3:not(:first-of-type) {
        margin-top: 40px !important;
      }

      p {
        font-size: 14px !important;
      }
    }

    #left-square {
      position: relative;
      height: 100%;

      .img1 {
        left: 50px !important;
        top: 100px !important;
        width: 350px !important;
        height: 500px !important;
      }

      .img2 {
        left: 120px !important;
        top: 511px !important;
        width: 350px !important;
        height: 450px !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 1100px !important;

    #right-square {
      padding-top: 100px !important;
      padding-bottom: 200px !important;

      p {
        width: 100%;
      }
    }

    #left-square {
      .img1 {
        left: 20px!important;
        top: 201px!important;
        width: 250px!important;
        height: 380px!important;
    }

      .img2 {
        left: 82px!important;
    top: 476px!important;
    width: 238px!important;
    height: 325px!important;
      }
    }
  }
  @include media-breakpoint-down(sm) {

    #left-square {
      display: none;
    }

    #right-square {
      h3:not(:first-of-type) {
        margin-top: 20px !important;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 1500px !important;
  }
}
// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #about-main {
    height: 42rem;
  }

  .mid {
    width: 377px !important;
    height: 88% !important;
  }

  #industries-desc .row #left-square .img2 {
    position: absolute !important;
    left: 200px !important;
    bottom: 45px !important;
    width: 356px !important;
    height: 505px !important;
  }

  #industries-desc .row #left-square .img1 {
    left: 50px !important;
    top: 201px !important;
    width: 400px !important;
    height: 620px !important;
    border-radius: 50px !important;
  }

  #industries-desc .row #left-square .img1:after {
    width: 112% !important;
  }

}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #industries-main {
    height: 48rem !important;

    #right-square1 {
      width: 600px;

      &:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px;
      }

      &:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px;
      }

      .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }

  #industries-desc .row #left-square .img2 {
    position: absolute !important;
    left: 200px !important;
    bottom: 45px !important;
    width: 356px !important;
    height: 505px !important;
  }

  #industries-desc .row #left-square .img1 {
    left: 50px !important;
    top: 201px !important;
    width: 400px !important;
    height: 620px !important;
    border-radius: 50px !important;
  }

  #industries-desc .row #left-square .img1:after {
    width: 112% !important;
  }

}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

  #industries-desc .row #left-square .img2 {
    position: absolute !important;
    left: 200px !important;
    bottom: 45px !important;
    width: 356px !important;
    height: 505px !important;
  }

  #industries-desc .row #left-square .img1 {
    left: 50px !important;
    top: 201px !important;
    width: 400px !important;
    height: 620px !important;
    border-radius: 50px !important;
  }

  #industries-desc .row #left-square .img1:after {
    width: 112% !important;
  }

}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}
