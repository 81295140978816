#training-tech-main {
  position: relative;
  height: 40rem;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/training/headerR.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0;

    .row p {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      font-size: 5em;
      margin-left: 74px;
      font-weight: 650;
      line-height: 1;
      margin-top: 250px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -40px;
        left: -58px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }

    &:after {
      content: " ";
      background-image: url(../images/training/icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(+50%) translateY(-50%);
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }

    
  }
  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 2.3em!important;
        margin-top: 280px!important;
        margin-left: 0!important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
      }

      div {
        padding-left: 25px !important;
      }

      .row p {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        margin-left: 5px !important;
      }
    }

    #left-square {
      &:after {
        top: 41%!important;
        right: 25%!important;
        width: 190px;
        height: 155px;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

#training-cloud {
  width: 100%;
  padding: 100px 75px 100px 75px;


  .row {
    padding-left: 50px;
    padding-right: 50px;
  }


  h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 23px;

    &:not(:first-of-type) {
      margin-top: 50px;
      margin-top: 0.2em !important;
      margin-top: 0.2em !important;
    }
  }

  p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 14px!important;
    line-height: 1.2;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      margin-left: 20px;
      
      &::marker {
        color: black;
      }
    }
  }

  #right-square {
    text-align: center;

    img {
      width: 80%;
    }
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
    padding-top: 50px !important;

    padding: 51px 25px 50px 25px !important;
    #right-square img {
      width: 100%;
      height: 90%;
      border-radius: 75px;
    }

    p {
      font-size: 15px;
    }
  }
  @include media-breakpoint-between(sm, md) {
    padding: 50px 25px 50px 25px;

    #left-square {
      padding-top: 0px !important;
    }

    p {
      color: #000;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.1;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    h3:not(:first-of-type) {
      margin-top: 30px;
    }

    #right-square img {
      width: 100%;
      height: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 50px 25px 50px 25px !important;

    #left-square {
      padding-top: 0;
    }

    #right-square img {
      display: none;
    }

    p {
      font-size: 15px;
    }
  }

  @include media-breakpoint-only(xs) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    
  }
}

#training-backap {
  width: 100%;
  padding: 0 75px 100px 75px;

  h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 23px;

    &:not(:first-of-type) {
      margin-top: 50px;
      margin-top: 0.2em !important;
      margin-top: 0.2em !important;
    }
  }

  p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 16px !important;
    line-height: 1.4;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      margin-left: 20px;
      &::marker {
        color: black;
      }
    }
  }

  #left-square {
    padding-top: 60px;
    padding-left: 50px;
    padding-right: 50px;
  }

  #right-square {
    text-align: right !important;

    img {
      width: 92%;
    }
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
    padding: 51px 25px 50px 25px !important;
    #right-square img {
      width: 90%;
      height: 76%;
      border-radius: 75px;
    }

    p {
      font-size: 15px;
    }
  }
  @include media-breakpoint-between(sm, md) {
    padding: 50px 25px 50px 25px;

    #left-square {
      padding-top: 0px !important;
    }

    p {
      color: #000;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.1;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    h3:not(:first-of-type) {
      margin-top: 30px;
    }

    #right-square img {
      width: 100%;
      height: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    padding: 50px 25px 50px 25px !important;

    #left-square {
      padding-top: 0;
    }

    #right-square img {
      display: none;
    }

    p {
      font-size: 15px;
    }
  }

  @include media-breakpoint-only(xs) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    
    .row {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

#training-service {
  height: 650px;
  position: relative;


  .shared-frorm {
    height: 600px !important;
  }

  .main-2{
    color: #1e0094!important;
    margin-bottom: 0.4em!important;
    padding-bottom: 0;
    font-weight: 650;
    font-size: 25px;
  }


  .h3rap {
    position: absolute;
    top: 65px;
    left: 100px;

    h3 {
      font-size: 3em;
      font-weight: 600;
      width: 70%;
    }
  }

  .managed-ul {
    position: absolute;
    left: 90px;
    top: 233px;
    line-height: 1.8;
    font-size: 21px;
  }

  // @include media-breakpoint-between(md, lg) {
  //   background-color: maroon;
  // }
  // @include media-breakpoint-between(sm, md) {
  //   background-color: palevioletred;
  // }
  // @include media-breakpoint-down(sm) {
  //   background-color: green;
  // }
  @include media-breakpoint-only(xs) {
    .h3rap h3 {
      font-size: 2.5em;
      font-weight: 600;
      width: 70%;
    }
  }
}


#workforce-training {
  position: relative;
  padding: 30px 15px;
  font-size: 16px;
  line-height: 1.4;
  color: #3a2525;
  font-weight: 500;
  text-align: center;

  h2 {
    font-size: 33px!important;
    color: #1f96d5!important;
    font-weight: 600;
  }

  p {
    width: 80%;
    margin: auto;
  }
  

    // end big screens media query
  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
  }
  @include media-breakpoint-down(sm) {
  }
  @include media-breakpoint-only(xs) {
    padding: 0;
    margin-top: 50px;
    p {
      font-size: 13px;
    }
  }

}

#training-vendor {
  height: 500px;
  position: relative;


  h3 {
    position: absolute;
    left: 9%;
    top: 10%;
    font-size: 2.5rem;
    font-weight: 600;
    width: 100%;
    line-height: 1.2;
  }

  img {
    position: absolute;
    right: 0;
    height: 100%;
    width: 95%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  ul {
    position: absolute;
    color: #fff;
    left: 8%;
    top: 30%;
    line-height: 1.4;
    font-size: 21px;
  }

  @include media-breakpoint-only(xs) {
      img {
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      } 
      
      ul {
        position: absolute;
        color: #fff;
        left: -2%;
        top: 37%;
        line-height: 1.4;
        font-size: 16px;
      }

      h3 {
        position: absolute;
        left: 9%;
        top: 10%;
        font-size: 2rem;
        font-weight: 600;
        width: 69%;
        line-height: 1.2;
      }
  }
}

// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #about-main {
    height: 42rem;
  }

  .mid {
    width: 377px !important;
    height: 88% !important;
  }


  #training-backap #right-square img {
    width: 92% !important;
    height: 63% !important;
    border-radius: 75px !important;
  }

  #training-cloud #right-square img {
    width: 88%!important;
    height: 100%!important;
    border-radius: 75px!important;
  }

  #training-backap #left-square {
    padding-top: 10px !important;
  }
}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #industries-main {
    height: 48rem !important;

    #right-square1 {
      width: 600px;

      &:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px;
      }

      &:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px;
      }

      .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }

  #training-backap #right-square img {
    width: 92% !important;
    height: 63% !important;
    border-radius: 75px !important;
  }

  #training-cloud #right-square img {
    width: 80% !important;
    height: 83% !important;
    border-radius: 75px !important;
  }
}


.main-2 {
  color: #1e0094!important;
  margin-bottom: 0.4em!important;
  padding-bottom: 0;
  font-weight: 650;
  font-size: 29px;
  margin-left: -20px;
}


@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

  #training-backap #right-square img {
    width: 92% !important;
    height: 63% !important;
    border-radius: 75px !important;
  }

  #training-cloud #right-square img {
    width: 80% !important;
    height: 83% !important;
    border-radius: 75px !important;
  }
}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}
