.privacy__section {
  h2 {
    font-weight: 650;
    font-size: 2rem;
    margin-bottom: 8px;
    line-height: 48px;
    color: #1e0094;
  }

  ul {
    color: black !important;
  }
}