:root {
  --main--color: #20a9df;
}

body {
  background-color: #ebebeb !important;
  overflow-x: hidden !important;
}

.container-xl {
  height: 100% !important;
  position: relative !important;
}

.nav-icon {
  font-size: 20px;
  border: 1px solid #fff;
  color: white;
  border-radius: 5px;
  padding: 0 8px;
  top: 58px;
}

.main-color {
  color: #1f96d5 !important;
}

.get-in {
  font-size: 15px;
  font-weight: 450;
  color: white;

  @include media-breakpoint-only(xs) {
    display: none;
  }
}

.shared-frorm {
  // end big screens media query
  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
    height: 68% !important;
    h3 {
      margin-bottom: 50px;
      font-size: 2em !important;
    }
  }
  @include media-breakpoint-down(sm) {
  }
  @include media-breakpoint-only(xs) {
    padding: 25px !important;
    height: 73%!important;
    

    .form-row:not(:last-of-type) {
      margin: 0 !important;
    }

    .form-row {
      margin-top: 0 !important;
    }

    h3 {
      font-size: 2em !important;
      width: 70% !important;
      margin-bottom: 30px !important;
      margin-left: 15px;
    }

    .shared-frorm .form-control {
      width: 100% !important; 
    }

    #rc-anchor-container {
      width: 88% !important;
    }
  }
}



#preloader-iteam {
  background: #1e0094 url(../images/gif/final.gif) center center no-repeat;
  background-size: 5%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99999999999;
  overflow: hidden;
}