/* image flicker */

.fadein-slider img {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 20s;
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 20s;
  }
  
  @-webkit-keyframes fade {
    0% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    33% {
      opacity: 1;
    }
  
    53% {
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    33% {
      opacity: 1;
    }
  
    53% {
      opacity: 0;
    }
  
    100% {
      opacity: 0;
    }
  }
  
  .fadein-slider img:nth-of-type(2) {
    -webkit-animation-delay: -10s;
    animation-delay: -10s;
  }
  
  /* end image flicker */

  // floating img


  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  
  
  .floating {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
   
  }


  // end floating img