#cyper-framework {
    overflow: hidden !important;
    .circles-wrapper {
      width: 750px !important;
      height: 750px !important;
      position: relative !important;
      animation: right;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      margin: auto !important;
      left: -35%;
  
      @include media-breakpoint-only(xs) {
        left: 0 !important;
      }
  
      @include media-breakpoint-only(sm) {
        left: 0 !important;
      }
    }


    .left {
      position: relative;
      color: black;
      right: 0;
      animation-fill-mode: forwards;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-direction: reverse;
      opacity: 0;
      padding-top: 70px;
      
  
      h2 {
        position: relative;
        margin-top: 146px;
        margin-left: 193px;
        color: #1e0094;
        font-weight: 620;
        font-size: 60px;
  
        &:before {
          content: " ";
          background-image: url(../images/Cyber-security/pattern.png);
          position: absolute;
          top: -54px;
          left: -80px;
          z-index: -1;
          width: 180px;
          height: 180px;
          background-repeat: no-repeat;
          background-size: contain;
          background-size: contain !important;
        }
      }
  
      .cyper-framework__p {
        margin-left: 110px;
        line-height: 1.3;
        font-weight: 500 !important;
        font-size: 14px !important;
      }
  
      h3 {
        color: #2196f3;
        margin-left: 110px;
        margin-top: 65px;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 10px;
      }
  
      h5 {
        margin-left: 110px;
        font-weight: bold;
        margin-bottom: 6px;
        font-size: 14px!important;
        font-weight: bold!important;
      }
  
      ul {
        font-weight: 500;
        margin-left: 110px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;

        li {
          line-height: 1.3;
          font-weight: 500 !important;
          font-size: 15px !important;
        }
      }
    }
  
    .right {
      .circle {
        z-index: 0;
        position: relative;
        border: 1px solid black;
        padding: 0;
        margin: 1em auto;
        width: 750px;
        height: 750px;
        border-radius: 50%;
        list-style: none;
        overflow: hidden;
  
        li {
          overflow: hidden;
          position: absolute;
          top: -20%;
          right: -20%;
          width: 70%;
          height: 70%;
          transform-origin: 0% 100%;
          border: 1px solid white;
        }
        .text,
        .text1,
        .text2 {
          position: absolute;
          // left: -100%;
          width: 100%;
          height: 100%;
          // text-align: center;
          // transform: skewY(-60deg) rotate(15deg);
          // padding-top: 20px;
  
          .spn {
            width: 15px !important;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 1.2em !important;
            -webkit-transform-origin: 0 215px;
            transform-origin: 0 325px;
            font-size: 20px !important;
            font-weight: 600;
            text-align: center;
            height: 49% !important;
            transform-origin: 0 0;
            text-align: right;
            display: flex;
            align-items: flex-end;
            justify-content: center;
  
            span {
              transform-origin: 50% 50%;
              transform: rotate(180deg);
            }
  
            @include media-breakpoint-only(xs) {
              font-size: 12px !important;
            }
          }
        }
  
        li {
          &:first-child {
            transform: rotate(0deg) skewY(30deg);
            background: linear-gradient(to right bottom, #882fbd, #9333b9);

            &:hover,
            &.active {
              background-color: transparent;
              background: linear-gradient(to right bottom,#a034e1,#0e030d) !important;
            }
          }
        }
  
        li {
          &:nth-child(2) {
            transform: rotate(120deg) skewY(30deg);
            background: linear-gradient(to right bottom, #882fbd, #e073d1);

            &:hover,
            &.active {
              background-color: transparent;
              background: linear-gradient(to right bottom,#a034e1,#0e030d) !important;
            }
          }
        }
  
        li {
          &:nth-child(3) {
            transform: rotate(240deg) skewY(30deg);
            background: linear-gradient(to right bottom, #e073d1, #882fbd);

            &:hover,
            &.active {
              background-color: transparent;
              background: linear-gradient(to right bottom,#a034e1,#0e030d) !important;
            }
          }
        }



        li {
          &:nth-child(4) {
            transform: rotate(0deg) skewY(30deg);
            background: transparent;
            z-index: 1;
          }
        }
  
        li {
          &:nth-child(5) {
            transform: rotate(120deg) skewY(30deg);
            background: transparent;
            z-index: 1;
          }
        }
  
        li {
          &:nth-child(6) {
            transform: rotate(240deg) skewY(30deg);
            background: transparent;
            z-index: 1;
          }
        }


        padding: 0;
        margin: 0;
      }
  
      .circle2 {
        z-index: 2;
        position: absolute;
        border: 1px solid #000;
        padding: 0;
        margin: 1em auto;
        width: 650px;
        height: 650px;
        border-radius: 50%;
        list-style: none;
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        border: none;
  
        li {
          overflow: hidden;
          position: absolute;
          top: -20%;
          right: -20%;
          width: 70%;
          height: 70%;
          transform-origin: 0% 100%;
          border: 1px solid white;
        }
  
        .text,
        .text1,
        .text2 {
          position: absolute;
          // left: -100%;
          width: 100%;
          height: 100%;
          // text-align: center;
          // transform: skewY(-60deg) rotate(15deg);
          // padding-top: 20px;
  
          .spn {
            width: 12px !important;
            margin: 0;
            padding: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 17px !important;
            -webkit-transform-origin: 0 215px;
            transform-origin: 0 325px;
            font-size: 20px !important;
            font-weight: 600;
            text-align: center;
            height: 49% !important;
            transform-origin: 0 0;
            text-align: right;
            display: flex;
            align-items: flex-end;
            justify-content: center;
  
            span {
              transform-origin: 50% 50%;
              transform: rotate(180deg);
            }
  
            @include media-breakpoint-only(xs) {
              font-size: 12px !important;
            }
          }
        }
  
        .text {
          .spn {
            span {
              transform-origin: 50% 50%;
              transform: rotate(0) !important;
            }
          }
        }
  
        li {
          &:first-child {
            transform: rotate(0deg) skewY(30deg);
            background: #00adef;

            &.active {
            background-color: transparent;
            background: #6bd2f9 !important;
          }
          }
        }
  
        li {
          &:nth-child(2) {
            transform: rotate(120deg) skewY(30deg);
            background: #00adef;

            &:hover,
          &.active {
            background-color: transparent;
            background: #6bd2f9 !important;
          }
          }
        }
  
        li {
          &:nth-child(3) {
            transform: rotate(240deg) skewY(30deg);
            background: #00adef;

            &:hover,
          &.active {
            background-color: transparent;
            background: #6bd2f9 !important;
          }
          }
        }

        li {
          &:nth-child(4) {
            transform: rotate(0deg) skewY(30deg);
            background: transparent;
            z-index: 2;
          }
        }
  
        li {
          &:nth-child(5) {
            transform: rotate(120deg) skewY(30deg);
            background: transparent;
            z-index: 2;
          }
        }
  
        li {
          &:nth-child(6) {
            transform: rotate(240deg) skewY(30deg);
            background: transparent;
            z-index: 2;
          }
        }

  
        padding: 0;
        margin: 0;
      }
  
      .circle3 {
        z-index: 3;
        position: absolute;
        border: 1px solid #000;
        padding: 0;
        margin: 1em auto;
        width: 550px;
        height: 550px;
        border-radius: 50%;
        list-style: none;
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(58deg);
        border: none;
  
        li {
          overflow: hidden;
          position: absolute;
          top: -20%;
          right: -20%;
          width: 70%;
          height: 70%;
          transform-origin: 0% 100%;
          border: 1px solid #00adef;
          cursor: pointer;
  
          &:hover {
            background: linear-gradient(to left blue, white, blue) !important;
          }
  
          span {
            display: inline-block;
            width: 43%;
            height: 19%;
            /* background: red; */
            position: absolute;
            left: 18%;
            top: 75%;
            transform: translate(-50%, -50%) skewY(224deg) rotate(-68deg);
            font-size: 18px;
          }
        }
  
        li {
          &:first-child {
            transform: rotate(0deg) skewY(135deg);
            background: #1e0094;
          }
        }
  
        li {
          &:nth-child(2) {
            transform: rotate(45deg) skewY(135deg);
            background: #1e0094;
          }
        }
  
        li {
          &:nth-child(3) {
            transform: rotate(90deg) skewY(135deg);
            background: #1e0094;
            span {
              font-size: 15px;
            }
          }
        }
  
        li {
          &:nth-child(4) {
            transform: rotate(135deg) skewY(135deg);
            background: #1e0094;
            span {
              left: 15%;
              transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
              font-size: 16px;
            }
          }
        }
  
        li {
          &:nth-child(5) {
            transform: rotate(180deg) skewY(135deg);
            background: #1e0094;
            span {
              left: 15%;
              transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
              font-size: 16px;
            }
          }
        }
  
        li {
          &:nth-child(6) {
            transform: rotate(225deg) skewY(135deg);
            background: #1e0094;
            span {
              left: 15%;
              transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
            }
          }
        }
  
        li {
          &:nth-child(7) {
            transform: rotate(270deg) skewY(135deg);
            background: #1e0094;
            span {
              left: 25%;
              -webkit-transform: translate(-50%,-50%) skewY(224deg) rotate(-249deg);
              transform: translate(-50%,-50%) skewY(224deg) rotate(-249deg);
              top: 87%;
            }
          }
        }
  
        li {
          &:nth-child(8) {
            transform: rotate(315deg) skewY(135deg);
            background: #1e0094;
          }
        }
  
        li {
          // &:hover,
          &.active {
            background-color: transparent;
            background: #3d1bc3 !important;
          }
        }
  
        padding: 0;
        margin: 0;
  
        // end big screens media query
        @include media-breakpoint-between(md, lg) {
          li {
            &:first-child {
              span {
                left: 22% !important;
              }
            }
          }
  
          li {
            &:nth-child(2) {
              span {
                left: 18%!important;
                top: 71%;
              }
            }
          }
  
          li {
            &:nth-child(3) {
              span {
                left: 21%!important;
                top: 78%;
              }
            }
          }
  
          li {
            &:nth-child(4) {
              span {
                left: 16% !important;
              }
            }
          }
  
          li {
            &:nth-child(5) {
              transform: rotate(180deg) skewY(135deg);
              background: #1e0094;
              span {
                left: 20%!important;
                top: 85%;
              }
            }
          }
  
          li {
            &:nth-child(6) {
              span {
                left: 16% !important;
              }
            }
          }
  
          li {
            &:nth-child(7) {
              span {
                top: 83%;
                left: 23%!important;
                font-size: 13px!important;
              }
            }
          }
  
          li {
            &:nth-child(8) {
              span {
                left: 23%!important;
                top: 74%;
              }
            }
          }
        }
        @include media-breakpoint-between(sm, md) {
        }
        @include media-breakpoint-down(sm) {
        }
        @include media-breakpoint-only(xs) {
        }
      }
  
      .circle4 {
        z-index: 4;
        position: absolute;
        padding: 0;
        margin: 1em auto;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        list-style: none;
        overflow: hidden;
        top: 49%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        border: none;
        background-color: #00adef;
        outline-style: dashed;
        outline-color: #00adef;
        outline-width: 2px;
        outline-offset: 8px;

        -webkit-outline-style: dashed;
        -webkit-outline-color: #00adef;
        -webkit-outline-width: 2px;
        -webkit-outline-offset: 8px;
  
        h3 {
          position: absolute;
          left: 16%;
          top: 23%;
          -webkit-transform: rotate(-44deg);
          transform: rotate(-44deg);
          font-weight: 600;
          font-size: 26px;
        }
  
        padding: 0;
        margin: 0;
  
        @include media-breakpoint-only(xs) {
          outline-offset: 4px !important;
        }
      }
    }
  
    // end big screens media query
    @include media-breakpoint-only(lg) {
      .left h2 {
        margin-top: 110px;
        margin-left: 140px;
        font-size: 50px !important;
      }
  
      .circles-wrapper {
        margin-top: 60px !important;
        width: 620px !important;
        height: 620px !important;
  
        .circle {
          width: 620px !important;
          height: 620px !important;
        }
        .circle2 {
          width: 530px !important;
          height: 530px !important;
        }
        .circle3 {
          width: 450px !important;
          height: 450px !important;
        }
        .circle4 {
          width: 130px !important;
          height: 130px !important;
        }
      }
  
      .right .circle3 li span {
        font-size: 14px !important;
      }
  
      .left {
        h2 {
          margin-top: 50px!important;
          font-size: 47px!important;
          width: 82%!important;
          margin-left: 170px!important;
          margin-bottom: 60px!important;
          &:before {
            top: -52px!important;
            left: -68px!important;
            width: 150px!important;
            height: 190px!important;
          }
        }
  
        h3 {
          margin-left: 105px!important;
          margin-top: 36px!important;
          font-size: 26px!important;
          margin-bottom: 10px!important;
          font-weight: 600;
        }
  
        .cyper-framework__p {
          margin-left: 105px !important;
          line-height: 1.3;
          font-weight: 500 !important;
          font-size: 16px !important;
        }
  
        h5 {
          margin-left: 105px !important;
          font-weight: 700 !important;
          margin-bottom: 6px !important;
          font-size: 17px !important;
          font-weight: 600 !important;
        }
  
        ul {
          font-weight: 500 !important;
          margin-left: 105px !important;
          padding-left: 20px !important;
          font-size: 12px !important;
          font-weight: 500 !important;


        }
      }
    }
  
    @include media-breakpoint-only(sm) {
      .left h2 {
        margin-top: 110px;
        margin-left: 140px;
        font-size: 50px !important;
      }
  
      .circles-wrapper {
        width: 650px !important;
        height: 650px !important;
  
        .circle {
          width: 650px !important;
          height: 650px !important;
        }
        .circle2 {
          width: 550px !important;
          height: 550px !important;
        }
        .circle3 {
          width: 450px !important;
          height: 450px !important;
        }
      }
  
      .right .circle3 li span {
        font-size: 14px !important;
      }
    }
  
    @include media-breakpoint-only(md) {
      .left h2 {
        margin-top: 110px;
        margin-left: 140px;
        font-size: 50px !important;
      }
  
      .circles-wrapper {
        margin-top: 0 !important;
        width: 550px !important;
        height: 550px !important;
  
        .circle {
          width: 550px !important;
          height: 550px !important;
        }
        .circle2 {
          width: 480px !important;
          height: 480px !important;
        }
        .circle3 {
          width: 420px !important;
          height: 420px !important;
        }
        .circle4 {
          width: 130px !important;
          height: 130px !important;
        }
      }
  
      .right .circle3 li span {
        font-size: 14px !important;
      }
  
      .left {
        h2 {
          margin-top: 110px !important;
          font-size: 33px !important;
          width: 100% !important;
          margin-left: 80px !important;
          &:before {
            top: -67px !important;
            left: -52px !important;
            width: 141px !important;
            height: 180px !important;
          }
        }
  
        h3 {
          margin-left: 25px !important;
          margin-top: 36px !important;
          font-size: 18px !important;
          margin-bottom: 10px !important;
        }
  
        .cyper-framework__p {
          margin-left: 25px !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
  
        h5 {
          margin-left: 25px !important;
          font-weight: 700 !important;
          margin-bottom: 6px !important;
          font-size: 15px !important;
          font-weight: 600 !important;
        }
  
        ul {
          font-weight: 500 !important;
          margin-left: 25px !important;
          padding-left: 20px !important;
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }
    }
  
    @include media-breakpoint-only(sm) {
      .left h2 {
        margin-top: 110px;
        margin-left: 140px;
        font-size: 50px !important;
      }
  
      .circles-wrapper {
        margin-top: 0 !important;
        width: 550px !important;
        height: 550px !important;
  
        .circle {
          width: 550px !important;
          height: 550px !important;
        }
        .circle2 {
          width: 480px !important;
          height: 480px !important;
        }
        .circle3 {
          width: 420px !important;
          height: 420px !important;
        }
        .circle4 {
          width: 130px !important;
          height: 130px !important;
        }
      }
  
      .right .circle3 li{
        span {
          font-size: 14px !important;
        }
  
        &:nth-child(5) {
          span {
            font-size: 12px !important;
          }
        }
        &:nth-child(4) {
          span {
            font-size: 12px !important;
          }
        }
        &:nth-child(3) {
          span {
            font-size: 11px !important;
          }
        }
        &:nth-child(2) {
          span {
            font-size: 12px !important;
          }
        }
      }
  
      .left {
        h2 {
          margin-top: 110px !important;
          font-size: 33px !important;
          width: 100% !important;
          margin-left: 80px !important;
          &:before {
            top: -67px !important;
            left: -52px !important;
            width: 141px !important;
            height: 180px !important;
          }
        }
  
        h3 {
          margin-left: 25px !important;
          margin-top: 36px !important;
          font-size: 18px !important;
          margin-bottom: 10px !important;
        }
  
        .cyper-framework__p {
          margin-left: 25px !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
  
        h5 {
          margin-left: 25px !important;
          font-weight: 700 !important;
          margin-bottom: 6px !important;
          font-size: 15px !important;
          font-weight: 600 !important;
        }
  
        ul {
          font-weight: 500 !important;
          margin-left: 25px !important;
          padding-left: 20px !important;
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }
    }
    @include media-breakpoint-only(xs) {

      .row {
          flex-direction: column-reverse !important;
      }

      .left h2 {
        margin-top: 110px;
        margin-left: 140px;
        font-size: 50px !important;
      }
  
      .circles-wrapper {
        margin-top: 0 !important;
        width: 340px !important;
        height: 340px !important;
        left: 0 !important;
  
        .circle {
          width: 340px !important;
          height: 340px !important;
  
          .spn {
            font-size: 12px !important;
          }
        }
        .circle2 {
          width: 290px !important;
          height: 290px !important;
        }
        .circle3 {
          width: 245px !important;
          height: 245px !important;
  
          li span {
            font-size: 11px !important;
  
            @include media-breakpoint-only(xs) {
              font-size: 8px !important;
            }
          }
  
          li {
            &:first-child {
              span {
                left: 22% !important;
              }
            }
          }
  
          li {
            &:nth-child(2) {
              span {
                left: 22% !important;
              }
            }
          }
  
          li {
            &:nth-child(3) {
              span {
                left: 21%!important;
                top: 78%;
              }
            }
          }
  
          li {
            &:nth-child(4) {
              span {
                left: 23%!important;
                top: 85%;
              }
            }
          }
  
          li {
            &:nth-child(5) {
              transform: rotate(180deg) skewY(135deg);
              background: #1e0094;
              span {
                left: 20%!important;
                top: 84%;
              }
            }
          }
  
          li {
            &:nth-child(6) {
              span {
                left: 18% !important;
              }
            }
          }
  
          li {
            &:nth-child(7) {
              span {
                left: 25%!important;
                top: 84%;
              }
            }
          }
  
          li {
            &:nth-child(8) {
              span {
                left: 22% !important;
              }
            }
          }
        }
        .circle4 {
          width: 80px !important;
          height: 80px !important;
  
          h3 {
            font-size: 22px;
  
            @include media-breakpoint-only(xs) {
              font-size: 15px !important;
            }
          }
        }
      }
  
      .left {
        h2 {
          margin-top: 110px!important;
          font-size: 33px!important;
          width: 98%!important;
          margin-left: 63px!important;
          margin-bottom: 40px;
  
          &:before {
            top: -67px !important;
            left: -52px !important;
            width: 141px !important;
            height: 180px !important;
          }
        }
  
        h3 {
          margin-left: 25px !important;
          margin-top: 36px !important;
          font-size: 18px !important;
          margin-bottom: 10px !important;
        }
  
        .cyper-framework__p {
          margin-left: 25px !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
  
        h5 {
          margin-left: 25px !important;
          font-weight: 700 !important;
          margin-bottom: 6px !important;
          font-size: 15px !important;
          font-weight: 600 !important;
        }
  
        ul {
          font-weight: 500 !important;
          margin-left: 25px !important;
          padding-left: 20px !important;
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }
    }

  }