#vendor {
  height: 700px;
  position: relative;
  margin-bottom: 60px;

  h3 {
    position: absolute;
    left: 250px;
    top: 160px;
    font-size: 50px;
    font-weight: 600;

    &:before {
      content: " ";
      background-image: url(../images/about-us/pattern.png);
      position: absolute;
      top: -50px;
      left: -63px;
      z-index: -1;
      width: 150px;
      height: 150px;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  & > .container-xl {
    
    &:before {
      content: " ";
      background-image: url(../images/ICT-Professional-Solutions/vendor-bg.png);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 92%;
      height: 591px;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-breakpoint-only(xs) {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px;
      }
      
    }

  }
  .sqrs {
    width: 80%;
    position: absolute;
    bottom: 30px;
    left: 10%;
    display: flex;
    justify-content: space-evenly;

    .sqr {
      background-color: white;
      display: inline-block;
      width: 225px;
      height: 275px;
      border-radius: 20px;
      border: 1px solid #2196f3;
      overflow: hidden;
      background-size: 75%;


      
      &:nth-child(1) {
        background-image: url(../images/Homepage/our-partners/a1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);
        background-size: 75%;

        &:hover {
          filter: none !important;
        }
  
      }

      &:nth-child(2) {
        background-image: url(../images/Homepage/our-partners/b1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);
        background-size: 75%;

        &:hover {
        filter: none !important;
      }

      }

      &:nth-child(3) {
        background-image: url(../images/Homepage/our-partners/c1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);
        background-size: 75%;
        
        &:hover {
          filter: none !important;
        }
  
      }

      &:nth-child(4) {
        background-image: url(../images/Homepage/our-partners/d1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        background-size: 75%;
        filter: grayscale(0.9);

        &:hover {
          filter: none !important;
        }
  
      }

    }
  }

  // end big screens media query
  @include media-breakpoint-between(md, lg) {
    &:before {
      width: 92% !important;
      height: 431px !important;
      background-size: cover !important;
    }

    .sqrs {
      top: 400px !important;
      width: 86%;
      bottom: 30px;
      left: 6%;
      .sqr {
        width: 225px!important;
        height: 265px!important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    h3 {
      left: 100px;
      top: 80px;
      font-size: 2.5em;
    }

    &:before {
      width: 99% !important;
      height: 359px !important;
      background-size: cover !important;
    }

    .sqrs {
      top: 310px !important;
      .sqr {
        width: 150px !important;
        height: 220px !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    h3 {
      left: 100px;
      top: 110px;
    }

    .sqrs {
      top: 285px !important;
      width: 96%;
      left: 2%;
      .sqr {
        width: 130px !important;
        height: 190px !important;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    h3 {
      left: 100px;
      top: 86px;
      font-size: 35px !important;
    }

    &:before {
      width: 99% !important;
      height: 359px !important;
      background-size: cover !important;
    }

    .sqrs {
      top: 203px!important;
      width: 88% !important;
      left: 2%;
      -ms-flex-wrap: wrap!important;
      flex-wrap: wrap!important;
      bottom: unset !important;
      
      .sqr {
        width: 130px !important;
        height: 190px !important;
        flex: 50% !important;
      }
    }
  }
}
