#cyper-main {
  overflow: hidden !important;
  position: relative;
  height: 37rem;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/Cyber-security/header.png);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 0 40px 0;

    .row p {
      width: 87%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      margin-left: 74px;
      font-weight: 650;
      font-size: 6em;
      line-height: 1;
      margin-top: 250px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -34px;
        left: -62px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }

    &:after {
      content: " ";
      background-image: url(../images/Cyber-security/cyber-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain!important;
      -webkit-transform: translateX(50%) translateY(-50%);
      transform: translateX(50%) translateY(-50%);
      right: -20px!important;
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 2.3em !important;
        margin-top: -70px !important;
        margin-left: 0 !important;
        font-size: 2.3em!important;
        margin-top: 280px!important;
        margin-left: 0!important;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 350%;}

      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
        width: 92%!important;
        margin-top: 0!important;
        margin-left: 5px!important;
      }

      div {
        padding-left: 25px !important;
      }

      &:after {
        top: 41%!important;
        right: 25%!important;
        width: 140px !important;
        height: 200px !important;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

#cyper-form {
  height: 800px;
  position: relative;

  .shared-frorm {
    top: 100px !important;
  }

  @include media-breakpoint-only(xs) {
    height: 1150px !important;
  }
}

#cyper-vendor {
  height: 1200px;
  overflow: hidden;
  position: relative;
  z-index: 2;

  & > .adv {
    
    &:after {
      content: " ";
      background-image: url(../images/Advanced-tech/img5.jpeg);
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      width: 96%;
      height: 590px;
      background-repeat: no-repeat;
      background-size: cover !important;
      border-top-left-radius: 75px;
    }
  }

  & > .oth {
    
    &:after {
      content: " ";
      background-image: url(../images/Advanced-tech/img7.jpeg);
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
      width: 96%;
      height: 590px;
      background-repeat: no-repeat;
      background-size: cover !important;
      border-top-left-radius: 75px;
    }

  }

  .container-xl {
    padding: 0;
    position: relative !important;
  }

  .managed-text {
    position: absolute;
    left: 109px;
    top: 65px;
    font-size: 2.5rem;
    font-weight: 600;
    width: 100%;
    line-height: 1.2;

    @include media-breakpoint-only(xs) {
      position: absolute;
      left: 38px;
      top: 45px;
      font-size: 1.8rem;
      font-weight: 600;
      width: 100%;
      line-height: 1.2;
    }
  }

  .managed-ul {
    position: absolute;
    left: 90px;
    top: 150px;
    line-height: 1.8;
    font-size: 21px;

    @include media-breakpoint-only(xs) {
      position: absolute;
      left: 15px;
      top: 150px;
      line-height: 1.8;
      font-size: 16px;
    }
  }

  #vendor {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
  }
}

// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #about-main {
    height: 42rem;
  }

  .mid {
    width: 377px !important;
    height: 88% !important;
  }
}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #industries-main {
    height: 48rem !important;

    #right-square1 {
      width: 600px;

      &:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px;
      }

      &:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px;
      }

      .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

  #cyper-framework .left h2 {
    margin-top: 20px !important;
  }


  #cyper-framework .right .circle3 li:nth-child(3) span {
      left: 24%;
      top: 78%;
  }
}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}

@keyframes right {
  from {
    left: -35% !important;
  }
  to {
    left: 0 !important;
  }
}

@keyframes left {
  0% {
    right: 0%;
  }
  100% {
    right: -200%;
  }
}

.test {
  display: none;
}

