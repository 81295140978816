#you-trust {
  position: relative;
  height: 40rem;
  margin-bottom: 80px;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    height: 100%;
    width: 90%;
    /* background-color: green; */
    background-size: cover;
    background-image: url(../images/Homepage/home/s-2.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0;

    .row p {
      width: 60%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      margin-top: 130px;
      font-weight: 610;
      font-size: 6em;
      line-height: 0.9;
    }

    a {
      border-radius: 5px;
    }
  }

  #right-square {
    position: absolute;
    top: 0;
    right: 0;
    width: 480px;
    height: 550px;
    background-color: #1e0094;
    border-radius: 0 0 0 40px;
    padding-left: 60px;

    h2 {
      color: #1f96d5 !important;
      font-size: 37px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .right-square__cyper {
      color: #1f96d5 !important;
      font-size: 25px;
      font-weight: 600;
    }

    .right-square__text {
      font-size: 14px;
      margin-bottom: 30px;
      width: 81% !important;
    }

    .prev {
      padding: 0;
      position: absolute;
      bottom: 53px;
      left: -14px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      text-align: center;
      line-height: 60px;

      span {
        padding: 0;
      }

      &:hover {
        background-color: #20a9df;
      }
    }

    .next {
      padding: 0;
      position: absolute;
      bottom: 53px;
      left: 50%;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      text-align: center;
      line-height: 60px;

      &.active {
        background-color: #20a9df;  
      }

      &:hover {
        background-color: #20a9df;
      }

      span {
        padding: 0;
      }
    }

    .divider {
      left: 12%;
      display: block;
      width: 36%;
      height: 1px;
      background-color: #1f96d5;
      bottom: 82px;
      position: absolute;
    }
  }

  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }
    #right-square {
      width: 46%;
      height: 550px;
      padding-left: 30px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #right-square {
      width: 46%;
      height: 550px;
      padding-left: 30px !important;
    }

    #left-square {
      width: 86%;

      h2 {
        font-size: 3.3em;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 57rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 60% !important;
      border-radius: 0;

      .left-content {
        margin-left: 0 !important;
        justify-content: center !important;
      }

      h2 {
        font-size: 3.3em !important;
        margin-top: 66px !important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
      }

      div {
        padding-left: 6px !important;
      }

      
    }

    #right-square {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      .prev {
        bottom: 40px !important;
        left: 30px !important;
      }

      .divider {
        left: 31% !important;
        bottom: 70px !important;
      }

      .next {
        bottom: 41px !important;
        left: 72% !important;
      }

      .right-square__text {
        width: 100% !important;
        a {
          display: inline-block;
          margin: 20px 0;
        }
      }

      .carousel-item {
        height: 96% !important;
      }

      .read-more-component {
        text-align: left !important;
        margin-left: 75px !important;
      }
    }
  }
}

#about-us {
  height: 600px;

  .container-xl {
    height: 100%;

    .row {
      height: 100%;
    }

    .about-us__content {
      height: 100%;
      position: relative;
      color: black;

      &:before {
        content: " ";
        background-image: url(../images/Homepage/about/pattern.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 214px;
        left: 44px;
        z-index: -1;
        width: 40%;
        height: 30%;
        border-radius: 0;
        background-size: cover;
      }

      .about-us__content__text {
        position: absolute;
        left: 120px;
        top: 270px;

        h2 {
          font-weight: 650;
          font-size: 58px;
          margin-bottom: 8px;
        }

        p {
          position: relative;
          font-size: 16px;
          line-height: 1.4;
          overflow: visible;
          width: 237%;
          color: #3a2525;
          font-weight: 500;
          z-index: 1;
          margin-bottom: 40px;
        }
      }

      .about__read {
        color: black !important;

        .read-more-component:hover .read-more__icon__span i {
          color: black !important;
        }

        .read-more-component:hover .read-more__icon__span i {
          color: white !important;
        }
      }
    }

    .about-us__images {
      height: 100%;
      position: relative;
      background-image: url(../images/Homepage/about/guy.png);
      background-repeat: no-repeat;
      background-size: 42%;
      background-position: bottom center;

      &:before {
        content: " ";
        background-image: url(../images/Homepage/about/i-TE.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 161px;
        left: -12px;
        z-index: 0;
        width: 51%;
        height: 43%;
        border-radius: 0;
        background-size: cover;
      }

      &:after {
        content: " ";
        background-image: url(../images/Homepage/about/AM.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 214px;
        right: 14px;
        z-index: -1;
        width: 42%;
        height: 35%;
        border-radius: 0;
        background-size: cover;
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .about-us__content {
      &:before {
        width: 110px !important;
        height: 110px !important;
      }

      .about-us__content__text {
        left: 79px !important;
        top: 244px !important;
        h2 {
          font-size: 42px !important;
        }
      }
    }

    .about-us__images {
      &:after {
        top: 255px !important;
        right: 2px !important;
        width: 308px !important;
        height: 177px !important;
      }

      &:before {
        position: absolute !important;
        top: 221px !important;
        left: -38px !important;
        width: 379px !important;
        height: 214px !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    .about-us__content__text {
      p {
        width: 325%;
      }
    }
    .about-us__images {
      background-size: 59% !important;
      background-position: top left !important;

      &:after {
        top: 54px !important;
        right: 120px !important;
        width: 254px !important;
        height: 146px !important;
      }

      &:before {
        top: 32px !important;
        left: -215px !important;
        width: 322px !important;
        height: 179px !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    height: 810px;
    .about-us__content {
      height: 32% !important;

      .about-us__content__text {
        position: absolute !important;
        left: 120px !important;
        top: 56px !important;

        h2 {
          font-size: 44px !important;
        }

        p {
          font-size: 14px !important;
          width: 92% !important;
        }
      }

      &:before {
        top: 23px !important;
        left: 45px !important;
        width: 150px !important;
        height: 150px !important;
      }
    }

    .about-us__images {
      height: 68% !important;
      background-size: 45% !important;
      background-position: bottom center !important;

      &:after {
        top: 245px !important;
        right: 10px !important;
        width: 225px !important;
        height: 129px !important;
      }

      &:before {
        top: 225px !important;
        left: 13px !important;
        width: 280px !important;
        height: 157px !important;
      }
    }
  }

  @include media-breakpoint-only(xs) {
    height: 750px;
    .about-us__content {
      height: 46% !important;

      .about-us__content__text {
        position: absolute !important;
        left: 120px !important;
        top: 56px !important;

        h2 {
          font-size: 44px !important;
        }

        p {
          font-size: 14px !important;
          width: 92% !important;
        }
      }

      &:before {
        top: 23px !important;
        left: 45px !important;
        width: 150px !important;
        height: 150px !important;
      }
    }

    .about-us__images {
      height: 54% !important;
      background-size: 51% !important;
      background-position: 43% 100% !important;

      &:after {
        top: 210px !important;
        right: 6px !important;
        width: 159px !important;
        height: 90px !important;
      }

      &:before {
        top: 197px!important;
        left: 9px!important;
        width: 172px!important;
        height: 97px!important;
      }
    }

  }
}

#our-solutions {
  position: relative;
  height: 800px;
  z-index: 0;

  .container-xl {
    height: 100%;
    background-size: cover;
    background-image: url(../images/Homepage/our-solutions/bg.png);
    background-repeat: no-repeat;

    .row {
      height: 100%;

      .our-solutions__content {
        height: 100%;
        position: relative;
        color: black;

        &:before {
          content: " ";
          background-image: url(../images/Homepage/our-solutions/pattern.png);
          background-repeat: no-repeat;
          background-size: 32%;
          background-position: bottom center;
          position: absolute;
          top: 118px;
          left: 44px;
          z-index: -1;
          width: 24%;
          height: 22%;
          border-radius: 0;
          background-size: contain;
        }

        .our-solutions__content__text {
          position: absolute;
          left: 100px;
          top: 200px;

          h2 {
            font-weight: 600;
            font-size: 46px;
            margin-bottom: 8px;
          }

          p {
            position: relative;
            font-size: 16px;
            line-height: 1.4;
            overflow: visible;
            width: 237%;
            color: #3a2525;
            font-weight: 500;
            z-index: 1;
          }
        }

        .our-solutions__ul__box {
          position: absolute;
          top: 383px;
          left: 44px;

          h3 {
            font-weight: 600;
            margin-bottom: 18px;
            font-size: 30px;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              i {
                color: #2196f3;
                opacity: 0.5;
                font-size: 18px;
              }

              img {
                width: 20px !important;
              }

              span {
                font-size: 15px;
                margin-left: 8px;
                line-height: 2;
              }
            }
          }
        }

        .our-solutions__read {
          position: absolute;
          bottom: 20px;
          left: 45px;
          display: block;
          height: 100px;
          width: 185px;
        }
      }

      .our-solutions__slider {
        padding: 100px 100px;
        padding: 100px 50px !important;

        height: 817px;
        overflow: scroll;

        .row {
          div {
            margin: 10px;
            border-radius: 20px;
            height: 294px;

            &.box {
              background-size: 99%;
              background-repeat: no-repeat;
              transition: 0.2s;
              cursor: pointer;
              position: relative;
              background-image: url(../images/Homepage/our-solutions/box.png);

              &:hover {
                background-image: url(../images/Homepage/our-solutions/box-on-hover.png);
              }

              &:before {
                content: " ";
                background-image: url(../images/Homepage/our-solutions/Advanced-icon.png);
                background-repeat: no-repeat;
                background-size: 32%;
                background-position: bottom center;
                position: absolute;
                top: 59px;
                z-index: 0;
                border-radius: 0;
                background-size: cover;
                height: 117px !important;
                width: 100px !important;
                left: 50% !important;
                transform: translateX(-50%) !important;
              }

              &:not(:first-child) {
                &:before {
                  width: 117px !important;
                }
              }

              .text-box {
                position: absolute;
                height: 50px;
                bottom: 14px;

                p {
                  font-weight: 600;
                  line-height: 1.3;
                  font-size: 21px;
                }

                h2 {
                  margin-bottom: 5px;
                }
              }

              &:nth-child(1) {
                &:before {
                  background-image: url(../images/Homepage/our-solutions/Cyber-icon.png);
                }
              }

              &:nth-child(3) {
                &:before {
                  background-image: url(../images/Homepage/our-solutions/Advanced-icon.png);
                }
              }

              &:nth-child(4) {
                &:before {
                  top: 62px;
                  background-image: url(../images/ICT-Professional-Solutions/ICT-icon.png);
                }
              }

              &:nth-child(6) {
                &:before {
                  background-image: url(../images/hr/Outsourcing-icon.png);
                  top: 57px;
                }
              }

              &:nth-child(7) {
                &:before {
                  background-image: url(../images/Homepage/our-solutions/icon.png);
                  top: 57px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {

    .box {
      background-size: cover !important;

      &:before {
        height: 117px !important;
        width: 100px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
      }

      &:not(:first-child) {
        &:before {
          width: 117px !important;
        }
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    .box {
      background-size: cover !important;
    }

    .our-solutions__content {
      &:before {
        top: 50px !important;
        left: 44px !important;
        width: 135px !important;
        height: 175px !important;
      }

      .our-solutions__ul__box ul li span {
        font-size: 16px !important;
      }
    }

    .our-solutions__slider {
      padding: 50px !important;
      padding-top: 0 !important;
    }
  }
  @include media-breakpoint-down(sm) {
    height: 1020px !important;

    .our-solutions__content {
      height: 580px !important;

      &:before {
        top: 0px !important;
      }

      .our-solutions__content__text {
        top: 75px !important;
      }

      .our-solutions__ul__box {
        top: 240px !important;
      }

      .our-solutions__read {
        bottom: 0 !important;
      }
    }

    .our-solutions__slider {
      height: 457px !important;
      .row {
        justify-content: center !important;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 1150px !important;

    .offset-md-2 {
      display: none !important;
    }

    .box {
      background-size: cover !important;
    }

    .our-solutions__content {
      height: 600px !important;

      &:before {
        top: 0px !important;
      }

      .our-solutions__content__text {
        top: 75px !important;
      }

      .our-solutions__ul__box {
        top: 383px;
        left: 16px !important;

        ul li span {
          font-size: 12px !important;
        }

        
        ul li {
          margin-bottom: 3px !important;
        }
      }

      .our-solutions__read {
        bottom: -60px !important;
      }
    }

    .our-solutions__slider {
      padding-top: 0 !important;
      height: 565px !important;
      .row {
        justify-content: center !important;
      }
    }
  }
}

.our-solutions__slider::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

#our-industries {
  height: 725px;
  background: white;
  position: relative;
  z-index: 0;

  .our-industries__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    background-color: #1e0094;
    border-radius: 0 0 50px 0;
    z-index: 1;

    &:before {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 50px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 23%;
      border-radius: 0;
      background-size: contain;
    }

    .our-industries__content__text {
      position: absolute;
      left: 100px;
      top: 100px;

      h2 {
        font-weight: 600;
        font-size: 46px;
        margin-bottom: 8px;
      }
    }

    .our-industries__left__read {
      position: absolute;
      top: 150px;

      .read-more__text {
        margin-left: 25px;
      }

      .read-more__icon__span {
        right: 34px;
      }
    }
  }

  .our-industries__right {
    position: absolute;
    padding-top: 100px;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    background-color: #eee;
    border-radius: 0 0 0 50px;
    z-index: 0;

    .our-industries__right__row {
      width: 100%;
    }

    .row {
      height: 100%;

      .items {
        .item {
          margin-bottom: 10px;
          border-radius: 18px;
          padding-left: 30px;
          box-sizing: border-box;
          display: inline-block;
          width: 49%;
          height: 300px;
          cursor: pointer;
          color: black;
          transition: 0.4s;

          h3 {
            margin: 50px 0 5px 0;
            font-size: 20px;
            font-weight: 650;
            color: black;
          }

          h2 {
            margin-bottom: 10px;
            color: #1e0094;
            font-weight: 700;
            margin-top: 30px !important;
          }

          p {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.5;
            color: #000;
          }

          .our-industries__item__read {
            margin-top: 35px;

            .read-more-component:hover .read-more__icon__span i {
              color: black !important;
            }

            .read-more-component:hover .read-more__icon__span i {
              color: white !important;
            }
          }

          &:hover {
            background-color: #20a9df;

            .read-more__divider {
              height: 1px;
              background-color: #1e0094;
            }

            p,
            h3 {
              color: white;
            }
          }

          .read-more-component:hover .read-more__icon__span {
            opacity: 1;
            background-color: #1e0094 !important;
          }
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .our-industries__right .item p {
      font-size: 16px;
      height: 88px !important;
      word-break: break-word !important;
    }

    .our-industries__content__text {
      position: absolute !important;
      left: 100px !important;
      top: 112px !important;

      h2 {
        font-weight: 600 !important;
        font-size: 35px !important;
        margin-bottom: 43px !important;
      }
    }
  }

  @include media-breakpoint-between(sm, md) {
    .item {
      padding-left: 20px !important;

      p {
        font-size: 13px !important;
      }
    }

    .our-industries__content__text {
      left: 68px !important;
      top: 123px !important;

      h2 {
        font-size: 30px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    height: 950px;

    .our-industries__left {
      border-radius: 0 !important;
      height: 300px !important;
      width: 100% !important;
      background-color: #1e0094 !important;

      .our-industries__left__read {
        position: absolute !important;
        top: 135px !important;
      }

      &:before {
        top: 50px !important;
        left: 44px !important;
        width: 150px !important;
        height: 150px !important;
      }

      .our-industries__content__text {
        left: 100px !important;
        top: 100px !important;

        h2 {
          font-weight: 600 !important;
          font-size: 46px !important;
          margin-bottom: 8px !important;
        }
      }
    }

    .our-industries__right {
      position: absolute;
      padding-top: 100px;
      top: 225px;
      right: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: #eee;
      padding-left: 12px;
      border-radius: 0 0 0 50px;
      z-index: 0;
    }
  }

  @include media-breakpoint-only(xs) {
    height: 1645px !important;

    .item {
      width: 100% !important;
      height: 320px !important;
    }
  }
}

#our-customers {
  height: 600px;
  background: #fff;
  position: relative;
  z-index: 0;
  z-index: unset;

  .our-customers__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    border-radius: 0 0 50px 0;
    z-index: 1;

    &:before {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 50px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 28%;
      border-radius: 0;
      background-size: contain;
    }

    .our-customers__content__text {
      position: absolute;
      left: 104px;
      top: 100px;

      h2 {
        font-weight: 650;
        font-size: 46px;
        margin-bottom: 8px;
        line-height: 48px;
        color: #1e0094;
      }
    }
  }

  .our-customers__right {
    position: absolute;
    padding-top: 307px;
    top: 0;
    right: 0;
    height: 100%;
    width: 84%;
    z-index: 1;

    .items {
      padding: 0;
      margin: 0;
      justify-content: space-evenly;
      .item {
        overflow: hidden;
        border-radius: 25px;
        height: 265px;
        width: 20%;
        position: relative;
        border: 1px solid #1f96d5;
        transition: 0.4s;
        z-index: 100000;
        background-color: white;
        background-repeat: no-repeat;
        background-size: contain;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);

        &:hover {
          filter: none;
        }

        &:hover {
          &:before {
            filter: none;
          }
        }

        &:before {
          content: " ";
          background-repeat: no-repeat;
          background-size: 32%;
          background-position: bottom center;
          position: absolute;
          top: 7%;
          left: 7%;
          background-repeat: no-repeat;
          width: 91%;
          height: 80%;
          border-radius: 0;
          background-size: contain;
          filter: grayscale(1);
        }

        &:nth-child(1) {
          background-image: url(../images/Homepage/our-customers/a1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center;
        }

        &:nth-child(2) {
          background-image: url(../images/Homepage/our-customers/b1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center;
        }

        &:nth-child(3) {
          background-image: url(../images/Homepage/our-customers/c1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center;
        }

        &:nth-child(4) {
          background-image: url(../images/Homepage/our-customers/d1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center;
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .our-customers__right {
      height: 100%;
      width: 100%;

      .item {
        height: 265px;
        width: 227px;
      }
    }

    .our-customers__left:before {
      width: 150px;
      height: 150px;
    }
  }

  @include media-breakpoint-between(sm, md) {
    height: 540px !important;

    .item {
      height: 220px !important;
      width: 23% !important;
    }

    .our-customers__left:before {
      width: 150px;
      height: 150px;
    }
  }

  @include media-breakpoint-down(sm) {
    height: 875px !important;

    .our-customers__right {
      height: 100% !important;
      width: 100% !important;
    }

    .item {
      height: 258px !important;
      width: 32% !important;
    }

    .our-customers__left:before {
      width: 150px;
      height: 150px;
    }
  }

  @include media-breakpoint-only(xs) {
    height: 875px !important;

    .item {
      height: 258px !important;
      width: 46% !important;
    }
  }
}

#our-partners2 {
  height: 700px;
  background: #fff;
  position: relative;
  z-index: 0;

  .our-partners__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    border-radius: 0 0 50px 0;
    z-index: 1;

    &:before {
      content: " ";
      background-image: url(../images/Homepage/our-partners/bg.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: -225px;
      left: 0;
      z-index: -83;
      width: 294%;
      height: 126%;
      border-radius: 0;
      background-size: contain;
      left: -27px;
    }

    &:after {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 150px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 28%;
      border-radius: 0;
      background-size: contain;
    }

    .our-partners__content__text {
      position: absolute;
      left: 104px;
      top: 200px;

      h2 {
        font-weight: 650;
        font-size: 46px;
        margin-bottom: 8px;
        line-height: 48px;
        color: #1e0094;
      }
    }
  }

  .our-partners__right {
    position: absolute;
    bottom: 0px;
    border-radius: 0 50px 50px 0;
    left: 0;
    height: 38%;
    width: 77%;
    z-index: 2;
    background-color: #ebebeb;

    .items {
      justify-content: space-between;
      align-items: center;
      height: 100%;

      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        width: 1px;
        height: 38%;
        color: black;
        background-color: gray;
        z-index: 10000;
        display: block;
      }

      .item {
        cursor: pointer;
        height: 100px;
        width: 23.5%;
        position: relative;
        transition: 0.4s;

        &:hover {
          &:before {
            filter: none;
          }
        }

        background-image: url(../images/Homepage/our-partners/p11.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        padding: 51px;

        &:nth-child(1) {
          background-image: url(../images/Homepage/our-partners/a1.png);
        }

        &:nth-child(3) {
          background-image: url(../images/Homepage/our-partners/b1.png);
        }

        &:nth-child(5) {
          background-image: url(../images/Homepage/our-partners/c1.png);
        }

        &:nth-child(7) {
          background-image: url(../images/Homepage/our-partners/d1.png);
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .item {
      width: 22%;
    }

    .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important;
    }

    .our-partners__left:before {
      top: -260px !important;
    }

    .our-partners__content__text {
      top: 145px !important;
    }

    .our-partners__left:after {
      top: 100px;
      width: 150px;
      height: 150px;
    }

    .our-partners__right {
      top: 330px !important;
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 415px !important;

    .our-partners__left:before {
      top: -154px !important;
    }
    .our-partners__right {
      top: 241px !important;
    }

    .our-partners__left:after {
      top: 60px !important;
      width: 150px !important;
      height: 150px !important;
    }

    .our-partners__content__text {
      top: 98px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .item {
      width: 22% !important;
    }

    .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important;
    }

    .our-partners__right {
      width: 100%;
      border-radius: 0 !important;
    }

    .our-partners__right {
      top: 290px !important;
    }
  }
  @include media-breakpoint-only(xs) {
    height: 490px;

    .our-partners__right {
      top: 216px !important;
      height: 48% !important;
      width: 100%;
      border-radius: 0 !important;
    }

    .item {
      width: 47% !important;

      &:nth-child(4):before {
        left: -8px !important;
        width: 100% !important;
      }
    }

    .our-partners__left {
      .our-partners__content__text {
        left: 85px !important;
        top: 81px !important;
        h2 {
          font-size: 38px;
        }
      }

      &:after {
        width: 100px !important;
        height: 100px !important;
        top: 77px !important;
      }

      &:before {
        top: -308px;
      }
    }
  }
}

#our-partners {
  height: 700px;
  background: #fff;
  position: relative;
  z-index: 0;

  .our-partners__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    border-radius: 0 0 50px 0;
    z-index: 1;

    &:before {
      content: " ";
      background-image: url(../images/Homepage/our-partners/bg.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: -225px;
      left: 0;
      z-index: -83;
      width: 294%;
      height: 126%;
      border-radius: 0;
      background-size: contain;
      left: -27px;
    }

    &:after {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 150px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 28%;
      border-radius: 0;
      background-size: contain;
    }

    .our-partners__content__text {
      position: absolute;
      left: 104px;
      top: 200px;

      h2 {
        font-weight: 650;
        font-size: 46px;
        margin-bottom: 8px;
        line-height: 48px;
        color: #1e0094;
      }
    }
  }

  .our-partners__right {
    position: absolute;
    bottom: 0px;
    border-radius: 0 50px 50px 0;
    left: 0;
    height: 38%;
    width: 77%;
    z-index: 2;
    background-color: #ebebeb;

    .items {
      justify-content: space-between;
      align-items: center;
      height: 100%;

      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        width: 1px;
        height: 38%;
        color: black;
        background-color: gray;
        z-index: 10000;
        display: block;
      }

      .item {
        height: 100px;
        width: 23.5%;
        position: relative;
        transition: 0.4s;

        &:hover {
          &:before {
            filter: none;
          }
          filter: none;
        }

        background-image: url(../images/Homepage/our-partners/p11.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 66%;
        filter: grayscale(0.9);
        padding: 51px;

        &:nth-child(1) {
          background-image: url(../images/Homepage/our-partners/a1.png);
        }

        &:nth-child(3) {
          background-image: url(../images/Homepage/our-partners/b1.png);
        }

        &:nth-child(5) {
          background-image: url(../images/Homepage/our-partners/c1.png);
        }

        &:nth-child(7) {
          background-image: url(../images/Homepage/our-partners/d1.png);
        }
      }
    }
  }

  @include media-breakpoint-between(md, lg) {
    .item {
      width: 22% !important;
    }

    .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important;
    }

    .our-partners__left:before {
      top: -260px !important;
    }

    .our-partners__content__text {
      top: 145px !important;
    }

    .our-partners__left:after {
      top: 100px;
      width: 150px;
      height: 150px;
    }

    .our-partners__right {
      top: 330px !important;
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 415px !important;
    .item {
      width: 22.5% !important;
    }

    .our-partners__left:before {
      top: -154px !important;
    }
    .our-partners__right {
      top: 241px !important;
    }

    .our-partners__left:after {
      top: 60px !important;
      width: 150px !important;
      height: 150px !important;
    }

    .our-partners__content__text {
      top: 98px !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .item {
      width: 22% !important;
    }

    .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important;
    }

    .our-partners__right {
      width: 100%;
      border-radius: 0 !important;
    }

    .our-partners__right {
      top: 290px !important;
    }
  }
  @include media-breakpoint-only(xs) {
    height: 490px;

    .our-partners__right {
      top: 216px !important;
      height: 48% !important;
      width: 100%;
      border-radius: 0 !important;
    }

    .item {
      width: 47% !important;

      &:nth-child(4):before {
        left: -8px !important;
        width: 100% !important;
      }

     
    }

    hr {
      display: none !important;
    }

    .our-partners__left {
      .our-partners__content__text {
        left: 85px !important;
        top: 81px !important;
        h2 {
          font-size: 38px;
        }
      }

      &:after {
        width: 100px !important;
        height: 100px !important;
        top: 77px !important;
      }

      &:before {
        top: -308px;
      }
    }
  }
}

#white-space {
  height: 100px;
  background-color: white;
}

// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #you-trust {
    height: 42rem;
  }

  #about-us {
    .about-us__content:before {
      height: 33% !important;
    }

    .about-us__images {
      height: 106% !important;

      &:before {
        height: 45% !important;
      }

      &:after {
        height: 37% !important;
      }
    }
  }

}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #you-trust {
    height: 48rem !important;

    #right-square {
      width: 600px;
      height: 620px;

      .right-square__cyper {
        font-size: 33px !important;
      }
    }
  }

  #about-us {
    height: 625px !important;
    .about-us__content:before {
      height: 36% !important;
    }

    .about-us__images {
      height: 113% !important;

      &:before {
        height: 45% !important;
        width: 50% !important;
      }

      &:after {
        height: 41% !important;
      }
    }
  }

  #our-solutions .container-xl .row .our-solutions__slider .row div.box {
    height: 370px !important;
    &:before {
      height: 175px !important;
      width: 150px !important;
    }

    &:not(:first-child) {
      &:before {
        width: 175px !important;
        height: 175px !important;
      }
    }
  }
}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

  #about-us {
    .about-us__images {
      background-size: 34% !important;

      &:before {
        top: 174px !important;
        left: 118px !important;
        width: 39% !important;
        height: 43% !important;
      }

      &:after {
        top: 214px !important;
        right: 100px !important;
        width: 32% !important;
        height: 35% !important;
      }
    }

    .about-us__content {
      &:before {
        left: 68px !important;
        width: 150px !important;
        height: 150px !important;
      }
    }
  }


  #our-solutions {
    .our-solutions__ul__box {
      li {
        span {
          font-size: 18px !important;
        }
      }
    }
  }


}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}

.d-none-custom {
  display: none;
}
