#about-main {
  position: relative;
  height: 40rem;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/about-us/bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0;

    .row p {
      width: 60%;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      margin-left: 65px;
      font-weight: 610;
      font-size: 6em;
      line-height: 0.9;
      margin-top: 140px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -21px;
        left: -58px;
        z-index: -1;
        width: 25%;
        height: 176%;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }
  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 2.3em !important;
        margin-top: -70px !important;
        margin-left: 0 !important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
      }

      div {
        padding-left: 25px !important;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

#about-text {
  position: relative;
  .container-xl {
    position: relative;
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  p {
    color: #000 !important;
    width: 95%;
    font-size: 16px;
    font-weight: 400;
  }

  .left-square {
    p {
      font-size: 17px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 12px;
      width: 35px;
      height: 3px;
      background-color: #2196f3;
    }

    .iTEAM {
      font-weight: 600;
    }
  }

  .right-square {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 12px;
      width: 35px;
      height: 3px;
      background-color: #2196f3;
    }
  }

  @include media-breakpoint-between(md, lg) {
  }
  @include media-breakpoint-between(sm, md) {
    .container-xl {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .container-xl {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
    }
  }
  @include media-breakpoint-only(xs) {
  }
}

#our-team {
  height: 750px;
  position: relative;

  .container-xl {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 90%;
      height: 100%;
      background-size: cover;
      background-image: url(../images/about-us/our-team-bg.png);
      background-repeat: no-repeat;
      background-position: top center;
      opacity: 0.15;
      border-radius: 40px 0 0 0;
    }

    .left-content {
      margin-left: 30px;
    }

    h2 {
      margin-left: -20px;
      font-weight: 610;
      font-size: 50px;
      line-height: 0.9;
      margin-top: 158px;
      position: relative;
      color: #1e0094;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -56px;
        left: -59px;
        z-index: -1;
        width: 18%;
        height: 343%;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    p {
      color: black;
      margin-left: -15px;
      font-size: 16px;
      font-weight: 500;
    }

    blockquote {
      color: #1e0094;
      margin-left: -15px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  @include media-breakpoint-between(md, lg) {
    h2:before {
      width: 25% !important;
    }
  }
  @include media-breakpoint-between(sm, md) {
    h2:before {
      width: 27% !important;
    }
  }
  @include media-breakpoint-down(sm) {
    h2 {
      margin-left: 15px !important;
    }
    h2:before {
      left: -35px !important;
      width: 32% !important;
    }

    blockquote {
      font-size: 15px !important;
    }
  }
  @include media-breakpoint-only(xs) {
    h2:before {
      width: 47% !important;
    }
  }
}

// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

  #about-main {
    height: 42rem;
  }

  .mid {
    width: 377px !important;
    height: 88% !important;
  }
}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

  #about-main {
    height: 48rem !important;

    #right-square1 {
      width: 600px;

      &:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px;
      }

      &:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px;
      }

      .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }
}

// end big screens media query
@include media-breakpoint-between(md, lg) {
}
@include media-breakpoint-between(sm, md) {
}
@include media-breakpoint-down(sm) {
}
@include media-breakpoint-only(xs) {
}
