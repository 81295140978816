
  
  $border-color:$primary;
  .hover-box {
    position:relative;
    vertical-align: middle;
    display: inline-block;
    height: 60px;
    line-height: 60px;
    text-align: center;
    transition: 0.5s;
    padding: 0 20px;
    cursor: pointer;
    border: 2px solid transparent;
    -webkit-transition:0.5s;
  }
  
  .hover-box:hover {
    border: 2px solid rgba(0,160,80,0);
    color: $border-color;
  }
  
  .hover-box::before, .hover-box::after {
    width: 100%;
    height:100%;
    z-index: 3;
    content:'';
    position: absolute;
    top:0;
    left:0;
    box-sizing: border-box;
    -webkit-transform: scale(0);
    transition: 0.5s;
  }
  
  .foo::before {
    border-bottom: 3px solid $border-color;
    border-left: 3px solid $border-color;
    -webkit-transform-origin: 0 100%;
  }
  
  .foo::after {
    border-top: 3px solid $border-color;
    border-right: 3px solid $border-color;
    -webkit-transform-origin: 100% 0%;
  }
  
  
  .bar::before {
    border-bottom: 3px solid $border-color;
    border-left: 3px solid $border-color;
    -webkit-transform-origin: 100% 0%;
  }
  
  .bar::after {
    border-top: 3px solid $border-color;
    border-right: 3px solid $border-color;
    -webkit-transform-origin: 0% 100%;
  }
  
  .curmudgeon::before {
    border-bottom: 3px solid $border-color;
    border-left: 0;
    -webkit-transform-origin: 0% 100%;
  }
  
  .curmudgeon::after {
    border-top: 0;
    border-right: 0;
    -webkit-transform-origin: 50% 50%;
  }
  
  .hover-box:hover::after, .hover-box:hover::before {
    -webkit-transform: scale(1);
  }
  
  .link {
    line-height: 1em;
    position: absolute;
    bottom: 0;
    right: 20px;
    width: 300px;
    height: 50px;
    font-family: "Open Sans","Roboto",Arial,sans-serif;
    text-align: right; 
  }
  
  .link > a {
    text-decoration: none;
    color: #FF0;
  }