#hr-main {
  position: relative;
  height: 40rem;

  .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0;
  }

  #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/hr/header.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0;

    .row p {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px;
    }


    .left-content {
      margin-left: 30px;
    }

    h2 {
      font-size: 5em;
      margin-left: 74px;
      font-weight: 650;
      line-height: 1;
      margin-top: 250px;
      position: relative;

      &:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -40px;
        left: -58px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important;
      }
    }

    a {
      border-radius: 5px;
    }

    &:after {
      content: " ";
      background-image: url(../images/hr/icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(+50%) translateY(-50%);
    }
  }

  #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0;

    .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat;
    }

    &:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px;
    }

    &:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .mid {
      right: 64% !important;
    }

    #right-square1 {
      &:before {
        right: 28% !important;
      }

      &:after {
        right: 33% !important;
      }
    }
  }

  @include media-breakpoint-between(sm, md) {
    height: 37rem;

    #left-square {
      width: 86%;

      h2 {
        margin-top: 122px;
        font-weight: 610;
        font-size: 4.5em;
        line-height: 0.9;
      }
    }

    #right-square1 {
      &:before {
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 65px;
      }
    }

    .mid {
      right: 6% !important;
    }
  }

  @include media-breakpoint-down(sm) {
    #left-square {
      width: 86%;

      .left-content {
        align-items: flex-start !important;
      }

      h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 1.2;
        margin-left: 16px;

        &:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%;
        }
      }
    }

    #right-square1 {
      &:before {
        width: 209px;
        height: 256px;
        right: -32% !important;
      }

      &:after {
        right: -22% !important;
        top: 93px;
      }
    }


  }

  @include media-breakpoint-only(xs) {
    height: 34rem;

    .container-xl {
      padding: 0;
    }

    #left-square {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0;

      .left-content {
        display: flex;
        margin-left: 0 !important;
        // justify-content: center !important;
        align-items: center !important;
      }

      h2 {
        font-size: 2.3em !important;
        margin-top: 280px !important;
        margin-left: 0 !important;
      }

      p {
        width: 92% !important;
        margin-top: 0px !important;
      }

      div {
        padding-left: 25px !important;
      }

      .row p {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.4;
        margin-left: 5px !important;
      }
    }

    #left-square {
      &:after {
        top: 41% !important;
        right: 25% !important;
        width: 190px;
        height: 155px;
      }
    }

    #right-square1 {
      position: relative !important;
      border-radius: 0 !important;
      width: 100% !important;
      padding: 30px !important;
      height: 450px !important;
      text-align: center;

      &:before {
        width: 150px;
        height: 203px;
        right: 0% !important;
        bottom: -94px;
      }

      &:after {
        right: 8% !important;
        top: 158px;
        width: 92px;
        height: 76%;
      }

      .mid {
        position: absolute;
        bottom: -96px;
        right: 30px;
        width: 253px;
        height: 84%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat;
      }
    }
  }
}


.what-hr {
  color: black;

  .content {
    padding-left: 20px;

    h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650;

      .blue {
        color: #1e0094;
      }
    }

    ul {
      list-style: none;
      padding-left: 1.5rem;

      li {
        position: relative;
        width: 65%;
        font-size: 17px;
      }
    }
  }

  @include media-breakpoint-between(md, lg) {}

  @include media-breakpoint-between(sm, md) {}

  @include media-breakpoint-down(sm) {}

  @include media-breakpoint-only(xs) {
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 30px !important;

    .content h2 {
      font-size: 35px !important;
      width: 100% !important;
      font-weight: 650 !important;
      padding-left: 0;
    }

    .content ul {
      margin-left: -15px !important;

      li {
        position: relative !important;
        width: 100% !important;
        font-size: 13px !important;
      }
    }

    .container-xl:after {
      display: none !important;
    }
  }
}


.hr-services {
  color: black;

  .content {
    padding-left: 1.5rem;

    h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650;
    }

    .contract {
      padding-left: 25px;
      font-size: 2rem;
      font-weight: 600;

      span {
        color: #20a9df;
      }
    }

    ul {
      list-style: none;

      li {
        position: relative;
        font-size: 16px;
        width: 90%;

        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #20a9df;
          left: -15px;
          display: block;
          top: 7px;
        }
      }
    }

  }

  .blue {
    color: #1e0094;
  }

  .card {
    background-color: transparent;
    border: 1px solid #1e0094;
    border-radius: 40px;

    h3 {
      color: #1e0094;
    }

    .term {
      font-size: 1.8rem;
      padding-left: 40px;
    }

    .hr {
      border: 0;
      width: 75%;
      border-top: 2px solid #20a9df;
    }

    @include media-breakpoint-between(md, lg) {
      height: 450px !important;
    }
    @media (min-width: 1650px) {
        height: 350px !important;
    }

  }



  @include media-breakpoint-between(md, lg) {}

  @include media-breakpoint-between(sm, md) {}

  @include media-breakpoint-down(sm) {}

  @include media-breakpoint-only(xs) {
    overflow-x: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 30px !important;


    .container-xl:after {
      display: none !important;
    }

    .content {
      padding-left: 0 !important;
    }
  }
}



.services-kinds {
  position: relative;


  .container-xl {
    position: relative;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 80%;
      height: 40%;
      background-color: #1e0094;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      border-top-right-radius: 50px;
    }

    &:after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 65%;
      height: 55%;
      background-color: #1e0094;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      border-bottom-left-radius: 50px;
    }

  }

  .services-kinds__item {
    background-color: white;
    border-radius: 40px;
    border: 1px solid #1e0094;

    h3 {
      color: #20a9df;
      font-size: 3.5rem;
      font-weight: 700;

      @include media-breakpoint-only(xs) {
        font-size: 2.7rem !important;
      }
    }

    p {
      color: black;
      font-size: 16px;
      margin-bottom: 20px;

      @include media-breakpoint-only(xs) {
        font-size: 14px !important;
      }
    }

  }


}



.services-industries {
  color: black;

  .content {
    padding-left: 20px;

    h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650;

      .blue {
        color: #1e0094;
      }
    }


  }




  overflow-x: hidden;

  .header {
    h2 {
      color: #1e0094;
      font-weight: 700;
      font-size: 35px;
      margin-bottom: 10px;
    }

    p {
      color: #000 !important;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .card {
    border-radius: 40px;
    background-color: #1e0094 !important;
    color: #20a9df;

    .hr {
      width: 70%;
      background-color: #20a9df;
      border: none;
      height: 2px;
      text-align: left;
      color: #20a9df;
      margin-left: 0 !important;
    }

    h3 {
      font-size: 27px;
      width: 90%;
      font-weight: 600 !important;

      span {
        color: #fff;
      }
    }

    .h32 {
      font-size: 23px !important;
    }

    .h33 {
      font-size: 27px;
      width: 97%;

      span {
        font-size: 24px;
      }
    }

    p {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    span {
      color: white;
      font-size: 2rem;
      display: inline-block;
    }


    transition: all 0.1s ease-in-out;

    &:hover {
      background-color: transparent !important;
      border: 1px solid #1e0094 !important;

      span {
        color: #1e0094 !important;
      }

      p {
        color: black !important;
      }
    }

    @include media-breakpoint-between(md, lg) {
      height: 450px !important;
    }
    @media (min-width: 1490px) {
        height: 350px !important;
    }

  }

}



// start big screens media query

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%;
  }

}

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%;
  }

}





@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px;
  }

}

// end big screens media query
@include media-breakpoint-between(md, lg) {}

@include media-breakpoint-between(sm, md) {}

@include media-breakpoint-down(sm) {}

@include media-breakpoint-only(xs) {}