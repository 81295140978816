@charset "UTF-8";
/*
Template Name: Hyper - Responsive Bootstrap 4 Admin Dashboard
Version: 3.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2c8ef8;
  --indigo: #727cf5;
  --purple: #6b5eae;
  --pink: #ff679b;
  --red: #E53935;
  --orange: #FB8C00;
  --yellow: #FDD835;
  --green: #43A047;
  --teal: #02a8b5;
  --cyan: #39afd1;
  --white: #fff;
  --gray: #98a6ad;
  --gray-dark: #343a40;
  --primary: #1e0094;
  --secondary: #118BCC;
  --success: #43A047;
  --info: #2059cc;
  --warning: #FDD835;
  --danger: #E53935;
  --light: #adb5bd;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1367px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Poppins";
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #1e0094;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0e0048;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.5rem;
  font-weight: 400;
  line-height: 1.1; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.875rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 0.9375rem; }

h6, .h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.125rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #eef2f7; }

small,
.small {
  font-size: 0.75rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 6px; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #39afd1;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1367px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1367px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1367px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #fff; }
  .table th,
  .table td {
    padding: 0.95rem;
    vertical-align: top;
    border-top: 1px solid #eef2f7; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eef2f7; }
  .table tbody + tbody {
    border-top: 2px solid #eef2f7; }

.table-sm th,
.table-sm td {
  padding: 0.5rem; }

.table-bordered {
  border: 1px solid #eef2f7; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eef2f7; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f3fa; }

.table-hover tbody tr:hover {
  color: #fff;
  background-color: #f1f3fa; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c0b8e1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #8a7ac7; }

.table-hover .table-primary:hover {
  background-color: #b0a6d9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b0a6d9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #bcdff1; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #83c3e4; }

.table-hover .table-secondary:hover {
  background-color: #a7d5ed; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #a7d5ed; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cae4cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9dce9f; }

.table-hover .table-success:hover {
  background-color: #b9dbba; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b9dbba; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c1d1f1; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8ba9e4; }

.table-hover .table-info:hover {
  background-color: #acc2ec; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #acc2ec; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fef4c6; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #feeb96; }

.table-hover .table-warning:hover {
  background-color: #feefad; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #feefad; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f8c8c6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f19896; }

.table-hover .table-danger:hover {
  background-color: #f5b2af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f5b2af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #e8eaed; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #d4d9dd; }

.table-hover .table-light:hover {
  background-color: #dadde2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #dadde2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #98a6ad;
  background-color: #313a46;
  border-color: #414d5d; }

.table .thead-light th {
  color: #6c757d;
  background-color: #f1f3fa;
  border-color: #eef2f7; }

.table-dark {
  color: #98a6ad;
  background-color: #313a46; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #414d5d; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1366.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 0px);
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #000;
  background-clip: padding-box;
  border: 0px solid #000;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #fff; }
  .form-control:focus {
    color: #495057;
    background-color: #000;
    border-color: #000;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #fff;
  background-color: #000; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.45rem + 0px);
  padding-bottom: calc(0.45rem + 0px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 0px);
  padding-bottom: calc(0.5rem + 0px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.28rem + 0px);
  padding-bottom: calc(0.28rem + 0px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 0px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.8725rem + 0px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(2.875rem + 0px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #98a6ad; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #43A047; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(67, 160, 71, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #43A047;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2343A047' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #43A047;
    box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #43A047;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2343A047' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #43A047;
    box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #43A047; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #43A047; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #43A047; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #5bbb60;
  background-color: #5bbb60; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #43A047; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #43A047; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #43A047;
  box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #E53935; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(229, 57, 53, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #E53935;
  padding-right: 2.25rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E53935' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E53935' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #E53935;
    box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #E53935;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23E53935' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23E53935' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #E53935;
    box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #E53935; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #E53935; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #E53935; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #eb6562;
  background-color: #eb6562; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #E53935; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #E53935; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #E53935;
  box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  line-height: 1.5;
  border-radius: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #fff;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(30, 0, 148, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #1e0094;
  border-color: #1e0094; }
  .btn-primary:hover {
    color: #fff;
    background-color: #16006e;
    border-color: #140061; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #16006e;
    border-color: #140061;
    box-shadow: 0 0 0 0.2rem rgba(64, 38, 164, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #1e0094;
    border-color: #1e0094; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #140061;
    border-color: #110054; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(64, 38, 164, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #118BCC;
  border-color: #118BCC; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #0e73a9;
    border-color: #0d6b9d; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #0e73a9;
    border-color: #0d6b9d;
    box-shadow: 0 0 0 0.2rem rgba(53, 156, 212, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #118BCC;
    border-color: #118BCC; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0d6b9d;
    border-color: #0c6391; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(53, 156, 212, 0.5); }

.btn-success {
  color: #fff;
  background-color: #43A047;
  border-color: #43A047; }
  .btn-success:hover {
    color: #fff;
    background-color: #38853b;
    border-color: #347c37; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #38853b;
    border-color: #347c37;
    box-shadow: 0 0 0 0.2rem rgba(95, 174, 99, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #43A047;
    border-color: #43A047; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #347c37;
    border-color: #307333; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(95, 174, 99, 0.5); }

.btn-info {
  color: #fff;
  background-color: #2059cc;
  border-color: #2059cc; }
  .btn-info:hover {
    color: #fff;
    background-color: #1b4bab;
    border-color: #1946a0; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #1b4bab;
    border-color: #1946a0;
    box-shadow: 0 0 0 0.2rem rgba(65, 114, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #2059cc;
    border-color: #2059cc; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1946a0;
    border-color: #174195; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(65, 114, 212, 0.5); }

.btn-warning {
  color: #313a46;
  background-color: #FDD835;
  border-color: #FDD835; }
  .btn-warning:hover {
    color: #313a46;
    background-color: #fdd10f;
    border-color: #fdce03; }
  .btn-warning:focus, .btn-warning.focus {
    color: #313a46;
    background-color: #fdd10f;
    border-color: #fdce03;
    box-shadow: 0 0 0 0.2rem rgba(222, 192, 56, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #313a46;
    background-color: #FDD835;
    border-color: #FDD835; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #313a46;
    background-color: #fdce03;
    border-color: #f0c402; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 192, 56, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #E53935;
  border-color: #E53935; }
  .btn-danger:hover {
    color: #fff;
    background-color: #d8201c;
    border-color: #cd1e1a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #d8201c;
    border-color: #cd1e1a;
    box-shadow: 0 0 0 0.2rem rgba(233, 87, 83, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #E53935;
    border-color: #E53935; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #cd1e1a;
    border-color: #c11d19; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 87, 83, 0.5); }

.btn-light {
  color: #313a46;
  background-color: #adb5bd;
  border-color: #adb5bd; }
  .btn-light:hover {
    color: #313a46;
    background-color: #98a2ac;
    border-color: #919ca6; }
  .btn-light:focus, .btn-light.focus {
    color: #313a46;
    background-color: #98a2ac;
    border-color: #919ca6;
    box-shadow: 0 0 0 0.2rem rgba(154, 163, 171, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #313a46;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #313a46;
    background-color: #919ca6;
    border-color: #8a95a1; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(154, 163, 171, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #1e0094;
  border-color: #1e0094; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #1e0094;
    border-color: #1e0094; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(30, 0, 148, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #1e0094;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #1e0094;
    border-color: #1e0094; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(30, 0, 148, 0.5); }

.btn-outline-secondary {
  color: #118BCC;
  border-color: #118BCC; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #118BCC;
    border-color: #118BCC; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(17, 139, 204, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #118BCC;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #118BCC;
    border-color: #118BCC; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(17, 139, 204, 0.5); }

.btn-outline-success {
  color: #43A047;
  border-color: #43A047; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #43A047;
    border-color: #43A047; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #43A047;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #43A047;
    border-color: #43A047; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.5); }

.btn-outline-info {
  color: #2059cc;
  border-color: #2059cc; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #2059cc;
    border-color: #2059cc; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 89, 204, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #2059cc;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #2059cc;
    border-color: #2059cc; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(32, 89, 204, 0.5); }

.btn-outline-warning {
  color: #FDD835;
  border-color: #FDD835; }
  .btn-outline-warning:hover {
    color: #313a46;
    background-color: #FDD835;
    border-color: #FDD835; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 216, 53, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FDD835;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #313a46;
    background-color: #FDD835;
    border-color: #FDD835; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(253, 216, 53, 0.5); }

.btn-outline-danger {
  color: #E53935;
  border-color: #E53935; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #E53935;
    border-color: #E53935; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #E53935;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #E53935;
    border-color: #E53935; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.5); }

.btn-outline-light {
  color: #adb5bd;
  border-color: #adb5bd; }
  .btn-outline-light:hover {
    color: #313a46;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #adb5bd;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #313a46;
    background-color: #adb5bd;
    border-color: #adb5bd; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #1e0094;
  text-decoration: none; }
  .btn-link:hover {
    color: #0e0048;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #adb5bd;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 1rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 1rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.25em solid;
    border-right: 0.25em solid transparent;
    border-bottom: 0;
    border-left: 0.25em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #a5adb6;
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1367px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #272e37;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #343a40;
    text-decoration: none;
    background-color: #b5bdc4; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #fff; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: 0px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: 0px; }

.input-group-prepend {
  margin-right: 0px; }

.input-group-append {
  margin-left: 0px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0px solid #000;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 0px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8725rem + 0px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.9rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.35rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.175rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #1e0094;
    border-color: #1e0094;
    background-color: #1e0094; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(30, 0, 148, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.175rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #000 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.175rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #1e0094;
  background-color: #1e0094; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 0, 148, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(30, 0, 148, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(30, 0, 148, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.175rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #000;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(30, 0, 148, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 0px);
  padding: 0.45rem 1.9rem 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 0px solid #000;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #000;
    outline: 0;
    box-shadow: none; }
    .custom-select:focus::-ms-value {
      color: #fff;
      background-color: #000; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.9rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 0px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 0px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #000;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 0px);
  padding: 0.45rem 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #000;
  border: 0px solid #000;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.45rem 0.9rem;
    line-height: 1.5;
    color: #fff;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.2rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(30, 0, 148, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(30, 0, 148, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.1rem rgba(30, 0, 148, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #1e0094;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #6d48ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #1e0094;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #6d48ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.1rem;
    margin-left: 0.1rem;
    background-color: #1e0094;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #6d48ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #1e0094; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1366.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1367px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #eef2f7; }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid #eef2f7; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #ced4da;
      content: ""; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #adb5bd; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #343a40;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #343a40;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(30, 0, 148, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1e0094;
  border-color: #1e0094; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #1e0094; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #140061; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(30, 0, 148, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #118BCC; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #0d6b9d; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(17, 139, 204, 0.5); }

.badge-success {
  color: #fff;
  background-color: #43A047; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #347c37; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(67, 160, 71, 0.5); }

.badge-info {
  color: #fff;
  background-color: #2059cc; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1946a0; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 89, 204, 0.5); }

.badge-warning {
  color: #313a46;
  background-color: #FDD835; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #313a46;
    background-color: #fdce03; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 216, 53, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #E53935; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #cd1e1a; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(229, 57, 53, 0.5); }

.badge-light {
  color: #313a46;
  background-color: #adb5bd; }
  a.badge-light:hover, a.badge-light:focus {
    color: #313a46;
    background-color: #919ca6; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(173, 181, 189, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.85rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #10004d;
  background-color: #d2ccea;
  border-color: #c0b8e1; }
  .alert-primary hr {
    border-top-color: #b0a6d9; }
  .alert-primary .alert-link {
    color: #05001a; }

.alert-secondary {
  color: #09486a;
  background-color: #cfe8f5;
  border-color: #bcdff1; }
  .alert-secondary hr {
    border-top-color: #a7d5ed; }
  .alert-secondary .alert-link {
    color: #05283b; }

.alert-success {
  color: #235325;
  background-color: #d9ecda;
  border-color: #cae4cb; }
  .alert-success hr {
    border-top-color: #b9dbba; }
  .alert-success .alert-link {
    color: #142f15; }

.alert-info {
  color: #112e6a;
  background-color: #d2def5;
  border-color: #c1d1f1; }
  .alert-info hr {
    border-top-color: #acc2ec; }
  .alert-info .alert-link {
    color: #0a1b3e; }

.alert-warning {
  color: #84701c;
  background-color: #fff7d7;
  border-color: #fef4c6; }
  .alert-warning hr {
    border-top-color: #feefad; }
  .alert-warning .alert-link {
    color: #5a4c13; }

.alert-danger {
  color: #771e1c;
  background-color: #fad7d7;
  border-color: #f8c8c6; }
  .alert-danger hr {
    border-top-color: #f5b2af; }
  .alert-danger .alert-link {
    color: #4e1412; }

.alert-light {
  color: #5a5e62;
  background-color: #eff0f2;
  border-color: #e8eaed; }
  .alert-light hr {
    border-top-color: #dadde2; }
  .alert-light .alert-link {
    color: #424547; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1e0094;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #eef2f7; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #f9f9fd; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #1e0094;
    border-color: #1e0094; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1367px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #10004d;
  background-color: #c0b8e1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #10004d;
    background-color: #b0a6d9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #10004d;
    border-color: #10004d; }

.list-group-item-secondary {
  color: #09486a;
  background-color: #bcdff1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #09486a;
    background-color: #a7d5ed; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #09486a;
    border-color: #09486a; }

.list-group-item-success {
  color: #235325;
  background-color: #cae4cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #235325;
    background-color: #b9dbba; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #235325;
    border-color: #235325; }

.list-group-item-info {
  color: #112e6a;
  background-color: #c1d1f1; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #112e6a;
    background-color: #acc2ec; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #112e6a;
    border-color: #112e6a; }

.list-group-item-warning {
  color: #84701c;
  background-color: #fef4c6; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #84701c;
    background-color: #feefad; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #84701c;
    border-color: #84701c; }

.list-group-item-danger {
  color: #771e1c;
  background-color: #f8c8c6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #771e1c;
    background-color: #f5b2af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #771e1c;
    border-color: #771e1c; }

.list-group-item-light {
  color: #5a5e62;
  background-color: #e8eaed; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #5a5e62;
    background-color: #dadde2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #5a5e62;
    border-color: #5a5e62; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.2rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #313a46; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.7; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1367px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #343a40; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #343a40; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #343a40; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #343a40; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.8rem;
  color: #f1f3fa;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.2rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f1f3fa; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  background-color: #f1f3fa;
  border-bottom: 1px solid #dee3f3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #1e0094 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #140061 !important; }

.bg-secondary {
  background-color: #118BCC !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0d6b9d !important; }

.bg-success {
  background-color: #43A047 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #347c37 !important; }

.bg-info {
  background-color: #2059cc !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1946a0 !important; }

.bg-warning {
  background-color: #FDD835 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #fdce03 !important; }

.bg-danger {
  background-color: #E53935 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cd1e1a !important; }

.bg-light {
  background-color: #adb5bd !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #919ca6 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #1e0094 !important; }

.border-secondary {
  border-color: #118BCC !important; }

.border-success {
  border-color: #43A047 !important; }

.border-info {
  border-color: #2059cc !important; }

.border-warning {
  border-color: #FDD835 !important; }

.border-danger {
  border-color: #E53935 !important; }

.border-light {
  border-color: #adb5bd !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1367px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1367px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1367px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-15 {
  width: 15% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-15 {
  height: 15% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.375rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.375rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important; }

.m-2 {
  margin: 0.75rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.75rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important; }

.m-4 {
  margin: 2.25rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.25rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important; }

.m-5 {
  margin: 4.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 4.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 4.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.375rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.375rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.375rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.375rem !important; }

.p-2 {
  padding: 0.75rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.75rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.75rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.75rem !important; }

.p-3 {
  padding: 1.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.5rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.5rem !important; }

.p-4 {
  padding: 2.25rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.25rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.25rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.25rem !important; }

.p-5 {
  padding: 4.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 4.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 4.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 4.5rem !important; }

.m-n1 {
  margin: -0.375rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important; }

.m-n2 {
  margin: -0.75rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important; }

.m-n3 {
  margin: -1.5rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important; }

.m-n4 {
  margin: -2.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important; }

.m-n5 {
  margin: -4.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.375rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important; }
  .m-sm-2 {
    margin: 0.75rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important; }
  .m-sm-4 {
    margin: 2.25rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important; }
  .m-sm-5 {
    margin: 4.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.375rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important; }
  .p-sm-2 {
    padding: 0.75rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important; }
  .p-sm-4 {
    padding: 2.25rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important; }
  .p-sm-5 {
    padding: 4.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4.5rem !important; }
  .m-sm-n1 {
    margin: -0.375rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important; }
  .m-sm-n2 {
    margin: -0.75rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important; }
  .m-sm-n3 {
    margin: -1.5rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important; }
  .m-sm-n4 {
    margin: -2.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important; }
  .m-sm-n5 {
    margin: -4.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.375rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important; }
  .m-md-2 {
    margin: 0.75rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important; }
  .m-md-4 {
    margin: 2.25rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important; }
  .m-md-5 {
    margin: 4.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.375rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important; }
  .p-md-2 {
    padding: 0.75rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important; }
  .p-md-4 {
    padding: 2.25rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important; }
  .p-md-5 {
    padding: 4.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4.5rem !important; }
  .m-md-n1 {
    margin: -0.375rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important; }
  .m-md-n2 {
    margin: -0.75rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important; }
  .m-md-n3 {
    margin: -1.5rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important; }
  .m-md-n4 {
    margin: -2.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important; }
  .m-md-n5 {
    margin: -4.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.375rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important; }
  .m-lg-2 {
    margin: 0.75rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important; }
  .m-lg-4 {
    margin: 2.25rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important; }
  .m-lg-5 {
    margin: 4.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.375rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important; }
  .p-lg-2 {
    padding: 0.75rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important; }
  .p-lg-4 {
    padding: 2.25rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important; }
  .p-lg-5 {
    padding: 4.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4.5rem !important; }
  .m-lg-n1 {
    margin: -0.375rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important; }
  .m-lg-n2 {
    margin: -0.75rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important; }
  .m-lg-n3 {
    margin: -1.5rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important; }
  .m-lg-n4 {
    margin: -2.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important; }
  .m-lg-n5 {
    margin: -4.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1367px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.375rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important; }
  .m-xl-2 {
    margin: 0.75rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important; }
  .m-xl-4 {
    margin: 2.25rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important; }
  .m-xl-5 {
    margin: 4.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.375rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important; }
  .p-xl-2 {
    padding: 0.75rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important; }
  .p-xl-4 {
    padding: 2.25rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important; }
  .p-xl-5 {
    padding: 4.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4.5rem !important; }
  .m-xl-n1 {
    margin: -0.375rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important; }
  .m-xl-n2 {
    margin: -0.75rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important; }
  .m-xl-n3 {
    margin: -1.5rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important; }
  .m-xl-n4 {
    margin: -2.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important; }
  .m-xl-n5 {
    margin: -4.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1367px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #1e0094 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0e0048 !important; }

.text-secondary {
  color: #118BCC !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0b5b85 !important; }

.text-success {
  color: #43A047 !important; }

a.text-success:hover, a.text-success:focus {
  color: #2c6a2f !important; }

.text-info {
  color: #2059cc !important; }

a.text-info:hover, a.text-info:focus {
  color: #163c8a !important; }

.text-warning {
  color: #FDD835 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #e3ba02 !important; }

.text-danger {
  color: #E53935 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #b61b17 !important; }

.text-light {
  color: #adb5bd !important; }

a.text-light:hover, a.text-light:focus {
  color: #838f9b !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #fff !important; }

.text-muted {
  color: #98a6ad !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #eef2f7; }
  .table .thead-dark th {
    color: inherit;
    border-color: #eef2f7; } }

.footer {
  background-color: white; }
  .footer .row {
    height: 400px; }
    .footer .row div:nth-child(3) {
      background-color: #1e0094;
      border-radius: 30px 0 0 0; }
    .footer .row .our-partners__left {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      border-radius: 0 0 50px 0;
      z-index: 1; }
      .footer .row .our-partners__left:after {
        content: " ";
        background-image: url(../images/Homepage/our-solutions/pattern.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: -1;
        width: 35%;
        height: 32%;
        border-radius: 0;
        background-size: contain; }
      .footer .row .our-partners__left .our-partners__content__text {
        position: absolute;
        left: 82px;
        top: 50px; }
        .footer .row .our-partners__left .our-partners__content__text h2 {
          font-weight: 650;
          font-size: 46px;
          margin-bottom: 8px;
          line-height: 48px;
          color: #1e0094; }
      .footer .row .our-partners__left .send-email {
        position: absolute;
        top: 193px;
        left: 45px;
        width: 100%;
        height: 150px; }
        .footer .row .our-partners__left .send-email form {
          height: 100%;
          width: 90%; }
          .footer .row .our-partners__left .send-email form label {
            color: #000;
            font-size: 20px;
            font-weight: 500; }
          .footer .row .our-partners__left .send-email form input {
            height: 55px;
            padding-left: 22px;
            font-size: 18px;
            border-radius: 10px 0 0 10px;
            background-color: #ebebeb;
            outline: none;
            z-index: 0; }
          .footer .row .our-partners__left .send-email form button {
            background-color: #1e0094;
            width: 130px;
            border-radius: 10px;
            font-size: 20px;
            font-weight: 600;
            margin-left: -10px;
            z-index: 2; }
    .footer .row .as {
      color: black;
      padding: 100px 30px 40px 100px; }
      .footer .row .as a {
        display: block;
        text-align: left;
        color: black;
        text-decoration: none;
        font-size: 20px;
        margin-bottom: 10px;
        cursor: pointer; }
        .footer .row .as a.explore {
          font-weight: bold;
          color: #20a9df;
          font-size: 24px; }
    .footer .row .ps {
      color: white;
      padding: 100px 20px 40px 90px; }
      .footer .row .ps h3 {
        font-weight: 700;
        color: #20a9df;
        font-size: 23px; }
      .footer .row .ps p {
        margin-bottom: 0;
        font-size: 18px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    .footer {
      overflow: hidden; }
      .footer .our-partners__left:after {
        top: 40px !important;
        left: 40px !important;
        width: 35% !important;
        height: 32% !important; }
      .footer .our-partners__left .send-email {
        bottom: 55px !important;
        left: 45px !important; }
      .footer .as {
        padding: 50px 30px 40px 50px !important; }
        .footer .as a {
          font-size: 20px !important;
          margin-bottom: 15px !important; }
      .footer .row div:nth-child(3) {
        border-radius: 30px 0 0 0 !important; }
      .footer .ps {
        padding: 50px 20px 40px 40px !important; }
        .footer .ps h3 {
          font-size: 23px !important;
          margin-bottom: 42px !important; }
        .footer .ps p {
          font-size: 16px !important; }
      .footer .our-partners__content__text {
        left: 121px !important;
        top: 74px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .footer .ps {
      padding: 50px 10px 40px 10px !important; }
      .footer .ps p {
        font-size: 14px !important; }
    .footer .as {
      padding: 50px 10px 40px 27px !important; }
    .footer .our-partners__content__text {
      top: 87px !important; }
      .footer .our-partners__content__text h2 {
        font-size: 36px !important; } }
  @media (max-width: 767.98px) {
    .footer .footer__get_in {
      height: 400px !important; }
    .footer .our-partners__left .our-partners__content__text {
      top: 90px !important;
      left: 139px !important; }
      .footer .our-partners__left .our-partners__content__text h2 {
        font-size: 46px !important;
        margin-bottom: 8px !important; }
    .footer .our-partners__left:after {
      top: 45px !important;
      left: 10px !important;
      width: 35% !important;
      height: 32% !important; }
    .footer .our-partners__left .send-email {
      left: 50% !important;
      width: 75% !important;
      height: 150px !important;
      transform: translateX(-50%) !important; }
    .footer .row div:nth-child(3) {
      border-radius: 0; } }
  @media (max-width: 575.98px) {
    .footer .row div:nth-child(3) {
      padding: 45px !important; }
    .footer .row .as {
      padding: 30px 50px !important;
      font-size: 29px !important; }
    .footer .our-partners__left {
      padding: 25px !important; }
    .footer .row .our-partners__left .send-email form button {
      background-color: #1e0094;
      width: 120px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      margin-left: -10px;
      z-index: 2; }
    .footer .row .our-partners__left .send-email form input {
      height: 40px;
      padding-left: 22px;
      font-size: 12px;
      border-radius: 10px 0 0 10px;
      background-color: #ebebeb;
      outline: 0;
      z-index: 0; }
    .footer .our-partners__left .send-email {
      width: 100% !important;
      left: 0 !important;
      padding: 25px !important;
      transform: none !important; }
      .footer .our-partners__left .send-email form {
        width: 100% !important; }
    .footer .our-partners__left:after {
      top: 45px !important;
      left: 7% !important;
      width: 35% !important;
      height: 32% !important; }
    .footer .our-partners__left .our-partners__content__text {
      top: 90px !important;
      left: 80px !important; }
    .footer .our-partners__left .our-partners__content__text h2 {
      font-size: 40px !important; } }

/* start nav bullets */
.nav-bullets {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 1000000000; }
  .nav-bullets .bullet {
    width: 8px;
    height: 8px;
    margin: 15px auto;
    border-radius: 50%;
    cursor: pointer;
    background-color: #999;
    border: none;
    position: relative;
    transition: 0.2s; }
    .nav-bullets .bullet.active, .nav-bullets .bullet:hover {
      background-color: var(--main--color); }
      .nav-bullets .bullet.active::after, .nav-bullets .bullet:hover::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        left: 50%;
        top: -8px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        border: 1px dashed #999;
        border-radius: 50%; }

.nav-bullets .bullet::before {
  content: '';
  border-style: solid;
  border-width: 13px;
  height: 0;
  width: 0;
  position: absolute;
  top: -8px;
  border-color: transparent transparent transparent var(--main--color);
  left: -23px;
  opacity: 0;
  -webkit-transition: .3s;
  transition: .3s; }

.nav-bullets .bullet .tooltip {
  background-color: var(--main--color);
  position: absolute;
  width: 110px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  right: 31px;
  padding: 4px 4px;
  border-radius: 5px 0 0 5px;
  top: -8px;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s; }

.nav-bullets .bullet:hover .tooltip {
  opacity: 1; }

.nav-bullets .bullet:hover::before {
  opacity: 1; }

/* end nav bullets */
@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Black.eot");
  src: url("../fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Black.woff2") format("woff2"), url("../fonts/Poppins-Black.woff") format("woff"), url("../fonts/Poppins-Black.ttf") format("truetype"), url("../fonts/Poppins-Black.svg#Poppins-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-BlackItalic.eot");
  src: url("../fonts/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-BlackItalic.woff2") format("woff2"), url("../fonts/Poppins-BlackItalic.woff") format("woff"), url("../fonts/Poppins-BlackItalic.ttf") format("truetype"), url("../fonts/Poppins-BlackItalic.svg#Poppins-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Bold.eot");
  src: url("../fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/Poppins-Bold.woff") format("woff"), url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-BoldItalic.eot");
  src: url("../fonts/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-BoldItalic.woff2") format("woff2"), url("../fonts/Poppins-BoldItalic.woff") format("woff"), url("../fonts/Poppins-BoldItalic.ttf") format("truetype"), url("../fonts/Poppins-BoldItalic.svg#Poppins-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-ExtraBold.eot");
  src: url("../fonts/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-ExtraBold.woff2") format("woff2"), url("../fonts/Poppins-ExtraBold.woff") format("woff"), url("../fonts/Poppins-ExtraBold.ttf") format("truetype"), url("../fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-ExtraBoldItalic.eot");
  src: url("../fonts/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/Poppins-ExtraBoldItalic.woff") format("woff"), url("../fonts/Poppins-ExtraBoldItalic.ttf") format("truetype"), url("../fonts/Poppins-ExtraBoldItalic.svg#Poppins-ExtraBoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-ExtraLight.eot");
  src: url("../fonts/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-ExtraLight.woff2") format("woff2"), url("../fonts/Poppins-ExtraLight.woff") format("woff"), url("../fonts/Poppins-ExtraLight.ttf") format("truetype"), url("../fonts/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-ExtraLightItalic.eot");
  src: url("../fonts/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-ExtraLightItalic.woff2") format("woff2"), url("../fonts/Poppins-ExtraLightItalic.woff") format("woff"), url("../fonts/Poppins-ExtraLightItalic.ttf") format("truetype"), url("../fonts/Poppins-ExtraLightItalic.svg#Poppins-ExtraLightItalic") format("svg");
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Italic.eot");
  src: url("../fonts/Poppins-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Italic.woff2") format("woff2"), url("../fonts/Poppins-Italic.woff") format("woff"), url("../fonts/Poppins-Italic.ttf") format("truetype"), url("../fonts/Poppins-Italic.svg#Poppins-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Light.eot");
  src: url("../fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff"), url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.svg#Poppins-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-LightItalic.eot");
  src: url("../fonts/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-LightItalic.woff2") format("woff2"), url("../fonts/Poppins-LightItalic.woff") format("woff"), url("../fonts/Poppins-LightItalic.ttf") format("truetype"), url("../fonts/Poppins-LightItalic.svg#Poppins-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Medium.eot");
  src: url("../fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff"), url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-MediumItalic.eot");
  src: url("../fonts/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-MediumItalic.woff2") format("woff2"), url("../fonts/Poppins-MediumItalic.woff") format("woff"), url("../fonts/Poppins-MediumItalic.ttf") format("truetype"), url("../fonts/Poppins-MediumItalic.svg#Poppins-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Regular.eot");
  src: url("../fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.svg#Poppins-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-SemiBold.eot");
  src: url("../fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff"), url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-SemiBoldItalic.eot");
  src: url("../fonts/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-SemiBoldItalic.woff2") format("woff2"), url("../fonts/Poppins-SemiBoldItalic.woff") format("woff"), url("../fonts/Poppins-SemiBoldItalic.ttf") format("truetype"), url("../fonts/Poppins-SemiBoldItalic.svg#Poppins-SemiBoldItalic") format("svg");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-Thin.eot");
  src: url("../fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-Thin.woff2") format("woff2"), url("../fonts/Poppins-Thin.woff") format("woff"), url("../fonts/Poppins-Thin.ttf") format("truetype"), url("../fonts/Poppins-Thin.svg#Poppins-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Poppins';
  src: url("../fonts/Poppins-ThinItalic.eot");
  src: url("../fonts/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Poppins-ThinItalic.woff2") format("woff2"), url("../fonts/Poppins-ThinItalic.woff") format("woff"), url("../fonts/Poppins-ThinItalic.ttf") format("truetype"), url("../fonts/Poppins-ThinItalic.svg#Poppins-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap; }

.pointer {
  cursor: pointer; }

.nav-item:after {
  display: block;
  transform: scaleX(0);
  content: "";
  border-bottom: solid 3px red;
  transition: transform 250ms ease-in-out; }

.nav-item:hover:after {
  transform: scaleX(1); }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .25rem 0;
  margin: .125rem 0 0;
  font-size: .9rem;
  /* color: #fff; */
  text-align: left;
  list-style: none;
  background-color: #1e0094;
  background-clip: padding-box;
  border: 1px solid #e4eaf2;
  border-radius: 0rem; }

@media (max-width: 767.98px) {
  .big-sub-title {
    font-size: 1.7rem; } }

@media (min-width: 576px) and (max-width: 991.98px) {
  .big-sub-title {
    font-size: 2.5rem; } }

@media (min-width: 992px) {
  .big-sub-title {
    font-size: 4rem; } }

.carousel-3 {
  /* display 3 */ }
  @media (min-width: 992px) {
    .carousel-3 .carousel-inner .carousel-item-right.active,
    .carousel-3 .carousel-inner .carousel-item-next {
      transform: translateX(33.333%); }
    .carousel-3 .carousel-inner .carousel-item-left.active,
    .carousel-3 .carousel-inner .carousel-item-prev {
      transform: translateX(-33.333%); }
    .carousel-3 .carousel-inner .carousel-item.active,
    .carousel-3 .carousel-inner .carousel-item-next,
    .carousel-3 .carousel-inner .carousel-item-prev {
      display: flex; } }
  .carousel-3 .carousel-inner .carousel-item-right,
  .carousel-3 .carousel-inner .carousel-item-left {
    transform: translateX(0); }

/* image flicker */
.fadein-slider img {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation-name: fade;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 20s;
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 20s; }

@-webkit-keyframes fade {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  33% {
    opacity: 1; }
  53% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fade {
  0% {
    opacity: 0; }
  20% {
    opacity: 1; }
  33% {
    opacity: 1; }
  53% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.fadein-slider img:nth-of-type(2) {
  -webkit-animation-delay: -10s;
  animation-delay: -10s; }

/* end image flicker */
@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-20px); }
  100% {
    transform: translatey(0px); } }

.floating {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite; }

.hover-box {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  height: 60px;
  line-height: 60px;
  text-align: center;
  transition: 0.5s;
  padding: 0 20px;
  cursor: pointer;
  border: 2px solid transparent;
  -webkit-transition: 0.5s; }

.hover-box:hover {
  border: 2px solid rgba(0, 160, 80, 0);
  color: #1e0094; }

.hover-box::before, .hover-box::after {
  width: 100%;
  height: 100%;
  z-index: 3;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transition: 0.5s; }

.foo::before {
  border-bottom: 3px solid #1e0094;
  border-left: 3px solid #1e0094;
  -webkit-transform-origin: 0 100%; }

.foo::after {
  border-top: 3px solid #1e0094;
  border-right: 3px solid #1e0094;
  -webkit-transform-origin: 100% 0%; }

.bar::before {
  border-bottom: 3px solid #1e0094;
  border-left: 3px solid #1e0094;
  -webkit-transform-origin: 100% 0%; }

.bar::after {
  border-top: 3px solid #1e0094;
  border-right: 3px solid #1e0094;
  -webkit-transform-origin: 0% 100%; }

.curmudgeon::before {
  border-bottom: 3px solid #1e0094;
  border-left: 0;
  -webkit-transform-origin: 0% 100%; }

.curmudgeon::after {
  border-top: 0;
  border-right: 0;
  -webkit-transform-origin: 50% 50%; }

.hover-box:hover::after, .hover-box:hover::before {
  -webkit-transform: scale(1); }

.link {
  line-height: 1em;
  position: absolute;
  bottom: 0;
  right: 20px;
  width: 300px;
  height: 50px;
  font-family: "Open Sans","Roboto",Arial,sans-serif;
  text-align: right; }

.link > a {
  text-decoration: none;
  color: #FF0; }

.my-nav-overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background: #1e0094 !important;
  overflow-x: hidden;
  transition: 0.2s ease-in-out; }
  .my-nav-overlay .my-nav-overlay-content {
    height: 79%;
    position: relative;
    top: 13%;
    width: 100%;
    text-align: center;
    margin-top: 30px; }
    .my-nav-overlay .my-nav-overlay-content .container-xl {
      height: 100%; }
      .my-nav-overlay .my-nav-overlay-content .container-xl .row {
        height: 100%;
        width: 100%;
        padding: 20px;
        margin: 0;
        overflow: hidden; }
        .my-nav-overlay .my-nav-overlay-content .container-xl .row .left {
          height: 100%;
          width: 34%;
          border-right: 1px solid #666;
          text-align: left;
          padding-left: 90px;
          padding-top: 35px; }
        .my-nav-overlay .my-nav-overlay-content .container-xl .row .right {
          height: 100%;
          width: 66%;
          padding: 0 50px 50px 100px;
          flex-wrap: wrap; }
          .my-nav-overlay .my-nav-overlay-content .container-xl .row .right .item {
            height: 45%;
            padding: 20px 50px;
            text-align: left;
            color: white; }
            .my-nav-overlay .my-nav-overlay-content .container-xl .row .right .item a {
              font-size: 28px;
              font-weight: 600; }
  .my-nav-overlay .left a {
    padding: 5px;
    text-decoration: none;
    font-size: 32px;
    color: #f1f3fa;
    display: block;
    transition: 0.4s;
    width: 260px;
    border-radius: 8px;
    margin-bottom: 5px; }
    .my-nav-overlay .left a:hover {
      background-color: #20a9df; }
  .my-nav-overlay .right a {
    padding: 5px;
    text-decoration: none;
    font-size: 32px;
    color: #f1f3fa;
    display: block;
    transition: 0.4s;
    width: 260px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative; }
    .my-nav-overlay .right a:before {
      position: absolute;
      left: -20px;
      top: 15px;
      font-size: 16px;
      color: #20a9df;
      font-weight: 700; }
    .my-nav-overlay .right a:hover {
      color: #20a9df; }
  .my-nav-overlay .right .item:nth-child(1) a:before {
    content: "";
    background-image: url(../images/small-icons/ICT-Professional.png);
    width: 30px;
    height: 30px;
    display: block;
    background-size: contain;
    position: absolute;
    left: -35px;
    background-repeat: no-repeat;
    top: 12px; }
  .my-nav-overlay .right .item:nth-child(2) a:before {
    content: "";
    background-image: url(../images/small-icons/cyber-security.png);
    width: 35px;
    height: 35px;
    display: block;
    background-size: contain;
    position: absolute;
    left: -35px;
    background-repeat: no-repeat;
    top: 12px; }
  .my-nav-overlay .right .item:nth-child(3) a:before {
    content: "";
    background-image: url(../images/small-icons/Advanced.png);
    width: 32px;
    height: 32px;
    display: block;
    background-size: contain;
    position: absolute;
    left: -35px;
    background-repeat: no-repeat;
    top: 12px; }
  .my-nav-overlay .right .item:nth-child(4) a:before {
    content: "";
    background-image: url(../images/small-icons/Outsourcing-HR.png);
    width: 30px;
    height: 30px;
    display: block;
    background-size: contain;
    position: absolute;
    left: -35px;
    background-repeat: no-repeat;
    top: 12px; }
  .my-nav-overlay .right .item:nth-child(5) a:before {
    content: "";
    background-image: url(../images/small-icons/Advanced.png);
    width: 30px;
    height: 30px;
    display: block;
    background-size: contain;
    position: absolute;
    left: -35px;
    background-repeat: no-repeat;
    top: 12px; }
  .my-nav-overlay .right .item .small-anchor {
    font-size: 16px !important;
    margin-top: -10px !important;
    margin-left: 15px !important; }
    .my-nav-overlay .right .item .small-anchor:before {
      display: none !important; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    .my-nav-overlay .my-nav-overlay-content .container-xl .row .left {
      padding-left: 42px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .my-nav-overlay .left {
      padding-left: 0 !important; }
    .my-nav-overlay .right {
      padding: 71px 2px 50px 0px !important; } }
  @media (max-width: 767.98px) {
    .my-nav-overlay .left {
      padding-left: 0 !important;
      width: 50% !important; }
    .my-nav-overlay .right {
      padding: 0 2px 50px 0 !important;
      width: 50% !important; }
      .my-nav-overlay .right .item {
        height: 26% !important; } }
  @media (max-width: 575.98px) {
    .my-nav-overlay {
      padding: 0 !important; }
      .my-nav-overlay .row {
        padding: 0 !important; }
      .my-nav-overlay .left {
        padding-left: 0 !important;
        width: 45% !important; }
        .my-nav-overlay .left a {
          font-size: 20px !important;
          width: 97%; }
      .my-nav-overlay .right {
        padding: 0 2px 50px 0 !important;
        width: 55% !important;
        height: 82% !important; }
        .my-nav-overlay .right a {
          font-size: 18px !important; } }

.my-nav-overlay a:hover .left,
.my-nav-overlay a:focus .left {
  color: #f1f1f1; }

.my-nav-overlay .logo {
  position: absolute;
  top: 20px;
  left: 45px;
  font-size: 60px; }

.my-nav-overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px; }

.my-nav-overlay .menu-img {
  width: 100%;
  max-width: 100%;
  max-height: 500px;
  border: 10px transparent solid;
  transition: 0.5s ease-in-out;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .my-nav-overlay .menu-img {
      margin-left: auto; } }

.my-nav-overlay .menu-img:hover {
  border: 10px white solid;
  transition: 0.5s ease-in-out; }

.my-nav-overlay .menu-title {
  margin-top: -100px;
  font-size: 2rem;
  transition: 0.3s ease-in-out; }

@media screen and (max-height: 450px) {
  .my-nav-overlay a {
    font-size: 20px; }
  .my-nav-overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px; } }

.navbar {
  z-index: 2;
  position: absolute;
  width: 100%;
  padding: 3rem 2rem;
  transition: 0.8s ease-in; }
  .navbar .container-xl {
    z-index: 999999; }

.smallfont {
  font-size: 1.7rem !important; }

.estimate-project {
  margin-left: auto;
  margin-right: 50px;
  font-weight: bold; }
  @media (max-width: 767.98px) {
    .estimate-project {
      display: none; } }

.engagment-link {
  position: absolute;
  bottom: 20px;
  font-size: 1rem !important;
  right: 45px;
  z-index: 1000; }

.closebtn {
  position: absolute;
  top: 56px;
  right: 45px;
  width: 36px;
  font-size: 38px;
  padding: 0;
  padding: 0 10px 10px 0;
  height: 32px;
  margin: 0;
  display: none; }
  .closebtn:before {
    margin-top: -72px !important;
    top: 62px !important;
    position: absolute !important;
    top: 0;
    left: 1px;
    font-size: 34px; }

#main-nav {
  position: fixed !important;
  z-index: 5; }

.left-hr {
  margin-left: 20% !important;
  height: 42% !important;
  padding: 0 50px !important;
  text-align: left !important;
  color: #fff !important; }

@media (max-width: 767.98px) {
  .left-hr {
    margin-left: 0 !important; } }

@media (max-width: 575.98px) {
  .left-hr {
    margin-left: 0 !important; } }

:root {
  --main--color: #20a9df; }

body {
  background-color: #ebebeb !important;
  overflow-x: hidden !important; }

.container-xl {
  height: 100% !important;
  position: relative !important; }

.nav-icon {
  font-size: 20px;
  border: 1px solid #fff;
  color: white;
  border-radius: 5px;
  padding: 0 8px;
  top: 58px; }

.main-color {
  color: #1f96d5 !important; }

.get-in {
  font-size: 15px;
  font-weight: 450;
  color: white; }
  @media (max-width: 575.98px) {
    .get-in {
      display: none; } }

@media (min-width: 576px) and (max-width: 991.98px) {
  .shared-frorm {
    height: 68% !important; }
    .shared-frorm h3 {
      margin-bottom: 50px;
      font-size: 2em !important; } }

@media (max-width: 575.98px) {
  .shared-frorm {
    padding: 25px !important;
    height: 73% !important; }
    .shared-frorm .form-row:not(:last-of-type) {
      margin: 0 !important; }
    .shared-frorm .form-row {
      margin-top: 0 !important; }
    .shared-frorm h3 {
      font-size: 2em !important;
      width: 70% !important;
      margin-bottom: 30px !important;
      margin-left: 15px; }
    .shared-frorm .shared-frorm .form-control {
      width: 100% !important; }
    .shared-frorm #rc-anchor-container {
      width: 88% !important; } }

#preloader-iteam {
  background: #1e0094 url(../images/gif/final.gif) center center no-repeat;
  background-size: 5%;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 99999999999;
  overflow: hidden; }

.read-more-component {
  text-decoration: none;
  color: inherit;
  text-decoration: none;
  transition: 0.4s;
  cursor: pointer;
  width: 175px;
  display: inline-block;
  position: relative; }
  .read-more-component:hover {
    text-decoration: none;
    color: inherit; }
    .read-more-component:hover .read-more__icon__span {
      animation-name: example;
      animation-timing-function: ease-in-out;
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      opacity: 1; }
    .read-more-component:hover .read-more__divider {
      animation-name: example2;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      opacity: 1; }
    .read-more-component:hover .read-more__divider {
      animation-name: example3;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-duration: 0.5s; }

.read-more__divider {
  left: 50%;
  display: block;
  width: 39%;
  height: 1px;
  background-color: #1f96d5;
  bottom: 10px;
  position: absolute;
  opacity: 0; }

.read-more__icon__span {
  position: absolute;
  left: 75px;
  width: 40px;
  top: -10px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  font-size: 18px;
  transition: .4s; }

.read-more-component:hover .read-more__icon__span {
  opacity: 1;
  background-color: #20a9df; }

.read-more-component:hover .read-more__divider {
  opacity: 1; }

@keyframes example {
  0% {
    opacity: 0; }
  100% {
    left: 150px;
    opacity: 1; } }

@keyframes example2 {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes example3 {
  0% {
    width: 0; }
  10% {
    width: 5%; }
  30% {
    width: 10%; }
  40% {
    width: 15%; }
  50% {
    width: 20%; }
  60% {
    width: 25%; }
  70% {
    width: 35%; }
  100% {
    width: 49%; } }

.shared-frorm {
  position: absolute;
  bottom: 30px;
  right: 4%;
  width: 92%;
  height: 77%;
  background-image: url(../images/Advanced-tech/Assessment-bg.png);
  border-radius: 90px;
  padding: 75px 50px; }
  .shared-frorm h3 {
    font-size: 3em;
    font-weight: 600;
    width: 70%;
    margin-bottom: 50px; }
  .shared-frorm .form-row {
    margin-top: 10px; }
    .shared-frorm .form-row:not(:last-of-type) {
      margin-bottom: 10px; }
  .shared-frorm .form-control {
    background-color: white;
    color: black;
    border-radius: 10px;
    height: 52px;
    width: 90%; }
  .shared-frorm label {
    font-size: 1.3em;
    font-weight: 300; }
  .shared-frorm button {
    width: 150px;
    height: 50px;
    border-radius: 8px;
    margin-top: 20px;
    font-size: 16px; }
  @media (max-width: 575.98px) {
    .shared-frorm .form-control {
      width: 100% !important; } }

#vendor {
  height: 700px;
  position: relative;
  margin-bottom: 60px; }
  #vendor h3 {
    position: absolute;
    left: 250px;
    top: 160px;
    font-size: 50px;
    font-weight: 600; }
    #vendor h3:before {
      content: " ";
      background-image: url(../images/about-us/pattern.png);
      position: absolute;
      top: -50px;
      left: -63px;
      z-index: -1;
      width: 150px;
      height: 150px;
      background-repeat: no-repeat;
      background-size: cover; }
  #vendor > .container-xl:before {
    content: " ";
    background-image: url(../images/ICT-Professional-Solutions/vendor-bg.png);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 92%;
    height: 591px;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (max-width: 575.98px) {
      #vendor > .container-xl:before {
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px; } }
  #vendor .sqrs {
    width: 80%;
    position: absolute;
    bottom: 30px;
    left: 10%;
    display: flex;
    justify-content: space-evenly; }
    #vendor .sqrs .sqr {
      background-color: white;
      display: inline-block;
      width: 225px;
      height: 275px;
      border-radius: 20px;
      border: 1px solid #2196f3;
      overflow: hidden;
      background-size: 75%; }
      #vendor .sqrs .sqr:nth-child(1) {
        background-image: url(../images/Homepage/our-partners/a1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);
        background-size: 75%; }
        #vendor .sqrs .sqr:nth-child(1):hover {
          filter: none !important; }
      #vendor .sqrs .sqr:nth-child(2) {
        background-image: url(../images/Homepage/our-partners/b1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);
        background-size: 75%; }
        #vendor .sqrs .sqr:nth-child(2):hover {
          filter: none !important; }
      #vendor .sqrs .sqr:nth-child(3) {
        background-image: url(../images/Homepage/our-partners/c1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9);
        background-size: 75%; }
        #vendor .sqrs .sqr:nth-child(3):hover {
          filter: none !important; }
      #vendor .sqrs .sqr:nth-child(4) {
        background-image: url(../images/Homepage/our-partners/d1.png);
        background-repeat: no-repeat;
        overflow: hidden;
        background-position: center center;
        background-size: 75%;
        filter: grayscale(0.9); }
        #vendor .sqrs .sqr:nth-child(4):hover {
          filter: none !important; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #vendor:before {
      width: 92% !important;
      height: 431px !important;
      background-size: cover !important; }
    #vendor .sqrs {
      top: 400px !important;
      width: 86%;
      bottom: 30px;
      left: 6%; }
      #vendor .sqrs .sqr {
        width: 225px !important;
        height: 265px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #vendor h3 {
      left: 100px;
      top: 80px;
      font-size: 2.5em; }
    #vendor:before {
      width: 99% !important;
      height: 359px !important;
      background-size: cover !important; }
    #vendor .sqrs {
      top: 310px !important; }
      #vendor .sqrs .sqr {
        width: 150px !important;
        height: 220px !important; } }
  @media (max-width: 767.98px) {
    #vendor h3 {
      left: 100px;
      top: 110px; }
    #vendor .sqrs {
      top: 285px !important;
      width: 96%;
      left: 2%; }
      #vendor .sqrs .sqr {
        width: 130px !important;
        height: 190px !important; } }
  @media (max-width: 575.98px) {
    #vendor h3 {
      left: 100px;
      top: 86px;
      font-size: 35px !important; }
    #vendor:before {
      width: 99% !important;
      height: 359px !important;
      background-size: cover !important; }
    #vendor .sqrs {
      top: 203px !important;
      width: 88% !important;
      left: 2%;
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
      bottom: unset !important; }
      #vendor .sqrs .sqr {
        width: 130px !important;
        height: 190px !important;
        flex: 50% !important; } }

#cyper-framework {
  overflow: hidden !important; }
  #cyper-framework .circles-wrapper {
    width: 750px !important;
    height: 750px !important;
    position: relative !important;
    animation: right;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    margin: auto !important;
    left: -35%; }
    @media (max-width: 575.98px) {
      #cyper-framework .circles-wrapper {
        left: 0 !important; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      #cyper-framework .circles-wrapper {
        left: 0 !important; } }
  #cyper-framework .left {
    position: relative;
    color: black;
    right: 0;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-direction: reverse;
    opacity: 0;
    padding-top: 70px; }
    #cyper-framework .left h2 {
      position: relative;
      margin-top: 146px;
      margin-left: 193px;
      color: #1e0094;
      font-weight: 620;
      font-size: 60px; }
      #cyper-framework .left h2:before {
        content: " ";
        background-image: url(../images/Cyber-security/pattern.png);
        position: absolute;
        top: -54px;
        left: -80px;
        z-index: -1;
        width: 180px;
        height: 180px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #cyper-framework .left .cyper-framework__p {
      margin-left: 110px;
      line-height: 1.3;
      font-weight: 500 !important;
      font-size: 14px !important; }
    #cyper-framework .left h3 {
      color: #2196f3;
      margin-left: 110px;
      margin-top: 65px;
      font-weight: 500;
      font-size: 28px;
      margin-bottom: 10px; }
    #cyper-framework .left h5 {
      margin-left: 110px;
      font-weight: bold;
      margin-bottom: 6px;
      font-size: 14px !important;
      font-weight: bold !important; }
    #cyper-framework .left ul {
      font-weight: 500;
      margin-left: 110px;
      padding-left: 20px;
      font-size: 16px;
      font-weight: 500; }
      #cyper-framework .left ul li {
        line-height: 1.3;
        font-weight: 500 !important;
        font-size: 15px !important; }
  #cyper-framework .right .circle {
    z-index: 0;
    position: relative;
    border: 1px solid black;
    padding: 0;
    margin: 1em auto;
    width: 750px;
    height: 750px;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
    padding: 0;
    margin: 0; }
    #cyper-framework .right .circle li {
      overflow: hidden;
      position: absolute;
      top: -20%;
      right: -20%;
      width: 70%;
      height: 70%;
      transform-origin: 0% 100%;
      border: 1px solid white; }
    #cyper-framework .right .circle .text,
    #cyper-framework .right .circle .text1,
    #cyper-framework .right .circle .text2 {
      position: absolute;
      width: 100%;
      height: 100%; }
      #cyper-framework .right .circle .text .spn,
      #cyper-framework .right .circle .text1 .spn,
      #cyper-framework .right .circle .text2 .spn {
        width: 15px !important;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 1.2em !important;
        -webkit-transform-origin: 0 215px;
        transform-origin: 0 325px;
        font-size: 20px !important;
        font-weight: 600;
        text-align: center;
        height: 49% !important;
        transform-origin: 0 0;
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: center; }
        #cyper-framework .right .circle .text .spn span,
        #cyper-framework .right .circle .text1 .spn span,
        #cyper-framework .right .circle .text2 .spn span {
          transform-origin: 50% 50%;
          transform: rotate(180deg); }
        @media (max-width: 575.98px) {
          #cyper-framework .right .circle .text .spn,
          #cyper-framework .right .circle .text1 .spn,
          #cyper-framework .right .circle .text2 .spn {
            font-size: 12px !important; } }
    #cyper-framework .right .circle li:first-child {
      transform: rotate(0deg) skewY(30deg);
      background: linear-gradient(to right bottom, #882fbd, #9333b9); }
      #cyper-framework .right .circle li:first-child:hover, #cyper-framework .right .circle li:first-child.active {
        background-color: transparent;
        background: linear-gradient(to right bottom, #a034e1, #0e030d) !important; }
    #cyper-framework .right .circle li:nth-child(2) {
      transform: rotate(120deg) skewY(30deg);
      background: linear-gradient(to right bottom, #882fbd, #e073d1); }
      #cyper-framework .right .circle li:nth-child(2):hover, #cyper-framework .right .circle li:nth-child(2).active {
        background-color: transparent;
        background: linear-gradient(to right bottom, #a034e1, #0e030d) !important; }
    #cyper-framework .right .circle li:nth-child(3) {
      transform: rotate(240deg) skewY(30deg);
      background: linear-gradient(to right bottom, #e073d1, #882fbd); }
      #cyper-framework .right .circle li:nth-child(3):hover, #cyper-framework .right .circle li:nth-child(3).active {
        background-color: transparent;
        background: linear-gradient(to right bottom, #a034e1, #0e030d) !important; }
    #cyper-framework .right .circle li:nth-child(4) {
      transform: rotate(0deg) skewY(30deg);
      background: transparent;
      z-index: 1; }
    #cyper-framework .right .circle li:nth-child(5) {
      transform: rotate(120deg) skewY(30deg);
      background: transparent;
      z-index: 1; }
    #cyper-framework .right .circle li:nth-child(6) {
      transform: rotate(240deg) skewY(30deg);
      background: transparent;
      z-index: 1; }
  #cyper-framework .right .circle2 {
    z-index: 2;
    position: absolute;
    border: 1px solid #000;
    padding: 0;
    margin: 1em auto;
    width: 650px;
    height: 650px;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border: none;
    padding: 0;
    margin: 0; }
    #cyper-framework .right .circle2 li {
      overflow: hidden;
      position: absolute;
      top: -20%;
      right: -20%;
      width: 70%;
      height: 70%;
      transform-origin: 0% 100%;
      border: 1px solid white; }
    #cyper-framework .right .circle2 .text,
    #cyper-framework .right .circle2 .text1,
    #cyper-framework .right .circle2 .text2 {
      position: absolute;
      width: 100%;
      height: 100%; }
      #cyper-framework .right .circle2 .text .spn,
      #cyper-framework .right .circle2 .text1 .spn,
      #cyper-framework .right .circle2 .text2 .spn {
        width: 12px !important;
        margin: 0;
        padding: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 17px !important;
        -webkit-transform-origin: 0 215px;
        transform-origin: 0 325px;
        font-size: 20px !important;
        font-weight: 600;
        text-align: center;
        height: 49% !important;
        transform-origin: 0 0;
        text-align: right;
        display: flex;
        align-items: flex-end;
        justify-content: center; }
        #cyper-framework .right .circle2 .text .spn span,
        #cyper-framework .right .circle2 .text1 .spn span,
        #cyper-framework .right .circle2 .text2 .spn span {
          transform-origin: 50% 50%;
          transform: rotate(180deg); }
        @media (max-width: 575.98px) {
          #cyper-framework .right .circle2 .text .spn,
          #cyper-framework .right .circle2 .text1 .spn,
          #cyper-framework .right .circle2 .text2 .spn {
            font-size: 12px !important; } }
    #cyper-framework .right .circle2 .text .spn span {
      transform-origin: 50% 50%;
      transform: rotate(0) !important; }
    #cyper-framework .right .circle2 li:first-child {
      transform: rotate(0deg) skewY(30deg);
      background: #00adef; }
      #cyper-framework .right .circle2 li:first-child.active {
        background-color: transparent;
        background: #6bd2f9 !important; }
    #cyper-framework .right .circle2 li:nth-child(2) {
      transform: rotate(120deg) skewY(30deg);
      background: #00adef; }
      #cyper-framework .right .circle2 li:nth-child(2):hover, #cyper-framework .right .circle2 li:nth-child(2).active {
        background-color: transparent;
        background: #6bd2f9 !important; }
    #cyper-framework .right .circle2 li:nth-child(3) {
      transform: rotate(240deg) skewY(30deg);
      background: #00adef; }
      #cyper-framework .right .circle2 li:nth-child(3):hover, #cyper-framework .right .circle2 li:nth-child(3).active {
        background-color: transparent;
        background: #6bd2f9 !important; }
    #cyper-framework .right .circle2 li:nth-child(4) {
      transform: rotate(0deg) skewY(30deg);
      background: transparent;
      z-index: 2; }
    #cyper-framework .right .circle2 li:nth-child(5) {
      transform: rotate(120deg) skewY(30deg);
      background: transparent;
      z-index: 2; }
    #cyper-framework .right .circle2 li:nth-child(6) {
      transform: rotate(240deg) skewY(30deg);
      background: transparent;
      z-index: 2; }
  #cyper-framework .right .circle3 {
    z-index: 3;
    position: absolute;
    border: 1px solid #000;
    padding: 0;
    margin: 1em auto;
    width: 550px;
    height: 550px;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(58deg);
    border: none;
    padding: 0;
    margin: 0; }
    #cyper-framework .right .circle3 li {
      overflow: hidden;
      position: absolute;
      top: -20%;
      right: -20%;
      width: 70%;
      height: 70%;
      transform-origin: 0% 100%;
      border: 1px solid #00adef;
      cursor: pointer; }
      #cyper-framework .right .circle3 li:hover {
        background: linear-gradient(to left blue, white, blue) !important; }
      #cyper-framework .right .circle3 li span {
        display: inline-block;
        width: 43%;
        height: 19%;
        /* background: red; */
        position: absolute;
        left: 18%;
        top: 75%;
        transform: translate(-50%, -50%) skewY(224deg) rotate(-68deg);
        font-size: 18px; }
    #cyper-framework .right .circle3 li:first-child {
      transform: rotate(0deg) skewY(135deg);
      background: #1e0094; }
    #cyper-framework .right .circle3 li:nth-child(2) {
      transform: rotate(45deg) skewY(135deg);
      background: #1e0094; }
    #cyper-framework .right .circle3 li:nth-child(3) {
      transform: rotate(90deg) skewY(135deg);
      background: #1e0094; }
      #cyper-framework .right .circle3 li:nth-child(3) span {
        font-size: 15px; }
    #cyper-framework .right .circle3 li:nth-child(4) {
      transform: rotate(135deg) skewY(135deg);
      background: #1e0094; }
      #cyper-framework .right .circle3 li:nth-child(4) span {
        left: 15%;
        transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
        font-size: 16px; }
    #cyper-framework .right .circle3 li:nth-child(5) {
      transform: rotate(180deg) skewY(135deg);
      background: #1e0094; }
      #cyper-framework .right .circle3 li:nth-child(5) span {
        left: 15%;
        transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
        font-size: 16px; }
    #cyper-framework .right .circle3 li:nth-child(6) {
      transform: rotate(225deg) skewY(135deg);
      background: #1e0094; }
      #cyper-framework .right .circle3 li:nth-child(6) span {
        left: 15%;
        transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg); }
    #cyper-framework .right .circle3 li:nth-child(7) {
      transform: rotate(270deg) skewY(135deg);
      background: #1e0094; }
      #cyper-framework .right .circle3 li:nth-child(7) span {
        left: 25%;
        -webkit-transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
        transform: translate(-50%, -50%) skewY(224deg) rotate(-249deg);
        top: 87%; }
    #cyper-framework .right .circle3 li:nth-child(8) {
      transform: rotate(315deg) skewY(135deg);
      background: #1e0094; }
    #cyper-framework .right .circle3 li.active {
      background-color: transparent;
      background: #3d1bc3 !important; }
    @media (min-width: 768px) and (max-width: 1366.98px) {
      #cyper-framework .right .circle3 li:first-child span {
        left: 22% !important; }
      #cyper-framework .right .circle3 li:nth-child(2) span {
        left: 18% !important;
        top: 71%; }
      #cyper-framework .right .circle3 li:nth-child(3) span {
        left: 21% !important;
        top: 78%; }
      #cyper-framework .right .circle3 li:nth-child(4) span {
        left: 16% !important; }
      #cyper-framework .right .circle3 li:nth-child(5) {
        transform: rotate(180deg) skewY(135deg);
        background: #1e0094; }
        #cyper-framework .right .circle3 li:nth-child(5) span {
          left: 20% !important;
          top: 85%; }
      #cyper-framework .right .circle3 li:nth-child(6) span {
        left: 16% !important; }
      #cyper-framework .right .circle3 li:nth-child(7) span {
        top: 83%;
        left: 23% !important;
        font-size: 13px !important; }
      #cyper-framework .right .circle3 li:nth-child(8) span {
        left: 23% !important;
        top: 74%; } }
  #cyper-framework .right .circle4 {
    z-index: 4;
    position: absolute;
    padding: 0;
    margin: 1em auto;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
    top: 49%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    border: none;
    background-color: #00adef;
    outline-style: dashed;
    outline-color: #00adef;
    outline-width: 2px;
    outline-offset: 8px;
    -webkit-outline-style: dashed;
    -webkit-outline-color: #00adef;
    -webkit-outline-width: 2px;
    -webkit-outline-offset: 8px;
    padding: 0;
    margin: 0; }
    #cyper-framework .right .circle4 h3 {
      position: absolute;
      left: 16%;
      top: 23%;
      -webkit-transform: rotate(-44deg);
      transform: rotate(-44deg);
      font-weight: 600;
      font-size: 26px; }
    @media (max-width: 575.98px) {
      #cyper-framework .right .circle4 {
        outline-offset: 4px !important; } }
  @media (min-width: 992px) and (max-width: 1366.98px) {
    #cyper-framework .left h2 {
      margin-top: 110px;
      margin-left: 140px;
      font-size: 50px !important; }
    #cyper-framework .circles-wrapper {
      margin-top: 60px !important;
      width: 620px !important;
      height: 620px !important; }
      #cyper-framework .circles-wrapper .circle {
        width: 620px !important;
        height: 620px !important; }
      #cyper-framework .circles-wrapper .circle2 {
        width: 530px !important;
        height: 530px !important; }
      #cyper-framework .circles-wrapper .circle3 {
        width: 450px !important;
        height: 450px !important; }
      #cyper-framework .circles-wrapper .circle4 {
        width: 130px !important;
        height: 130px !important; }
    #cyper-framework .right .circle3 li span {
      font-size: 14px !important; }
    #cyper-framework .left h2 {
      margin-top: 50px !important;
      font-size: 47px !important;
      width: 82% !important;
      margin-left: 170px !important;
      margin-bottom: 60px !important; }
      #cyper-framework .left h2:before {
        top: -52px !important;
        left: -68px !important;
        width: 150px !important;
        height: 190px !important; }
    #cyper-framework .left h3 {
      margin-left: 105px !important;
      margin-top: 36px !important;
      font-size: 26px !important;
      margin-bottom: 10px !important;
      font-weight: 600; }
    #cyper-framework .left .cyper-framework__p {
      margin-left: 105px !important;
      line-height: 1.3;
      font-weight: 500 !important;
      font-size: 16px !important; }
    #cyper-framework .left h5 {
      margin-left: 105px !important;
      font-weight: 700 !important;
      margin-bottom: 6px !important;
      font-size: 17px !important;
      font-weight: 600 !important; }
    #cyper-framework .left ul {
      font-weight: 500 !important;
      margin-left: 105px !important;
      padding-left: 20px !important;
      font-size: 12px !important;
      font-weight: 500 !important; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    #cyper-framework .left h2 {
      margin-top: 110px;
      margin-left: 140px;
      font-size: 50px !important; }
    #cyper-framework .circles-wrapper {
      width: 650px !important;
      height: 650px !important; }
      #cyper-framework .circles-wrapper .circle {
        width: 650px !important;
        height: 650px !important; }
      #cyper-framework .circles-wrapper .circle2 {
        width: 550px !important;
        height: 550px !important; }
      #cyper-framework .circles-wrapper .circle3 {
        width: 450px !important;
        height: 450px !important; }
    #cyper-framework .right .circle3 li span {
      font-size: 14px !important; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    #cyper-framework .left h2 {
      margin-top: 110px;
      margin-left: 140px;
      font-size: 50px !important; }
    #cyper-framework .circles-wrapper {
      margin-top: 0 !important;
      width: 550px !important;
      height: 550px !important; }
      #cyper-framework .circles-wrapper .circle {
        width: 550px !important;
        height: 550px !important; }
      #cyper-framework .circles-wrapper .circle2 {
        width: 480px !important;
        height: 480px !important; }
      #cyper-framework .circles-wrapper .circle3 {
        width: 420px !important;
        height: 420px !important; }
      #cyper-framework .circles-wrapper .circle4 {
        width: 130px !important;
        height: 130px !important; }
    #cyper-framework .right .circle3 li span {
      font-size: 14px !important; }
    #cyper-framework .left h2 {
      margin-top: 110px !important;
      font-size: 33px !important;
      width: 100% !important;
      margin-left: 80px !important; }
      #cyper-framework .left h2:before {
        top: -67px !important;
        left: -52px !important;
        width: 141px !important;
        height: 180px !important; }
    #cyper-framework .left h3 {
      margin-left: 25px !important;
      margin-top: 36px !important;
      font-size: 18px !important;
      margin-bottom: 10px !important; }
    #cyper-framework .left .cyper-framework__p {
      margin-left: 25px !important;
      font-weight: 500 !important;
      font-size: 13px !important; }
    #cyper-framework .left h5 {
      margin-left: 25px !important;
      font-weight: 700 !important;
      margin-bottom: 6px !important;
      font-size: 15px !important;
      font-weight: 600 !important; }
    #cyper-framework .left ul {
      font-weight: 500 !important;
      margin-left: 25px !important;
      padding-left: 20px !important;
      font-size: 12px !important;
      font-weight: 500 !important; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    #cyper-framework .left h2 {
      margin-top: 110px;
      margin-left: 140px;
      font-size: 50px !important; }
    #cyper-framework .circles-wrapper {
      margin-top: 0 !important;
      width: 550px !important;
      height: 550px !important; }
      #cyper-framework .circles-wrapper .circle {
        width: 550px !important;
        height: 550px !important; }
      #cyper-framework .circles-wrapper .circle2 {
        width: 480px !important;
        height: 480px !important; }
      #cyper-framework .circles-wrapper .circle3 {
        width: 420px !important;
        height: 420px !important; }
      #cyper-framework .circles-wrapper .circle4 {
        width: 130px !important;
        height: 130px !important; }
    #cyper-framework .right .circle3 li span {
      font-size: 14px !important; }
    #cyper-framework .right .circle3 li:nth-child(5) span {
      font-size: 12px !important; }
    #cyper-framework .right .circle3 li:nth-child(4) span {
      font-size: 12px !important; }
    #cyper-framework .right .circle3 li:nth-child(3) span {
      font-size: 11px !important; }
    #cyper-framework .right .circle3 li:nth-child(2) span {
      font-size: 12px !important; }
    #cyper-framework .left h2 {
      margin-top: 110px !important;
      font-size: 33px !important;
      width: 100% !important;
      margin-left: 80px !important; }
      #cyper-framework .left h2:before {
        top: -67px !important;
        left: -52px !important;
        width: 141px !important;
        height: 180px !important; }
    #cyper-framework .left h3 {
      margin-left: 25px !important;
      margin-top: 36px !important;
      font-size: 18px !important;
      margin-bottom: 10px !important; }
    #cyper-framework .left .cyper-framework__p {
      margin-left: 25px !important;
      font-weight: 500 !important;
      font-size: 13px !important; }
    #cyper-framework .left h5 {
      margin-left: 25px !important;
      font-weight: 700 !important;
      margin-bottom: 6px !important;
      font-size: 15px !important;
      font-weight: 600 !important; }
    #cyper-framework .left ul {
      font-weight: 500 !important;
      margin-left: 25px !important;
      padding-left: 20px !important;
      font-size: 12px !important;
      font-weight: 500 !important; } }
  @media (max-width: 575.98px) {
    #cyper-framework .row {
      flex-direction: column-reverse !important; }
    #cyper-framework .left h2 {
      margin-top: 110px;
      margin-left: 140px;
      font-size: 50px !important; }
    #cyper-framework .circles-wrapper {
      margin-top: 0 !important;
      width: 340px !important;
      height: 340px !important;
      left: 0 !important; }
      #cyper-framework .circles-wrapper .circle {
        width: 340px !important;
        height: 340px !important; }
        #cyper-framework .circles-wrapper .circle .spn {
          font-size: 12px !important; }
      #cyper-framework .circles-wrapper .circle2 {
        width: 290px !important;
        height: 290px !important; }
      #cyper-framework .circles-wrapper .circle3 {
        width: 245px !important;
        height: 245px !important; }
        #cyper-framework .circles-wrapper .circle3 li span {
          font-size: 11px !important; } }
      @media (max-width: 575.98px) and (max-width: 575.98px) {
        #cyper-framework .circles-wrapper .circle3 li span {
          font-size: 8px !important; } }
  @media (max-width: 575.98px) {
        #cyper-framework .circles-wrapper .circle3 li:first-child span {
          left: 22% !important; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(2) span {
          left: 22% !important; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(3) span {
          left: 21% !important;
          top: 78%; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(4) span {
          left: 23% !important;
          top: 85%; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(5) {
          transform: rotate(180deg) skewY(135deg);
          background: #1e0094; }
          #cyper-framework .circles-wrapper .circle3 li:nth-child(5) span {
            left: 20% !important;
            top: 84%; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(6) span {
          left: 18% !important; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(7) span {
          left: 25% !important;
          top: 84%; }
        #cyper-framework .circles-wrapper .circle3 li:nth-child(8) span {
          left: 22% !important; }
      #cyper-framework .circles-wrapper .circle4 {
        width: 80px !important;
        height: 80px !important; }
        #cyper-framework .circles-wrapper .circle4 h3 {
          font-size: 22px; } }
      @media (max-width: 575.98px) and (max-width: 575.98px) {
        #cyper-framework .circles-wrapper .circle4 h3 {
          font-size: 15px !important; } }
  @media (max-width: 575.98px) {
    #cyper-framework .left h2 {
      margin-top: 110px !important;
      font-size: 33px !important;
      width: 98% !important;
      margin-left: 63px !important;
      margin-bottom: 40px; }
      #cyper-framework .left h2:before {
        top: -67px !important;
        left: -52px !important;
        width: 141px !important;
        height: 180px !important; }
    #cyper-framework .left h3 {
      margin-left: 25px !important;
      margin-top: 36px !important;
      font-size: 18px !important;
      margin-bottom: 10px !important; }
    #cyper-framework .left .cyper-framework__p {
      margin-left: 25px !important;
      font-weight: 500 !important;
      font-size: 13px !important; }
    #cyper-framework .left h5 {
      margin-left: 25px !important;
      font-weight: 700 !important;
      margin-bottom: 6px !important;
      font-size: 15px !important;
      font-weight: 600 !important; }
    #cyper-framework .left ul {
      font-weight: 500 !important;
      margin-left: 25px !important;
      padding-left: 20px !important;
      font-size: 12px !important;
      font-weight: 500 !important; } }

#training-tech-main {
  position: relative;
  height: 40rem; }
  #training-tech-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #training-tech-main #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/training/headerR.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #training-tech-main #left-square .row p {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px; }
    #training-tech-main #left-square .left-content {
      margin-left: 30px; }
    #training-tech-main #left-square h2 {
      font-size: 5em;
      margin-left: 74px;
      font-weight: 650;
      line-height: 1;
      margin-top: 250px;
      position: relative; }
      #training-tech-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -40px;
        left: -58px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #training-tech-main #left-square a {
      border-radius: 5px; }
    #training-tech-main #left-square:after {
      content: " ";
      background-image: url(../images/training/icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(50%) translateY(-50%); }
  #training-tech-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #training-tech-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #training-tech-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #training-tech-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #training-tech-main .mid {
      right: 64% !important; }
    #training-tech-main #right-square1:before {
      right: 28% !important; }
    #training-tech-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #training-tech-main {
      height: 37rem; }
      #training-tech-main #left-square {
        width: 86%; }
        #training-tech-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #training-tech-main #right-square1:before {
        right: -32% !important; }
      #training-tech-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #training-tech-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #training-tech-main #left-square {
      width: 86%; }
      #training-tech-main #left-square .left-content {
        align-items: flex-start !important; }
      #training-tech-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #training-tech-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #training-tech-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #training-tech-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #training-tech-main {
      height: 34rem; }
      #training-tech-main .container-xl {
        padding: 0; }
      #training-tech-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #training-tech-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #training-tech-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: 280px !important;
          margin-left: 0 !important; }
        #training-tech-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important; }
        #training-tech-main #left-square div {
          padding-left: 25px !important; }
        #training-tech-main #left-square .row p {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.4;
          margin-left: 5px !important; }
      #training-tech-main #left-square:after {
        top: 41% !important;
        right: 25% !important;
        width: 190px;
        height: 155px; }
      #training-tech-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #training-tech-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #training-tech-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #training-tech-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

#training-cloud {
  width: 100%;
  padding: 100px 75px 100px 75px; }
  #training-cloud .row {
    padding-left: 50px;
    padding-right: 50px; }
  #training-cloud h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 23px; }
    #training-cloud h3:not(:first-of-type) {
      margin-top: 50px;
      margin-top: 0.2em !important;
      margin-top: 0.2em !important; }
  #training-cloud p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 14px !important;
    line-height: 1.2; }
  #training-cloud ul {
    padding: 0;
    margin: 0; }
    #training-cloud ul li {
      padding: 0;
      margin: 0;
      margin-left: 20px; }
      #training-cloud ul li::marker {
        color: black; }
  #training-cloud #right-square {
    text-align: center; }
    #training-cloud #right-square img {
      width: 80%; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #training-cloud {
      padding-top: 50px !important;
      padding: 51px 25px 50px 25px !important; }
      #training-cloud #right-square img {
        width: 100%;
        height: 90%;
        border-radius: 75px; }
      #training-cloud p {
        font-size: 15px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #training-cloud {
      padding: 50px 25px 50px 25px; }
      #training-cloud #left-square {
        padding-top: 0px !important; }
      #training-cloud p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
        padding: 0;
        margin: 0;
        width: 100%; }
      #training-cloud h3:not(:first-of-type) {
        margin-top: 30px; }
      #training-cloud #right-square img {
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    #training-cloud {
      padding: 50px 25px 50px 25px !important; }
      #training-cloud #left-square {
        padding-top: 0; }
      #training-cloud #right-square img {
        display: none; }
      #training-cloud p {
        font-size: 15px; } }
  @media (max-width: 575.98px) {
    #training-cloud {
      padding-left: 0 !important;
      padding-right: 0 !important; } }

#training-backap {
  width: 100%;
  padding: 0 75px 100px 75px; }
  #training-backap h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 23px; }
    #training-backap h3:not(:first-of-type) {
      margin-top: 50px;
      margin-top: 0.2em !important;
      margin-top: 0.2em !important; }
  #training-backap p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 16px !important;
    line-height: 1.4; }
  #training-backap ul {
    padding: 0;
    margin: 0; }
    #training-backap ul li {
      padding: 0;
      margin: 0;
      margin-left: 20px; }
      #training-backap ul li::marker {
        color: black; }
  #training-backap #left-square {
    padding-top: 60px;
    padding-left: 50px;
    padding-right: 50px; }
  #training-backap #right-square {
    text-align: right !important; }
    #training-backap #right-square img {
      width: 92%; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #training-backap {
      padding: 51px 25px 50px 25px !important; }
      #training-backap #right-square img {
        width: 90%;
        height: 76%;
        border-radius: 75px; }
      #training-backap p {
        font-size: 15px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #training-backap {
      padding: 50px 25px 50px 25px; }
      #training-backap #left-square {
        padding-top: 0px !important; }
      #training-backap p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
        padding: 0;
        margin: 0;
        width: 100%; }
      #training-backap h3:not(:first-of-type) {
        margin-top: 30px; }
      #training-backap #right-square img {
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    #training-backap {
      padding: 50px 25px 50px 25px !important; }
      #training-backap #left-square {
        padding-top: 0; }
      #training-backap #right-square img {
        display: none; }
      #training-backap p {
        font-size: 15px; } }
  @media (max-width: 575.98px) {
    #training-backap {
      padding-left: 0 !important;
      padding-right: 0 !important; }
      #training-backap .row {
        padding-left: 0;
        padding-right: 0; } }

#training-service {
  height: 650px;
  position: relative; }
  #training-service .shared-frorm {
    height: 600px !important; }
  #training-service .main-2 {
    color: #1e0094 !important;
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    font-size: 25px; }
  #training-service .h3rap {
    position: absolute;
    top: 65px;
    left: 100px; }
    #training-service .h3rap h3 {
      font-size: 3em;
      font-weight: 600;
      width: 70%; }
  #training-service .managed-ul {
    position: absolute;
    left: 90px;
    top: 233px;
    line-height: 1.8;
    font-size: 21px; }
  @media (max-width: 575.98px) {
    #training-service .h3rap h3 {
      font-size: 2.5em;
      font-weight: 600;
      width: 70%; } }

#workforce-training {
  position: relative;
  padding: 30px 15px;
  font-size: 16px;
  line-height: 1.4;
  color: #3a2525;
  font-weight: 500;
  text-align: center; }
  #workforce-training h2 {
    font-size: 33px !important;
    color: #1f96d5 !important;
    font-weight: 600; }
  #workforce-training p {
    width: 80%;
    margin: auto; }
  @media (max-width: 575.98px) {
    #workforce-training {
      padding: 0;
      margin-top: 50px; }
      #workforce-training p {
        font-size: 13px; } }

#training-vendor {
  height: 500px;
  position: relative; }
  #training-vendor h3 {
    position: absolute;
    left: 9%;
    top: 10%;
    font-size: 2.5rem;
    font-weight: 600;
    width: 100%;
    line-height: 1.2; }
  #training-vendor img {
    position: absolute;
    right: 0;
    height: 100%;
    width: 95%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px; }
  #training-vendor ul {
    position: absolute;
    color: #fff;
    left: 8%;
    top: 30%;
    line-height: 1.4;
    font-size: 21px; }
  @media (max-width: 575.98px) {
    #training-vendor img {
      position: absolute;
      right: 0;
      height: 100%;
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    #training-vendor ul {
      position: absolute;
      color: #fff;
      left: -2%;
      top: 37%;
      line-height: 1.4;
      font-size: 16px; }
    #training-vendor h3 {
      position: absolute;
      left: 9%;
      top: 10%;
      font-size: 2rem;
      font-weight: 600;
      width: 69%;
      line-height: 1.2; } }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 42rem; }
  .mid {
    width: 377px !important;
    height: 88% !important; }
  #training-backap #right-square img {
    width: 92% !important;
    height: 63% !important;
    border-radius: 75px !important; }
  #training-cloud #right-square img {
    width: 88% !important;
    height: 100% !important;
    border-radius: 75px !important; }
  #training-backap #left-square {
    padding-top: 10px !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #industries-main {
    height: 48rem !important; }
    #industries-main #right-square1 {
      width: 600px; }
      #industries-main #right-square1:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px; }
      #industries-main #right-square1:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px; }
      #industries-main #right-square1 .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat; }
  #training-backap #right-square img {
    width: 92% !important;
    height: 63% !important;
    border-radius: 75px !important; }
  #training-cloud #right-square img {
    width: 80% !important;
    height: 83% !important;
    border-radius: 75px !important; } }

.main-2 {
  color: #1e0094 !important;
  margin-bottom: 0.4em !important;
  padding-bottom: 0;
  font-weight: 650;
  font-size: 29px;
  margin-left: -20px; }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #training-backap #right-square img {
    width: 92% !important;
    height: 63% !important;
    border-radius: 75px !important; }
  #training-cloud #right-square img {
    width: 80% !important;
    height: 83% !important;
    border-radius: 75px !important; } }

#hr-main {
  position: relative;
  height: 40rem; }
  #hr-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #hr-main #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/hr/header.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #hr-main #left-square .row p {
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px; }
    #hr-main #left-square .left-content {
      margin-left: 30px; }
    #hr-main #left-square h2 {
      font-size: 5em;
      margin-left: 74px;
      font-weight: 650;
      line-height: 1;
      margin-top: 250px;
      position: relative; }
      #hr-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -40px;
        left: -58px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #hr-main #left-square a {
      border-radius: 5px; }
    #hr-main #left-square:after {
      content: " ";
      background-image: url(../images/hr/icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(50%) translateY(-50%); }
  #hr-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #hr-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #hr-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #hr-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #hr-main .mid {
      right: 64% !important; }
    #hr-main #right-square1:before {
      right: 28% !important; }
    #hr-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #hr-main {
      height: 37rem; }
      #hr-main #left-square {
        width: 86%; }
        #hr-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #hr-main #right-square1:before {
        right: -32% !important; }
      #hr-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #hr-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #hr-main #left-square {
      width: 86%; }
      #hr-main #left-square .left-content {
        align-items: flex-start !important; }
      #hr-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 1.2;
        margin-left: 16px; }
        #hr-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #hr-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #hr-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #hr-main {
      height: 34rem; }
      #hr-main .container-xl {
        padding: 0; }
      #hr-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #hr-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #hr-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: 280px !important;
          margin-left: 0 !important; }
        #hr-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important; }
        #hr-main #left-square div {
          padding-left: 25px !important; }
        #hr-main #left-square .row p {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.4;
          margin-left: 5px !important; }
      #hr-main #left-square:after {
        top: 41% !important;
        right: 25% !important;
        width: 190px;
        height: 155px; }
      #hr-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #hr-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #hr-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #hr-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

.what-hr {
  color: black; }
  .what-hr .content {
    padding-left: 20px; }
    .what-hr .content h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650; }
      .what-hr .content h2 .blue {
        color: #1e0094; }
    .what-hr .content ul {
      list-style: none;
      padding-left: 1.5rem; }
      .what-hr .content ul li {
        position: relative;
        width: 65%;
        font-size: 17px; }
  @media (max-width: 575.98px) {
    .what-hr {
      overflow-x: hidden !important;
      margin: 0 !important;
      padding: 0 !important;
      padding-top: 30px !important; }
      .what-hr .content h2 {
        font-size: 35px !important;
        width: 100% !important;
        font-weight: 650 !important;
        padding-left: 0; }
      .what-hr .content ul {
        margin-left: -15px !important; }
        .what-hr .content ul li {
          position: relative !important;
          width: 100% !important;
          font-size: 13px !important; }
      .what-hr .container-xl:after {
        display: none !important; } }

.hr-services {
  color: black; }
  .hr-services .content {
    padding-left: 1.5rem; }
    .hr-services .content h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650; }
    .hr-services .content .contract {
      padding-left: 25px;
      font-size: 2rem;
      font-weight: 600; }
      .hr-services .content .contract span {
        color: #20a9df; }
    .hr-services .content ul {
      list-style: none; }
      .hr-services .content ul li {
        position: relative;
        font-size: 16px;
        width: 90%; }
        .hr-services .content ul li:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #20a9df;
          left: -15px;
          display: block;
          top: 7px; }
  .hr-services .blue {
    color: #1e0094; }
  .hr-services .card {
    background-color: transparent;
    border: 1px solid #1e0094;
    border-radius: 40px; }
    .hr-services .card h3 {
      color: #1e0094; }
    .hr-services .card .term {
      font-size: 1.8rem;
      padding-left: 40px; }
    .hr-services .card .hr {
      border: 0;
      width: 75%;
      border-top: 2px solid #20a9df; }
    @media (min-width: 768px) and (max-width: 1366.98px) {
      .hr-services .card {
        height: 450px !important; } }
    @media (min-width: 1650px) {
      .hr-services .card {
        height: 350px !important; } }
  @media (max-width: 575.98px) {
    .hr-services {
      overflow-x: hidden !important;
      margin: 0 !important;
      padding: 0 !important;
      padding-top: 30px !important; }
      .hr-services .container-xl:after {
        display: none !important; }
      .hr-services .content {
        padding-left: 0 !important; } }

.services-kinds {
  position: relative; }
  .services-kinds .container-xl {
    position: relative; }
    .services-kinds .container-xl:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      width: 80%;
      height: 40%;
      background-color: #1e0094;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      border-top-right-radius: 50px; }
    .services-kinds .container-xl:after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 65%;
      height: 55%;
      background-color: #1e0094;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      border-bottom-left-radius: 50px; }
  .services-kinds .services-kinds__item {
    background-color: white;
    border-radius: 40px;
    border: 1px solid #1e0094; }
    .services-kinds .services-kinds__item h3 {
      color: #20a9df;
      font-size: 3.5rem;
      font-weight: 700; }
      @media (max-width: 575.98px) {
        .services-kinds .services-kinds__item h3 {
          font-size: 2.7rem !important; } }
    .services-kinds .services-kinds__item p {
      color: black;
      font-size: 16px;
      margin-bottom: 20px; }
      @media (max-width: 575.98px) {
        .services-kinds .services-kinds__item p {
          font-size: 14px !important; } }

.services-industries {
  color: black;
  overflow-x: hidden; }
  .services-industries .content {
    padding-left: 20px; }
    .services-industries .content h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650; }
      .services-industries .content h2 .blue {
        color: #1e0094; }
  .services-industries .header h2 {
    color: #1e0094;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 10px; }
  .services-industries .header p {
    color: #000 !important;
    font-size: 18px;
    font-weight: 500; }
  .services-industries .card {
    border-radius: 40px;
    background-color: #1e0094 !important;
    color: #20a9df;
    transition: all 0.1s ease-in-out; }
    .services-industries .card .hr {
      width: 70%;
      background-color: #20a9df;
      border: none;
      height: 2px;
      text-align: left;
      color: #20a9df;
      margin-left: 0 !important; }
    .services-industries .card h3 {
      font-size: 27px;
      width: 90%;
      font-weight: 600 !important; }
      .services-industries .card h3 span {
        color: #fff; }
    .services-industries .card .h32 {
      font-size: 23px !important; }
    .services-industries .card .h33 {
      font-size: 27px;
      width: 97%; }
      .services-industries .card .h33 span {
        font-size: 24px; }
    .services-industries .card p {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px; }
    .services-industries .card span {
      color: white;
      font-size: 2rem;
      display: inline-block; }
    .services-industries .card:hover {
      background-color: transparent !important;
      border: 1px solid #1e0094 !important; }
      .services-industries .card:hover span {
        color: #1e0094 !important; }
      .services-industries .card:hover p {
        color: black !important; }
    @media (min-width: 768px) and (max-width: 1366.98px) {
      .services-industries .card {
        height: 450px !important; } }
    @media (min-width: 1490px) {
      .services-industries .card {
        height: 350px !important; } }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; } }

#ADHICS-main {
  position: relative;
  height: 40rem;
  overflow-x: hidden; }
  #ADHICS-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #ADHICS-main #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/ADHICS/Compliance.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #ADHICS-main #left-square .row p {
      font-weight: 400;
      font-size: 31px;
      line-height: 1.4;
      margin-left: 75px;
      width: 100%; }
    #ADHICS-main #left-square .left-content {
      margin-left: 30px; }
    #ADHICS-main #left-square h2 {
      font-size: 7em;
      margin-left: 74px;
      font-weight: 650;
      line-height: 1;
      margin-top: 170px;
      position: relative; }
      #ADHICS-main #left-square h2:before {
        content: " ";
        background-image: url(../images/ADHICS/pattern.png);
        position: absolute;
        top: -33px;
        left: -53px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #ADHICS-main #left-square a {
      border-radius: 5px; }
    #ADHICS-main #left-square:after {
      content: " ";
      background-image: url(../images/ADHICS/ADHICS-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(50%) translateY(-50%); }
  #ADHICS-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #ADHICS-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #ADHICS-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #ADHICS-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #ADHICS-main .mid {
      right: 64% !important; }
    #ADHICS-main #right-square1:before {
      right: 28% !important; }
    #ADHICS-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #ADHICS-main {
      height: 37rem; }
      #ADHICS-main #left-square {
        width: 86%; }
        #ADHICS-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #ADHICS-main #right-square1:before {
        right: -32% !important; }
      #ADHICS-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #ADHICS-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #ADHICS-main #left-square {
      width: 86%; }
      #ADHICS-main #left-square .left-content {
        align-items: flex-start !important; }
      #ADHICS-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #ADHICS-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #ADHICS-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #ADHICS-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #ADHICS-main {
      height: 34rem; }
      #ADHICS-main .container-xl {
        padding: 0; }
      #ADHICS-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #ADHICS-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #ADHICS-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: 280px !important;
          margin-left: 0 !important; }
        #ADHICS-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important; }
        #ADHICS-main #left-square div {
          padding-left: 25px !important; }
        #ADHICS-main #left-square .row p {
          font-weight: 500;
          font-size: 18px;
          line-height: 1.4;
          margin-left: 5px !important; }
      #ADHICS-main #left-square:after {
        top: 41% !important;
        right: 25% !important;
        width: 140px !important;
        height: 200px !important; }
      #ADHICS-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #ADHICS-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #ADHICS-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #ADHICS-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

.what-ADHICS {
  color: black; }
  .what-ADHICS .container-xl:after {
    content: "";
    position: absolute;
    width: 39%;
    height: 115%;
    background-image: url(../images/ADHICS/2.jpeg);
    background-size: cover;
    right: 0;
    top: -40%;
    display: block;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px; }
  .what-ADHICS .content {
    padding-left: 20px; }
    .what-ADHICS .content h2 {
      padding-left: 25px;
      font-size: 3rem;
      width: 37%;
      font-weight: 650; }
      .what-ADHICS .content h2 .blue {
        color: #1e0094; }
    .what-ADHICS .content ul {
      list-style: none; }
      .what-ADHICS .content ul li {
        position: relative;
        width: 55%;
        font-size: 17px; }
        .what-ADHICS .content ul li:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #20a9df;
          left: -15px;
          display: block;
          top: 7px; }
  @media (max-width: 575.98px) {
    .what-ADHICS {
      overflow-x: hidden !important;
      margin: 0 !important;
      padding: 0 !important;
      padding-top: 30px !important; }
      .what-ADHICS .content h2 {
        font-size: 35px !important;
        width: 100% !important;
        font-weight: 650 !important;
        padding-left: 0; }
      .what-ADHICS .content ul {
        margin-left: -15px !important; }
        .what-ADHICS .content ul li {
          position: relative !important;
          width: 100% !important;
          font-size: 13px !important; }
      .what-ADHICS .container-xl:after {
        display: none !important; } }

#our-adhics {
  overflow-x: hidden; }
  #our-adhics .col-md-3 {
    transition: all 0.3s ease-in-out; }
    #our-adhics .col-md-3:hover {
      transform: scale(1.05); }
  #our-adhics .header h2 {
    color: #1e0094;
    font-weight: 700;
    font-size: 35px;
    margin-bottom: 10px; }
  #our-adhics .header p {
    color: #000 !important;
    font-size: 18px;
    font-weight: 500; }
  #our-adhics .card {
    border-radius: 40px;
    background-color: #1e0094 !important;
    color: #20a9df; }
    #our-adhics .card .hr {
      width: 70%;
      background-color: #20a9df;
      border: none;
      height: 2px;
      text-align: left;
      color: #20a9df;
      margin-left: 0 !important; }
    #our-adhics .card h3 {
      font-size: 27px;
      width: 90%;
      font-weight: 600 !important; }
      #our-adhics .card h3 span {
        color: #fff; }
    #our-adhics .card .h32 {
      font-size: 23px !important; }
    #our-adhics .card .h33 {
      font-size: 27px;
      width: 97%; }
      #our-adhics .card .h33 span {
        font-size: 24px; }
    #our-adhics .card ul {
      list-style: none;
      /* Remove default bullets */
      margin-left: -38px; }
      #our-adhics .card ul li {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px; }
    #our-adhics .card ul li::before {
      content: "\2022";
      /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: #20a9df;
      /* Change the color */
      font-weight: bold;
      /* If you want it to be bold */
      display: inline-block;
      /* Needed to add space between the bullet and the text */
      width: 1em;
      /* Also needed for space (tweak if needed) */
      margin-left: -1em;
      /* Also needed for space (tweak if needed) */ }
  @media (max-width: 575.98px) {
    #our-adhics .row .col-md-12 {
      margin-left: 0 !important;
      padding-left: 0 !important; }
      #our-adhics .row .col-md-12 p {
        margin-left: 20px !important;
        padding-left: 20px !important; }
      #our-adhics .row .col-md-12 h2 {
        margin-left: 20px !important;
        padding-left: 20px !important; }
    #our-adhics .col-md-3 {
      margin-bottom: 30px !important; } }

#our-carosal {
  overflow-x: hidden; }
  #our-carosal .container-xl {
    padding-top: 50px;
    background: #1e0094;
    transition: all 0.2 ease-in-out;
    border-radius: 60px; }
    #our-carosal .container-xl #carousel-custom-dots {
      list-style: none;
      text-align: center;
      display: flex;
      justify-content: space-evenly;
      position: relative; }
      #our-carosal .container-xl #carousel-custom-dots li {
        font-size: 30px !important;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        cursor: pointer;
        z-index: 5; }
      @media (max-width: 575.98px) {
        #our-carosal .container-xl #carousel-custom-dots {
          padding-left: 0 !important; } }
    #our-carosal .container-xl .owl-item {
      height: 285px !important;
      background: rgba(119, 148, 255, 0.2);
      border-radius: 30px !important;
      opacity: 0.5; }
    #our-carosal .container-xl .active .item h4 {
      font-size: 80px;
      font-weight: 600;
      margin-top: 50px !important;
      margin-left: 40px !important;
      color: #20a9df; }
    #our-carosal .container-xl .active .item p {
      margin-left: 40px !important;
      font-size: 16px;
      width: 75%;
      color: white;
      font-weight: 400; }
    #our-carosal .container-xl .owl-theme .owl-nav.disabled + .owl-dots {
      position: absolute;
      top: -100px;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      left: -2%; }
    #our-carosal .container-xl .owl-dots:before {
      content: "";
      display: block;
      position: absolute;
      width: 88%;
      height: 1px;
      left: 6%;
      top: 48%;
      background-color: white;
      z-index: 0; }
    #our-carosal .container-xl .owl-dots:after {
      content: "";
      display: block;
      position: absolute;
      width: 10%;
      height: 3px;
      left: 6%;
      top: 44%;
      background-color: #20a9df;
      z-index: 0; }
    #our-carosal .container-xl .owl-dots.f0:after {
      width: calc(100% / 8); }
    #our-carosal .container-xl .owl-dots.f1:after {
      width: calc(100% / 8 * 2); }
    #our-carosal .container-xl .owl-dots.f2:after {
      width: calc(100% / 8 * 3); }
    #our-carosal .container-xl .owl-dots.f3:after {
      width: calc(100% / 8 * 4); }
    #our-carosal .container-xl .owl-dots.f4:after {
      width: calc(100% / 8 * 5); }
    #our-carosal .container-xl .owl-dots.f5:after {
      width: calc(100% / 8 * 6); }
    #our-carosal .container-xl .owl-dots.f6:after {
      width: calc(88%); }
    #our-carosal .container-xl .owl-dots .owl-dot span {
      background-color: white;
      width: 10px;
      height: 10px;
      background: #d6d6d6;
      display: block;
      border-radius: 50%; }
    #our-carosal .container-xl .owl-dots .owl-dot.active span {
      position: relative;
      background: #20a9df !important;
      width: 20px !important;
      left: 50%;
      height: 20px !important;
      top: 50%;
      transform: translate(-50%, -44%); }
      #our-carosal .container-xl .owl-dots .owl-dot.active span:before {
        content: "";
        width: 30px !important;
        height: 30px !important;
        border-radius: 50% !important;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border: 1px solid white; }
    #our-carosal .container-xl .owl-dots .owl-dot.active1 span {
      position: relative;
      background: #20a9df !important;
      top: -2px; }
      #our-carosal .container-xl .owl-dots .owl-dot.active1 span:before {
        content: "";
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 80%;
        background-color: #20a9df !important;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      #our-carosal .container-xl .owl-dots .owl-dot.active1 span:after {
        content: "";
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 82%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border: 1px solid white;
        z-index: 5; }
    #our-carosal .container-xl .lifecycle {
      color: white; }
      #our-carosal .container-xl .lifecycle h3 {
        padding-left: 46px;
        font-size: 45px;
        font-weight: 600; }
        @media (max-width: 575.98px) {
          #our-carosal .container-xl .lifecycle h3 {
            padding-left: 8px !important; } }
  @media (max-width: 575.98px) {
    #our-carosal {
      padding-left: 0 !important; }
      #our-carosal .owl-carousel {
        padding-left: 0 !important; }
      #our-carosal .item p {
        font-size: 14px !important; } }

.owl-item:not(.active) + .owl-item.active {
  opacity: 1 !important;
  background-color: white !important; }
  .owl-item:not(.active) + .owl-item.active .item h4 {
    font-size: 80px;
    font-weight: 600;
    margin-top: 50px !important;
    margin-left: 40px !important;
    color: #20a9df; }
  .owl-item:not(.active) + .owl-item.active .item p {
    margin-left: 40px !important;
    font-size: 18px;
    width: 75%;
    color: #1e0094 !important;
    font-weight: 500; }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #our-adhics .col-md-3 {
    height: 630px !important; }
    #our-adhics .col-md-3 .card {
      height: 100% !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #our-adhics .col-md-3 {
    height: 630px !important; }
    #our-adhics .col-md-3 .card {
      height: 100% !important; } }

.main-2 {
  color: #1e0094 !important;
  margin-bottom: 0.4em !important;
  padding-bottom: 0;
  font-weight: 650;
  font-size: 29px;
  margin-left: -20px; }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #our-adhics .col-md-3 {
    height: 630px !important; }
    #our-adhics .col-md-3 .card {
      height: 100% !important; } }

.iframe {
  margin-top: 100px;
  height: 100vh;
  width: 100%; }

#you-trust {
  position: relative;
  height: 40rem;
  margin-bottom: 80px; }
  #you-trust .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #you-trust #left-square {
    height: 100%;
    width: 90%;
    /* background-color: green; */
    background-size: cover;
    background-image: url(../images/Homepage/home/s-2.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #you-trust #left-square .row p {
      width: 60%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4; }
    #you-trust #left-square .left-content {
      margin-left: 30px; }
    #you-trust #left-square h2 {
      margin-top: 130px;
      font-weight: 610;
      font-size: 6em;
      line-height: 0.9; }
    #you-trust #left-square a {
      border-radius: 5px; }
  #you-trust #right-square {
    position: absolute;
    top: 0;
    right: 0;
    width: 480px;
    height: 550px;
    background-color: #1e0094;
    border-radius: 0 0 0 40px;
    padding-left: 60px; }
    #you-trust #right-square h2 {
      color: #1f96d5 !important;
      font-size: 37px;
      font-weight: 700;
      margin-bottom: 10px; }
    #you-trust #right-square .right-square__cyper {
      color: #1f96d5 !important;
      font-size: 25px;
      font-weight: 600; }
    #you-trust #right-square .right-square__text {
      font-size: 14px;
      margin-bottom: 30px;
      width: 81% !important; }
    #you-trust #right-square .prev {
      padding: 0;
      position: absolute;
      bottom: 53px;
      left: -14px;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      text-align: center;
      line-height: 60px; }
      #you-trust #right-square .prev span {
        padding: 0; }
      #you-trust #right-square .prev:hover {
        background-color: #20a9df; }
    #you-trust #right-square .next {
      padding: 0;
      position: absolute;
      bottom: 53px;
      left: 50%;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      text-align: center;
      line-height: 60px; }
      #you-trust #right-square .next.active {
        background-color: #20a9df; }
      #you-trust #right-square .next:hover {
        background-color: #20a9df; }
      #you-trust #right-square .next span {
        padding: 0; }
    #you-trust #right-square .divider {
      left: 12%;
      display: block;
      width: 36%;
      height: 1px;
      background-color: #1f96d5;
      bottom: 82px;
      position: absolute; }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #you-trust {
      height: 37rem; }
      #you-trust #left-square {
        width: 86%; }
        #you-trust #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #you-trust #right-square {
        width: 46%;
        height: 550px;
        padding-left: 30px !important; } }
  @media (max-width: 767.98px) {
    #you-trust #right-square {
      width: 46%;
      height: 550px;
      padding-left: 30px !important; }
    #you-trust #left-square {
      width: 86%; }
      #you-trust #left-square h2 {
        font-size: 3.3em; } }
  @media (max-width: 575.98px) {
    #you-trust {
      height: 57rem; }
      #you-trust .container-xl {
        padding: 0; }
      #you-trust #left-square {
        width: 100% !important;
        height: 60% !important;
        border-radius: 0; }
        #you-trust #left-square .left-content {
          margin-left: 0 !important;
          justify-content: center !important; }
        #you-trust #left-square h2 {
          font-size: 3.3em !important;
          margin-top: 66px !important; }
        #you-trust #left-square p {
          width: 92% !important;
          margin-top: 0px !important; }
        #you-trust #left-square div {
          padding-left: 6px !important; }
      #you-trust #right-square {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #you-trust #right-square .prev {
          bottom: 40px !important;
          left: 30px !important; }
        #you-trust #right-square .divider {
          left: 31% !important;
          bottom: 70px !important; }
        #you-trust #right-square .next {
          bottom: 41px !important;
          left: 72% !important; }
        #you-trust #right-square .right-square__text {
          width: 100% !important; }
          #you-trust #right-square .right-square__text a {
            display: inline-block;
            margin: 20px 0; }
        #you-trust #right-square .carousel-item {
          height: 96% !important; }
        #you-trust #right-square .read-more-component {
          text-align: left !important;
          margin-left: 75px !important; } }

#about-us {
  height: 600px; }
  #about-us .container-xl {
    height: 100%; }
    #about-us .container-xl .row {
      height: 100%; }
    #about-us .container-xl .about-us__content {
      height: 100%;
      position: relative;
      color: black; }
      #about-us .container-xl .about-us__content:before {
        content: " ";
        background-image: url(../images/Homepage/about/pattern.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 214px;
        left: 44px;
        z-index: -1;
        width: 40%;
        height: 30%;
        border-radius: 0;
        background-size: cover; }
      #about-us .container-xl .about-us__content .about-us__content__text {
        position: absolute;
        left: 120px;
        top: 270px; }
        #about-us .container-xl .about-us__content .about-us__content__text h2 {
          font-weight: 650;
          font-size: 58px;
          margin-bottom: 8px; }
        #about-us .container-xl .about-us__content .about-us__content__text p {
          position: relative;
          font-size: 16px;
          line-height: 1.4;
          overflow: visible;
          width: 237%;
          color: #3a2525;
          font-weight: 500;
          z-index: 1;
          margin-bottom: 40px; }
      #about-us .container-xl .about-us__content .about__read {
        color: black !important; }
        #about-us .container-xl .about-us__content .about__read .read-more-component:hover .read-more__icon__span i {
          color: black !important; }
        #about-us .container-xl .about-us__content .about__read .read-more-component:hover .read-more__icon__span i {
          color: white !important; }
    #about-us .container-xl .about-us__images {
      height: 100%;
      position: relative;
      background-image: url(../images/Homepage/about/guy.png);
      background-repeat: no-repeat;
      background-size: 42%;
      background-position: bottom center; }
      #about-us .container-xl .about-us__images:before {
        content: " ";
        background-image: url(../images/Homepage/about/i-TE.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 161px;
        left: -12px;
        z-index: 0;
        width: 51%;
        height: 43%;
        border-radius: 0;
        background-size: cover; }
      #about-us .container-xl .about-us__images:after {
        content: " ";
        background-image: url(../images/Homepage/about/AM.png);
        background-repeat: no-repeat;
        background-size: 32%;
        background-position: bottom center;
        position: absolute;
        top: 214px;
        right: 14px;
        z-index: -1;
        width: 42%;
        height: 35%;
        border-radius: 0;
        background-size: cover; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #about-us .about-us__content:before {
      width: 110px !important;
      height: 110px !important; }
    #about-us .about-us__content .about-us__content__text {
      left: 79px !important;
      top: 244px !important; }
      #about-us .about-us__content .about-us__content__text h2 {
        font-size: 42px !important; }
    #about-us .about-us__images:after {
      top: 255px !important;
      right: 2px !important;
      width: 308px !important;
      height: 177px !important; }
    #about-us .about-us__images:before {
      position: absolute !important;
      top: 221px !important;
      left: -38px !important;
      width: 379px !important;
      height: 214px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #about-us .about-us__content__text p {
      width: 325%; }
    #about-us .about-us__images {
      background-size: 59% !important;
      background-position: top left !important; }
      #about-us .about-us__images:after {
        top: 54px !important;
        right: 120px !important;
        width: 254px !important;
        height: 146px !important; }
      #about-us .about-us__images:before {
        top: 32px !important;
        left: -215px !important;
        width: 322px !important;
        height: 179px !important; } }
  @media (max-width: 767.98px) {
    #about-us {
      height: 810px; }
      #about-us .about-us__content {
        height: 32% !important; }
        #about-us .about-us__content .about-us__content__text {
          position: absolute !important;
          left: 120px !important;
          top: 56px !important; }
          #about-us .about-us__content .about-us__content__text h2 {
            font-size: 44px !important; }
          #about-us .about-us__content .about-us__content__text p {
            font-size: 14px !important;
            width: 92% !important; }
        #about-us .about-us__content:before {
          top: 23px !important;
          left: 45px !important;
          width: 150px !important;
          height: 150px !important; }
      #about-us .about-us__images {
        height: 68% !important;
        background-size: 45% !important;
        background-position: bottom center !important; }
        #about-us .about-us__images:after {
          top: 245px !important;
          right: 10px !important;
          width: 225px !important;
          height: 129px !important; }
        #about-us .about-us__images:before {
          top: 225px !important;
          left: 13px !important;
          width: 280px !important;
          height: 157px !important; } }
  @media (max-width: 575.98px) {
    #about-us {
      height: 750px; }
      #about-us .about-us__content {
        height: 46% !important; }
        #about-us .about-us__content .about-us__content__text {
          position: absolute !important;
          left: 120px !important;
          top: 56px !important; }
          #about-us .about-us__content .about-us__content__text h2 {
            font-size: 44px !important; }
          #about-us .about-us__content .about-us__content__text p {
            font-size: 14px !important;
            width: 92% !important; }
        #about-us .about-us__content:before {
          top: 23px !important;
          left: 45px !important;
          width: 150px !important;
          height: 150px !important; }
      #about-us .about-us__images {
        height: 54% !important;
        background-size: 51% !important;
        background-position: 43% 100% !important; }
        #about-us .about-us__images:after {
          top: 210px !important;
          right: 6px !important;
          width: 159px !important;
          height: 90px !important; }
        #about-us .about-us__images:before {
          top: 197px !important;
          left: 9px !important;
          width: 172px !important;
          height: 97px !important; } }

#our-solutions {
  position: relative;
  height: 800px;
  z-index: 0; }
  #our-solutions .container-xl {
    height: 100%;
    background-size: cover;
    background-image: url(../images/Homepage/our-solutions/bg.png);
    background-repeat: no-repeat; }
    #our-solutions .container-xl .row {
      height: 100%; }
      #our-solutions .container-xl .row .our-solutions__content {
        height: 100%;
        position: relative;
        color: black; }
        #our-solutions .container-xl .row .our-solutions__content:before {
          content: " ";
          background-image: url(../images/Homepage/our-solutions/pattern.png);
          background-repeat: no-repeat;
          background-size: 32%;
          background-position: bottom center;
          position: absolute;
          top: 118px;
          left: 44px;
          z-index: -1;
          width: 24%;
          height: 22%;
          border-radius: 0;
          background-size: contain; }
        #our-solutions .container-xl .row .our-solutions__content .our-solutions__content__text {
          position: absolute;
          left: 100px;
          top: 200px; }
          #our-solutions .container-xl .row .our-solutions__content .our-solutions__content__text h2 {
            font-weight: 600;
            font-size: 46px;
            margin-bottom: 8px; }
          #our-solutions .container-xl .row .our-solutions__content .our-solutions__content__text p {
            position: relative;
            font-size: 16px;
            line-height: 1.4;
            overflow: visible;
            width: 237%;
            color: #3a2525;
            font-weight: 500;
            z-index: 1; }
        #our-solutions .container-xl .row .our-solutions__content .our-solutions__ul__box {
          position: absolute;
          top: 383px;
          left: 44px; }
          #our-solutions .container-xl .row .our-solutions__content .our-solutions__ul__box h3 {
            font-weight: 600;
            margin-bottom: 18px;
            font-size: 30px; }
          #our-solutions .container-xl .row .our-solutions__content .our-solutions__ul__box ul {
            list-style: none;
            padding: 0;
            margin: 0; }
            #our-solutions .container-xl .row .our-solutions__content .our-solutions__ul__box ul li i {
              color: #2196f3;
              opacity: 0.5;
              font-size: 18px; }
            #our-solutions .container-xl .row .our-solutions__content .our-solutions__ul__box ul li img {
              width: 20px !important; }
            #our-solutions .container-xl .row .our-solutions__content .our-solutions__ul__box ul li span {
              font-size: 15px;
              margin-left: 8px;
              line-height: 2; }
        #our-solutions .container-xl .row .our-solutions__content .our-solutions__read {
          position: absolute;
          bottom: 20px;
          left: 45px;
          display: block;
          height: 100px;
          width: 185px; }
      #our-solutions .container-xl .row .our-solutions__slider {
        padding: 100px 100px;
        padding: 100px 50px !important;
        height: 817px;
        overflow: scroll; }
        #our-solutions .container-xl .row .our-solutions__slider .row div {
          margin: 10px;
          border-radius: 20px;
          height: 294px; }
          #our-solutions .container-xl .row .our-solutions__slider .row div.box {
            background-size: 99%;
            background-repeat: no-repeat;
            transition: 0.2s;
            cursor: pointer;
            position: relative;
            background-image: url(../images/Homepage/our-solutions/box.png); }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:hover {
              background-image: url(../images/Homepage/our-solutions/box-on-hover.png); }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:before {
              content: " ";
              background-image: url(../images/Homepage/our-solutions/Advanced-icon.png);
              background-repeat: no-repeat;
              background-size: 32%;
              background-position: bottom center;
              position: absolute;
              top: 59px;
              z-index: 0;
              border-radius: 0;
              background-size: cover;
              height: 117px !important;
              width: 100px !important;
              left: 50% !important;
              transform: translateX(-50%) !important; }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:not(:first-child):before {
              width: 117px !important; }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box .text-box {
              position: absolute;
              height: 50px;
              bottom: 14px; }
              #our-solutions .container-xl .row .our-solutions__slider .row div.box .text-box p {
                font-weight: 600;
                line-height: 1.3;
                font-size: 21px; }
              #our-solutions .container-xl .row .our-solutions__slider .row div.box .text-box h2 {
                margin-bottom: 5px; }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:nth-child(1):before {
              background-image: url(../images/Homepage/our-solutions/Cyber-icon.png); }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:nth-child(3):before {
              background-image: url(../images/Homepage/our-solutions/Advanced-icon.png); }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:nth-child(4):before {
              top: 62px;
              background-image: url(../images/ICT-Professional-Solutions/ICT-icon.png); }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:nth-child(6):before {
              background-image: url(../images/hr/Outsourcing-icon.png);
              top: 57px; }
            #our-solutions .container-xl .row .our-solutions__slider .row div.box:nth-child(7):before {
              background-image: url(../images/Homepage/our-solutions/icon.png);
              top: 57px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #our-solutions .box {
      background-size: cover !important; }
      #our-solutions .box:before {
        height: 117px !important;
        width: 100px !important;
        left: 50% !important;
        transform: translateX(-50%) !important; }
      #our-solutions .box:not(:first-child):before {
        width: 117px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #our-solutions .box {
      background-size: cover !important; }
    #our-solutions .our-solutions__content:before {
      top: 50px !important;
      left: 44px !important;
      width: 135px !important;
      height: 175px !important; }
    #our-solutions .our-solutions__content .our-solutions__ul__box ul li span {
      font-size: 16px !important; }
    #our-solutions .our-solutions__slider {
      padding: 50px !important;
      padding-top: 0 !important; } }
  @media (max-width: 767.98px) {
    #our-solutions {
      height: 1020px !important; }
      #our-solutions .our-solutions__content {
        height: 580px !important; }
        #our-solutions .our-solutions__content:before {
          top: 0px !important; }
        #our-solutions .our-solutions__content .our-solutions__content__text {
          top: 75px !important; }
        #our-solutions .our-solutions__content .our-solutions__ul__box {
          top: 240px !important; }
        #our-solutions .our-solutions__content .our-solutions__read {
          bottom: 0 !important; }
      #our-solutions .our-solutions__slider {
        height: 457px !important; }
        #our-solutions .our-solutions__slider .row {
          justify-content: center !important; } }
  @media (max-width: 575.98px) {
    #our-solutions {
      height: 1150px !important; }
      #our-solutions .offset-md-2 {
        display: none !important; }
      #our-solutions .box {
        background-size: cover !important; }
      #our-solutions .our-solutions__content {
        height: 600px !important; }
        #our-solutions .our-solutions__content:before {
          top: 0px !important; }
        #our-solutions .our-solutions__content .our-solutions__content__text {
          top: 75px !important; }
        #our-solutions .our-solutions__content .our-solutions__ul__box {
          top: 383px;
          left: 16px !important; }
          #our-solutions .our-solutions__content .our-solutions__ul__box ul li span {
            font-size: 12px !important; }
          #our-solutions .our-solutions__content .our-solutions__ul__box ul li {
            margin-bottom: 3px !important; }
        #our-solutions .our-solutions__content .our-solutions__read {
          bottom: -60px !important; }
      #our-solutions .our-solutions__slider {
        padding-top: 0 !important;
        height: 565px !important; }
        #our-solutions .our-solutions__slider .row {
          justify-content: center !important; } }

.our-solutions__slider::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */ }

#our-industries {
  height: 725px;
  background: white;
  position: relative;
  z-index: 0; }
  #our-industries .our-industries__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    background-color: #1e0094;
    border-radius: 0 0 50px 0;
    z-index: 1; }
    #our-industries .our-industries__left:before {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 50px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 23%;
      border-radius: 0;
      background-size: contain; }
    #our-industries .our-industries__left .our-industries__content__text {
      position: absolute;
      left: 100px;
      top: 100px; }
      #our-industries .our-industries__left .our-industries__content__text h2 {
        font-weight: 600;
        font-size: 46px;
        margin-bottom: 8px; }
    #our-industries .our-industries__left .our-industries__left__read {
      position: absolute;
      top: 150px; }
      #our-industries .our-industries__left .our-industries__left__read .read-more__text {
        margin-left: 25px; }
      #our-industries .our-industries__left .our-industries__left__read .read-more__icon__span {
        right: 34px; }
  #our-industries .our-industries__right {
    position: absolute;
    padding-top: 100px;
    top: 0;
    right: 0;
    height: 100%;
    width: 80%;
    background-color: #eee;
    border-radius: 0 0 0 50px;
    z-index: 0; }
    #our-industries .our-industries__right .our-industries__right__row {
      width: 100%; }
    #our-industries .our-industries__right .row {
      height: 100%; }
      #our-industries .our-industries__right .row .items .item {
        margin-bottom: 10px;
        border-radius: 18px;
        padding-left: 30px;
        box-sizing: border-box;
        display: inline-block;
        width: 49%;
        height: 300px;
        cursor: pointer;
        color: black;
        transition: 0.4s; }
        #our-industries .our-industries__right .row .items .item h3 {
          margin: 50px 0 5px 0;
          font-size: 20px;
          font-weight: 650;
          color: black; }
        #our-industries .our-industries__right .row .items .item h2 {
          margin-bottom: 10px;
          color: #1e0094;
          font-weight: 700;
          margin-top: 30px !important; }
        #our-industries .our-industries__right .row .items .item p {
          font-size: 18px;
          font-weight: 500;
          line-height: 1.5;
          color: #000; }
        #our-industries .our-industries__right .row .items .item .our-industries__item__read {
          margin-top: 35px; }
          #our-industries .our-industries__right .row .items .item .our-industries__item__read .read-more-component:hover .read-more__icon__span i {
            color: black !important; }
          #our-industries .our-industries__right .row .items .item .our-industries__item__read .read-more-component:hover .read-more__icon__span i {
            color: white !important; }
        #our-industries .our-industries__right .row .items .item:hover {
          background-color: #20a9df; }
          #our-industries .our-industries__right .row .items .item:hover .read-more__divider {
            height: 1px;
            background-color: #1e0094; }
          #our-industries .our-industries__right .row .items .item:hover p,
          #our-industries .our-industries__right .row .items .item:hover h3 {
            color: white; }
        #our-industries .our-industries__right .row .items .item .read-more-component:hover .read-more__icon__span {
          opacity: 1;
          background-color: #1e0094 !important; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #our-industries .our-industries__right .item p {
      font-size: 16px;
      height: 88px !important;
      word-break: break-word !important; }
    #our-industries .our-industries__content__text {
      position: absolute !important;
      left: 100px !important;
      top: 112px !important; }
      #our-industries .our-industries__content__text h2 {
        font-weight: 600 !important;
        font-size: 35px !important;
        margin-bottom: 43px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #our-industries .item {
      padding-left: 20px !important; }
      #our-industries .item p {
        font-size: 13px !important; }
    #our-industries .our-industries__content__text {
      left: 68px !important;
      top: 123px !important; }
      #our-industries .our-industries__content__text h2 {
        font-size: 30px; } }
  @media (max-width: 767.98px) {
    #our-industries {
      height: 950px; }
      #our-industries .our-industries__left {
        border-radius: 0 !important;
        height: 300px !important;
        width: 100% !important;
        background-color: #1e0094 !important; }
        #our-industries .our-industries__left .our-industries__left__read {
          position: absolute !important;
          top: 135px !important; }
        #our-industries .our-industries__left:before {
          top: 50px !important;
          left: 44px !important;
          width: 150px !important;
          height: 150px !important; }
        #our-industries .our-industries__left .our-industries__content__text {
          left: 100px !important;
          top: 100px !important; }
          #our-industries .our-industries__left .our-industries__content__text h2 {
            font-weight: 600 !important;
            font-size: 46px !important;
            margin-bottom: 8px !important; }
      #our-industries .our-industries__right {
        position: absolute;
        padding-top: 100px;
        top: 225px;
        right: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #eee;
        padding-left: 12px;
        border-radius: 0 0 0 50px;
        z-index: 0; } }
  @media (max-width: 575.98px) {
    #our-industries {
      height: 1645px !important; }
      #our-industries .item {
        width: 100% !important;
        height: 320px !important; } }

#our-customers {
  height: 600px;
  background: #fff;
  position: relative;
  z-index: 0;
  z-index: unset; }
  #our-customers .our-customers__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    border-radius: 0 0 50px 0;
    z-index: 1; }
    #our-customers .our-customers__left:before {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 50px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 28%;
      border-radius: 0;
      background-size: contain; }
    #our-customers .our-customers__left .our-customers__content__text {
      position: absolute;
      left: 104px;
      top: 100px; }
      #our-customers .our-customers__left .our-customers__content__text h2 {
        font-weight: 650;
        font-size: 46px;
        margin-bottom: 8px;
        line-height: 48px;
        color: #1e0094; }
  #our-customers .our-customers__right {
    position: absolute;
    padding-top: 307px;
    top: 0;
    right: 0;
    height: 100%;
    width: 84%;
    z-index: 1; }
    #our-customers .our-customers__right .items {
      padding: 0;
      margin: 0;
      justify-content: space-evenly; }
      #our-customers .our-customers__right .items .item {
        overflow: hidden;
        border-radius: 25px;
        height: 265px;
        width: 20%;
        position: relative;
        border: 1px solid #1f96d5;
        transition: 0.4s;
        z-index: 100000;
        background-color: white;
        background-repeat: no-repeat;
        background-size: contain;
        overflow: hidden;
        background-position: center center;
        filter: grayscale(0.9); }
        #our-customers .our-customers__right .items .item:hover {
          filter: none; }
        #our-customers .our-customers__right .items .item:hover:before {
          filter: none; }
        #our-customers .our-customers__right .items .item:before {
          content: " ";
          background-repeat: no-repeat;
          background-size: 32%;
          background-position: bottom center;
          position: absolute;
          top: 7%;
          left: 7%;
          background-repeat: no-repeat;
          width: 91%;
          height: 80%;
          border-radius: 0;
          background-size: contain;
          filter: grayscale(1); }
        #our-customers .our-customers__right .items .item:nth-child(1) {
          background-image: url(../images/Homepage/our-customers/a1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center; }
        #our-customers .our-customers__right .items .item:nth-child(2) {
          background-image: url(../images/Homepage/our-customers/b1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center; }
        #our-customers .our-customers__right .items .item:nth-child(3) {
          background-image: url(../images/Homepage/our-customers/c1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center; }
        #our-customers .our-customers__right .items .item:nth-child(4) {
          background-image: url(../images/Homepage/our-customers/d1.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-size: 75%;
          overflow: hidden;
          background-position: center center; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #our-customers .our-customers__right {
      height: 100%;
      width: 100%; }
      #our-customers .our-customers__right .item {
        height: 265px;
        width: 227px; }
    #our-customers .our-customers__left:before {
      width: 150px;
      height: 150px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #our-customers {
      height: 540px !important; }
      #our-customers .item {
        height: 220px !important;
        width: 23% !important; }
      #our-customers .our-customers__left:before {
        width: 150px;
        height: 150px; } }
  @media (max-width: 767.98px) {
    #our-customers {
      height: 875px !important; }
      #our-customers .our-customers__right {
        height: 100% !important;
        width: 100% !important; }
      #our-customers .item {
        height: 258px !important;
        width: 32% !important; }
      #our-customers .our-customers__left:before {
        width: 150px;
        height: 150px; } }
  @media (max-width: 575.98px) {
    #our-customers {
      height: 875px !important; }
      #our-customers .item {
        height: 258px !important;
        width: 46% !important; } }

#our-partners2 {
  height: 700px;
  background: #fff;
  position: relative;
  z-index: 0; }
  #our-partners2 .our-partners__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    border-radius: 0 0 50px 0;
    z-index: 1; }
    #our-partners2 .our-partners__left:before {
      content: " ";
      background-image: url(../images/Homepage/our-partners/bg.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: -225px;
      left: 0;
      z-index: -83;
      width: 294%;
      height: 126%;
      border-radius: 0;
      background-size: contain;
      left: -27px; }
    #our-partners2 .our-partners__left:after {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 150px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 28%;
      border-radius: 0;
      background-size: contain; }
    #our-partners2 .our-partners__left .our-partners__content__text {
      position: absolute;
      left: 104px;
      top: 200px; }
      #our-partners2 .our-partners__left .our-partners__content__text h2 {
        font-weight: 650;
        font-size: 46px;
        margin-bottom: 8px;
        line-height: 48px;
        color: #1e0094; }
  #our-partners2 .our-partners__right {
    position: absolute;
    bottom: 0px;
    border-radius: 0 50px 50px 0;
    left: 0;
    height: 38%;
    width: 77%;
    z-index: 2;
    background-color: #ebebeb; }
    #our-partners2 .our-partners__right .items {
      justify-content: space-between;
      align-items: center;
      height: 100%; }
      #our-partners2 .our-partners__right .items hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        width: 1px;
        height: 38%;
        color: black;
        background-color: gray;
        z-index: 10000;
        display: block; }
      #our-partners2 .our-partners__right .items .item {
        cursor: pointer;
        height: 100px;
        width: 23.5%;
        position: relative;
        transition: 0.4s;
        background-image: url(../images/Homepage/our-partners/p11.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        padding: 51px; }
        #our-partners2 .our-partners__right .items .item:hover:before {
          filter: none; }
        #our-partners2 .our-partners__right .items .item:nth-child(1) {
          background-image: url(../images/Homepage/our-partners/a1.png); }
        #our-partners2 .our-partners__right .items .item:nth-child(3) {
          background-image: url(../images/Homepage/our-partners/b1.png); }
        #our-partners2 .our-partners__right .items .item:nth-child(5) {
          background-image: url(../images/Homepage/our-partners/c1.png); }
        #our-partners2 .our-partners__right .items .item:nth-child(7) {
          background-image: url(../images/Homepage/our-partners/d1.png); }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #our-partners2 .item {
      width: 22%; }
    #our-partners2 .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important; }
    #our-partners2 .our-partners__left:before {
      top: -260px !important; }
    #our-partners2 .our-partners__content__text {
      top: 145px !important; }
    #our-partners2 .our-partners__left:after {
      top: 100px;
      width: 150px;
      height: 150px; }
    #our-partners2 .our-partners__right {
      top: 330px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #our-partners2 {
      height: 415px !important; }
      #our-partners2 .our-partners__left:before {
        top: -154px !important; }
      #our-partners2 .our-partners__right {
        top: 241px !important; }
      #our-partners2 .our-partners__left:after {
        top: 60px !important;
        width: 150px !important;
        height: 150px !important; }
      #our-partners2 .our-partners__content__text {
        top: 98px !important; } }
  @media (max-width: 767.98px) {
    #our-partners2 .item {
      width: 22% !important; }
    #our-partners2 .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important; }
    #our-partners2 .our-partners__right {
      width: 100%;
      border-radius: 0 !important; }
    #our-partners2 .our-partners__right {
      top: 290px !important; } }
  @media (max-width: 575.98px) {
    #our-partners2 {
      height: 490px; }
      #our-partners2 .our-partners__right {
        top: 216px !important;
        height: 48% !important;
        width: 100%;
        border-radius: 0 !important; }
      #our-partners2 .item {
        width: 47% !important; }
        #our-partners2 .item:nth-child(4):before {
          left: -8px !important;
          width: 100% !important; }
      #our-partners2 .our-partners__left .our-partners__content__text {
        left: 85px !important;
        top: 81px !important; }
        #our-partners2 .our-partners__left .our-partners__content__text h2 {
          font-size: 38px; }
      #our-partners2 .our-partners__left:after {
        width: 100px !important;
        height: 100px !important;
        top: 77px !important; }
      #our-partners2 .our-partners__left:before {
        top: -308px; } }

#our-partners {
  height: 700px;
  background: #fff;
  position: relative;
  z-index: 0; }
  #our-partners .our-partners__left {
    position: absolute;
    left: 0;
    top: 0;
    height: 85%;
    width: 30%;
    border-radius: 0 0 50px 0;
    z-index: 1; }
    #our-partners .our-partners__left:before {
      content: " ";
      background-image: url(../images/Homepage/our-partners/bg.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: -225px;
      left: 0;
      z-index: -83;
      width: 294%;
      height: 126%;
      border-radius: 0;
      background-size: contain;
      left: -27px; }
    #our-partners .our-partners__left:after {
      content: " ";
      background-image: url(../images/Homepage/our-solutions/pattern.png);
      background-repeat: no-repeat;
      background-size: 32%;
      background-position: bottom center;
      position: absolute;
      top: 150px;
      left: 44px;
      z-index: -1;
      width: 36%;
      height: 28%;
      border-radius: 0;
      background-size: contain; }
    #our-partners .our-partners__left .our-partners__content__text {
      position: absolute;
      left: 104px;
      top: 200px; }
      #our-partners .our-partners__left .our-partners__content__text h2 {
        font-weight: 650;
        font-size: 46px;
        margin-bottom: 8px;
        line-height: 48px;
        color: #1e0094; }
  #our-partners .our-partners__right {
    position: absolute;
    bottom: 0px;
    border-radius: 0 50px 50px 0;
    left: 0;
    height: 38%;
    width: 77%;
    z-index: 2;
    background-color: #ebebeb; }
    #our-partners .our-partners__right .items {
      justify-content: space-between;
      align-items: center;
      height: 100%; }
      #our-partners .our-partners__right .items hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        width: 1px;
        height: 38%;
        color: black;
        background-color: gray;
        z-index: 10000;
        display: block; }
      #our-partners .our-partners__right .items .item {
        height: 100px;
        width: 23.5%;
        position: relative;
        transition: 0.4s;
        background-image: url(../images/Homepage/our-partners/p11.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 66%;
        filter: grayscale(0.9);
        padding: 51px; }
        #our-partners .our-partners__right .items .item:hover {
          filter: none; }
          #our-partners .our-partners__right .items .item:hover:before {
            filter: none; }
        #our-partners .our-partners__right .items .item:nth-child(1) {
          background-image: url(../images/Homepage/our-partners/a1.png); }
        #our-partners .our-partners__right .items .item:nth-child(3) {
          background-image: url(../images/Homepage/our-partners/b1.png); }
        #our-partners .our-partners__right .items .item:nth-child(5) {
          background-image: url(../images/Homepage/our-partners/c1.png); }
        #our-partners .our-partners__right .items .item:nth-child(7) {
          background-image: url(../images/Homepage/our-partners/d1.png); }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #our-partners .item {
      width: 22% !important; }
    #our-partners .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important; }
    #our-partners .our-partners__left:before {
      top: -260px !important; }
    #our-partners .our-partners__content__text {
      top: 145px !important; }
    #our-partners .our-partners__left:after {
      top: 100px;
      width: 150px;
      height: 150px; }
    #our-partners .our-partners__right {
      top: 330px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #our-partners {
      height: 415px !important; }
      #our-partners .item {
        width: 22.5% !important; }
      #our-partners .our-partners__left:before {
        top: -154px !important; }
      #our-partners .our-partners__right {
        top: 241px !important; }
      #our-partners .our-partners__left:after {
        top: 60px !important;
        width: 150px !important;
        height: 150px !important; }
      #our-partners .our-partners__content__text {
        top: 98px !important; } }
  @media (max-width: 767.98px) {
    #our-partners .item {
      width: 22% !important; }
    #our-partners .item:nth-child(4):before {
      left: -8px !important;
      width: 100% !important; }
    #our-partners .our-partners__right {
      width: 100%;
      border-radius: 0 !important; }
    #our-partners .our-partners__right {
      top: 290px !important; } }
  @media (max-width: 575.98px) {
    #our-partners {
      height: 490px; }
      #our-partners .our-partners__right {
        top: 216px !important;
        height: 48% !important;
        width: 100%;
        border-radius: 0 !important; }
      #our-partners .item {
        width: 47% !important; }
        #our-partners .item:nth-child(4):before {
          left: -8px !important;
          width: 100% !important; }
      #our-partners hr {
        display: none !important; }
      #our-partners .our-partners__left .our-partners__content__text {
        left: 85px !important;
        top: 81px !important; }
        #our-partners .our-partners__left .our-partners__content__text h2 {
          font-size: 38px; }
      #our-partners .our-partners__left:after {
        width: 100px !important;
        height: 100px !important;
        top: 77px !important; }
      #our-partners .our-partners__left:before {
        top: -308px; } }

#white-space {
  height: 100px;
  background-color: white; }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #you-trust {
    height: 42rem; }
  #about-us .about-us__content:before {
    height: 33% !important; }
  #about-us .about-us__images {
    height: 106% !important; }
    #about-us .about-us__images:before {
      height: 45% !important; }
    #about-us .about-us__images:after {
      height: 37% !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #you-trust {
    height: 48rem !important; }
    #you-trust #right-square {
      width: 600px;
      height: 620px; }
      #you-trust #right-square .right-square__cyper {
        font-size: 33px !important; }
  #about-us {
    height: 625px !important; }
    #about-us .about-us__content:before {
      height: 36% !important; }
    #about-us .about-us__images {
      height: 113% !important; }
      #about-us .about-us__images:before {
        height: 45% !important;
        width: 50% !important; }
      #about-us .about-us__images:after {
        height: 41% !important; }
  #our-solutions .container-xl .row .our-solutions__slider .row div.box {
    height: 370px !important; }
    #our-solutions .container-xl .row .our-solutions__slider .row div.box:before {
      height: 175px !important;
      width: 150px !important; }
    #our-solutions .container-xl .row .our-solutions__slider .row div.box:not(:first-child):before {
      width: 175px !important;
      height: 175px !important; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #about-us .about-us__images {
    background-size: 34% !important; }
    #about-us .about-us__images:before {
      top: 174px !important;
      left: 118px !important;
      width: 39% !important;
      height: 43% !important; }
    #about-us .about-us__images:after {
      top: 214px !important;
      right: 100px !important;
      width: 32% !important;
      height: 35% !important; }
  #about-us .about-us__content:before {
    left: 68px !important;
    width: 150px !important;
    height: 150px !important; }
  #our-solutions .our-solutions__ul__box li span {
    font-size: 18px !important; } }

.d-none-custom {
  display: none; }

#about-main {
  position: relative;
  height: 40rem; }
  #about-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #about-main #left-square {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/about-us/bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #about-main #left-square .row p {
      width: 60%; }
    #about-main #left-square .left-content {
      margin-left: 30px; }
    #about-main #left-square h2 {
      margin-left: 65px;
      font-weight: 610;
      font-size: 6em;
      line-height: 0.9;
      margin-top: 140px;
      position: relative; }
      #about-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -21px;
        left: -58px;
        z-index: -1;
        width: 25%;
        height: 176%;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #about-main #left-square a {
      border-radius: 5px; }
  #about-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #about-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #about-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #about-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #about-main .mid {
      right: 64% !important; }
    #about-main #right-square1:before {
      right: 28% !important; }
    #about-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #about-main {
      height: 37rem; }
      #about-main #left-square {
        width: 86%; }
        #about-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #about-main #right-square1:before {
        right: -32% !important; }
      #about-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #about-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #about-main #left-square {
      width: 86%; }
      #about-main #left-square .left-content {
        align-items: flex-start !important; }
      #about-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #about-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #about-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #about-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #about-main {
      height: 34rem; }
      #about-main .container-xl {
        padding: 0; }
      #about-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #about-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #about-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: -70px !important;
          margin-left: 0 !important; }
        #about-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important; }
        #about-main #left-square div {
          padding-left: 25px !important; }
      #about-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #about-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #about-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #about-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

#about-text {
  position: relative; }
  #about-text .container-xl {
    position: relative;
    padding-left: 6rem !important;
    padding-right: 6rem !important; }
  #about-text p {
    color: #000 !important;
    width: 95%;
    font-size: 16px;
    font-weight: 400; }
  #about-text .left-square p {
    font-size: 17px; }
  #about-text .left-square:before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    width: 35px;
    height: 3px;
    background-color: #2196f3; }
  #about-text .left-square .iTEAM {
    font-weight: 600; }
  #about-text .right-square:before {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    width: 35px;
    height: 3px;
    background-color: #2196f3; }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #about-text .container-xl {
      padding-left: 4rem !important;
      padding-right: 4rem !important; } }
  @media (max-width: 767.98px) {
    #about-text .container-xl {
      padding-left: 3rem !important;
      padding-right: 3rem !important; } }

#our-team {
  height: 750px;
  position: relative; }
  #our-team .container-xl:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    background-size: cover;
    background-image: url(../images/about-us/our-team-bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    opacity: 0.15;
    border-radius: 40px 0 0 0; }
  #our-team .container-xl .left-content {
    margin-left: 30px; }
  #our-team .container-xl h2 {
    margin-left: -20px;
    font-weight: 610;
    font-size: 50px;
    line-height: 0.9;
    margin-top: 158px;
    position: relative;
    color: #1e0094; }
    #our-team .container-xl h2:before {
      content: " ";
      background-image: url(../images/about-us/pattern.png);
      position: absolute;
      top: -56px;
      left: -59px;
      z-index: -1;
      width: 18%;
      height: 343%;
      background-repeat: no-repeat;
      background-size: cover; }
  #our-team .container-xl p {
    color: black;
    margin-left: -15px;
    font-size: 16px;
    font-weight: 500; }
  #our-team .container-xl blockquote {
    color: #1e0094;
    margin-left: -15px;
    font-size: 20px;
    font-weight: bold; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #our-team h2:before {
      width: 25% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #our-team h2:before {
      width: 27% !important; } }
  @media (max-width: 767.98px) {
    #our-team h2 {
      margin-left: 15px !important; }
    #our-team h2:before {
      left: -35px !important;
      width: 32% !important; }
    #our-team blockquote {
      font-size: 15px !important; } }
  @media (max-width: 575.98px) {
    #our-team h2:before {
      width: 47% !important; } }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 42rem; }
  .mid {
    width: 377px !important;
    height: 88% !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 48rem !important; }
    #about-main #right-square1 {
      width: 600px; }
      #about-main #right-square1:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px; }
      #about-main #right-square1:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px; }
      #about-main #right-square1 .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; } }

#meeting .container-xl .meeting__logo img {
  width: 250px !important; }

@media (max-width: 575.98px) {
  #meeting .container-xl .meeting__logo img {
    width: 160px !important; } }

#meeting .container-xl .meeting__quote {
  text-align: center; }
  #meeting .container-xl .meeting__quote blockquote {
    color: #1e0094;
    line-height: 1.8;
    font-size: 22px;
    font-weight: 600; }
  @media (max-width: 575.98px) {
    #meeting .container-xl .meeting__quote blockquote {
      font-size: 10px !important; } }

#meeting .container-xl .meeting__events .event {
  text-align: center; }
  #meeting .container-xl .meeting__events .event img {
    width: 50px; }
    @media (max-width: 575.98px) {
      #meeting .container-xl .meeting__events .event img {
        width: 38px; } }
  #meeting .container-xl .meeting__events .event span {
    color: #d22ebd;
    font-size: 24px;
    text-align: right;
    margin-left: 12%;
    font-weight: 600; }
    @media (max-width: 575.98px) {
      #meeting .container-xl .meeting__events .event span {
        color: #d22ebd;
        font-size: 16px;
        text-align: right;
        margin-left: 12%;
        font-weight: 600; } }
  @media (max-width: 575.98px) {
    #meeting .container-xl .meeting__events .event {
      margin-bottom: 15px !important; } }

#meeting .container-xl .meeting__button {
  text-align: center; }
  #meeting .container-xl .meeting__button button {
    cursor: pointer;
    background: #d22ebd;
    border-radius: 28px;
    color: #fff;
    border: #d22ebd;
    padding: 20px 35px;
    font-size: 27px;
    font-weight: 500;
    outline: none;
    transition: all 0.3s; }
    #meeting .container-xl .meeting__button button:hover {
      opacity: 0.8; }
    @media (max-width: 575.98px) {
      #meeting .container-xl .meeting__button button {
        padding: 10px 21px !important;
        font-size: 18px !important; } }

@media (min-width: 768px) and (max-width: 1366.98px) {
  #meeting .meeting__content:before {
    width: 110px !important;
    height: 110px !important; }
  #meeting .meeting__content .meeting__content__text {
    left: 79px !important;
    top: 244px !important; }
    #meeting .meeting__content .meeting__content__text h2 {
      font-size: 42px !important; } }

@media (min-width: 576px) and (max-width: 991.98px) {
  #meeting .meeting__content__text p {
    width: 325%; } }

@media (max-width: 767.98px) {
  #meeting .meeting__content {
    height: 32% !important; }
    #meeting .meeting__content .meeting__content__text {
      position: absolute !important;
      left: 120px !important;
      top: 56px !important; }
      #meeting .meeting__content .meeting__content__text h2 {
        font-size: 44px !important; }
      #meeting .meeting__content .meeting__content__text p {
        font-size: 14px !important;
        width: 92% !important; }
    #meeting .meeting__content:before {
      top: 23px !important;
      left: 45px !important;
      width: 150px !important;
      height: 150px !important; } }

@media (max-width: 575.98px) {
  #meeting .meeting__content {
    height: 46% !important; }
    #meeting .meeting__content .meeting__content__text {
      position: absolute !important;
      left: 120px !important;
      top: 56px !important; }
      #meeting .meeting__content .meeting__content__text h2 {
        font-size: 44px !important; }
      #meeting .meeting__content .meeting__content__text p {
        font-size: 14px !important;
        width: 92% !important; } }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; } }

.privacy__section h2 {
  font-weight: 650;
  font-size: 2rem;
  margin-bottom: 8px;
  line-height: 48px;
  color: #1e0094; }

.privacy__section ul {
  color: black !important; }

#industries-main {
  position: relative;
  height: 40rem; }
  #industries-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #industries-main #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/Our-industries/header.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #industries-main #left-square .row p {
      width: 60%; }
    #industries-main #left-square .left-content {
      margin-left: 30px; }
    #industries-main #left-square h2 {
      margin-left: 65px;
      font-weight: 650;
      font-size: 8em;
      line-height: 0.9;
      margin-top: 140px;
      position: relative; }
      #industries-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -53px;
        left: -58px;
        z-index: -1;
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #industries-main #left-square a {
      border-radius: 5px; }
  #industries-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #industries-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #industries-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #industries-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #industries-main .mid {
      right: 64% !important; }
    #industries-main #right-square1:before {
      right: 28% !important; }
    #industries-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #industries-main {
      height: 37rem; }
      #industries-main #left-square {
        width: 86%; }
        #industries-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #industries-main #right-square1:before {
        right: -32% !important; }
      #industries-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #industries-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #industries-main #left-square {
      width: 86%; }
      #industries-main #left-square .left-content {
        align-items: flex-start !important; }
      #industries-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #industries-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #industries-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #industries-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #industries-main {
      height: 34rem; }
      #industries-main .container-xl {
        padding: 0; }
      #industries-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #industries-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #industries-main #left-square h2 {
          font-size: 3.9em !important;
          margin-top: 52px !important;
          margin-left: 0 !important; }
        #industries-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important; }
        #industries-main #left-square div {
          padding-left: 25px !important; }
      #industries-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #industries-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #industries-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #industries-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

#industries-desc {
  height: 1100px; }
  #industries-desc .row {
    height: 100%; }
    #industries-desc .row #left-square {
      position: relative;
      height: 100%; }
      #industries-desc .row #left-square .img1 {
        border-radius: 50px;
        content: "";
        position: absolute;
        left: 50px;
        top: 201px;
        width: 360px;
        height: 620px;
        overflow: hidden; }
        #industries-desc .row #left-square .img1:after {
          content: " ";
          background-image: url(../images/Our-industries/image.png);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain; }
      #industries-desc .row #left-square .img2 {
        border-radius: 50px;
        content: "";
        position: absolute;
        left: 200px;
        bottom: 298px;
        width: 340px;
        height: 455px;
        overflow: hidden;
        z-index: 2; }
        #industries-desc .row #left-square .img2:after {
          content: " ";
          background-image: url(../images/Our-industries/image1.png);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover; }
    #industries-desc .row #right-square {
      padding-top: 200px;
      padding-bottom: 200px;
      padding-left: 25px; }
      #industries-desc .row #right-square h3 {
        margin-bottom: 0;
        padding-bottom: 0;
        font-weight: 650;
        color: #2196f3; }
        #industries-desc .row #right-square h3:not(:first-of-type) {
          margin-top: 90px; }
      #industries-desc .row #right-square p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.2;
        padding: 0;
        margin: 0;
        width: 90%; }
      #industries-desc .row #right-square ul {
        padding: 0;
        margin: 0; }
        #industries-desc .row #right-square ul li {
          padding: 0;
          margin: 0;
          margin-left: 20px; }
          #industries-desc .row #right-square ul li::marker {
            color: black; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #industries-desc #right-square h3:not(:first-of-type) {
      margin-top: 40px !important; }
    #industries-desc #right-square p {
      font-size: 14px !important; }
    #industries-desc #left-square {
      position: relative;
      height: 100%; }
      #industries-desc #left-square .img1 {
        left: 50px !important;
        top: 100px !important;
        width: 350px !important;
        height: 500px !important; }
      #industries-desc #left-square .img2 {
        left: 120px !important;
        top: 511px !important;
        width: 350px !important;
        height: 450px !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #industries-desc {
      height: 1100px !important; }
      #industries-desc #right-square {
        padding-top: 100px !important;
        padding-bottom: 200px !important; }
        #industries-desc #right-square p {
          width: 100%; }
      #industries-desc #left-square .img1 {
        left: 20px !important;
        top: 201px !important;
        width: 250px !important;
        height: 380px !important; }
      #industries-desc #left-square .img2 {
        left: 82px !important;
        top: 476px !important;
        width: 238px !important;
        height: 325px !important; } }
  @media (max-width: 767.98px) {
    #industries-desc #left-square {
      display: none; }
    #industries-desc #right-square h3:not(:first-of-type) {
      margin-top: 20px !important; } }
  @media (max-width: 575.98px) {
    #industries-desc {
      height: 1500px !important; } }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 42rem; }
  .mid {
    width: 377px !important;
    height: 88% !important; }
  #industries-desc .row #left-square .img2 {
    position: absolute !important;
    left: 200px !important;
    bottom: 45px !important;
    width: 356px !important;
    height: 505px !important; }
  #industries-desc .row #left-square .img1 {
    left: 50px !important;
    top: 201px !important;
    width: 400px !important;
    height: 620px !important;
    border-radius: 50px !important; }
  #industries-desc .row #left-square .img1:after {
    width: 112% !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #industries-main {
    height: 48rem !important; }
    #industries-main #right-square1 {
      width: 600px; }
      #industries-main #right-square1:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px; }
      #industries-main #right-square1:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px; }
      #industries-main #right-square1 .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat; }
  #industries-desc .row #left-square .img2 {
    position: absolute !important;
    left: 200px !important;
    bottom: 45px !important;
    width: 356px !important;
    height: 505px !important; }
  #industries-desc .row #left-square .img1 {
    left: 50px !important;
    top: 201px !important;
    width: 400px !important;
    height: 620px !important;
    border-radius: 50px !important; }
  #industries-desc .row #left-square .img1:after {
    width: 112% !important; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #industries-desc .row #left-square .img2 {
    position: absolute !important;
    left: 200px !important;
    bottom: 45px !important;
    width: 356px !important;
    height: 505px !important; }
  #industries-desc .row #left-square .img1 {
    left: 50px !important;
    top: 201px !important;
    width: 400px !important;
    height: 620px !important;
    border-radius: 50px !important; }
  #industries-desc .row #left-square .img1:after {
    width: 112% !important; } }

#ICT-main {
  position: relative;
  height: 40rem; }
  #ICT-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #ICT-main #left-square {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/ICT-Professional-Solutions/header.png);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 0 40px 0; }
    #ICT-main #left-square .row p {
      width: 87%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px; }
    #ICT-main #left-square .left-content {
      margin-left: 30px; }
    #ICT-main #left-square h2 {
      margin-left: 74px;
      font-weight: 650;
      font-size: 6em;
      line-height: 0.8;
      margin-top: 300px;
      position: relative; }
      #ICT-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -41px;
        left: -58px;
        z-index: -1;
        width: 135px;
        height: 135px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #ICT-main #left-square a {
      border-radius: 5px; }
    #ICT-main #left-square:after {
      content: " ";
      background-image: url(../images/ICT-Professional-Solutions/ICT-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(50%) translateY(-50%); }
  #ICT-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #ICT-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #ICT-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #ICT-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #ICT-main .mid {
      right: 64% !important; }
    #ICT-main #right-square1:before {
      right: 28% !important; }
    #ICT-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #ICT-main {
      height: 37rem; }
      #ICT-main #left-square {
        width: 86%; }
        #ICT-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #ICT-main #right-square1:before {
        right: -32% !important; }
      #ICT-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #ICT-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #ICT-main #left-square {
      width: 86%; }
      #ICT-main #left-square .left-content {
        align-items: flex-start !important; }
      #ICT-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #ICT-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #ICT-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #ICT-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (min-width: 992px) and (max-width: 1366.98px) {
    #ICT-main {
      height: 37rem; } }
  @media (max-width: 575.98px) {
    #ICT-main {
      height: 34rem; }
      #ICT-main .container-xl {
        padding: 0; }
      #ICT-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #ICT-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #ICT-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: 280px !important;
          margin-left: 0 !important; }
        #ICT-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important;
          width: 87%;
          font-weight: 500;
          font-size: 18px;
          line-height: 1.4;
          margin-left: 5px !important; }
        #ICT-main #left-square div {
          padding-left: 25px !important; }
        #ICT-main #left-square:after {
          top: 37% !important;
          right: 24% !important;
          width: 190px;
          height: 162px; }
      #ICT-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #ICT-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #ICT-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #ICT-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

#ict-solutions {
  position: relative;
  height: 1000px;
  padding-top: 300px;
  padding-bottom: 200px; }
  #ict-solutions .left-square {
    width: 55%;
    border-radius: 75px;
    background-color: #1e0094 !important;
    position: absolute;
    left: 100px;
    padding: 125px; }
    #ict-solutions .left-square h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 650;
      color: #20a9df; }
      #ict-solutions .left-square h3:not(:first-of-type) {
        margin-top: 50px; }
  #ict-solutions .right-square {
    position: absolute;
    z-index: -2;
    top: -153px;
    right: 157px;
    height: 88%;
    width: 32%;
    background-size: cover;
    background-image: url(../images/ICT-Professional-Solutions/up.png);
    background-repeat: no-repeat;
    border-radius: 50px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #ict-solutions .left-square {
      padding: 70px !important; }
    #ict-solutions .right-square {
      top: -188px;
      right: 100px;
      height: 104%;
      width: 40%; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #ict-solutions .left-square {
      padding: 50px !important;
      width: 60% !important;
      left: 40px !important; }
    #ict-solutions .right-square {
      top: -153px;
      right: 52px;
      height: 88%;
      width: 45%; } }
  @media (max-width: 767.98px) {
    #ict-solutions {
      padding-top: 100px; }
      #ict-solutions .right-square {
        display: none !important; }
      #ict-solutions .left-square {
        width: 88% !important; } }
  @media (max-width: 575.98px) {
    #ict-solutions {
      padding-top: 100px; }
      #ict-solutions .right-square {
        display: none !important; }
      #ict-solutions .left-square {
        width: 100% !important;
        left: 0 !important;
        border-radius: 0;
        padding: 35px; } }
  @media (min-width: 992px) and (max-width: 1366.98px) {
    #ict-solutions {
      height: 1214px; }
      #ict-solutions .left-square {
        padding: 118px !important; } }

#ict-solutions-s2 {
  position: relative;
  height: 1300px;
  padding-top: 300px;
  padding-bottom: 200px; }
  #ict-solutions-s2 .left-square {
    width: 55%;
    border-radius: 100px;
    background-color: #1e0094 !important;
    position: absolute;
    right: 100px;
    padding: 125px; }
    #ict-solutions-s2 .left-square h3 {
      margin-bottom: 0;
      padding-bottom: 0;
      font-weight: 650;
      color: #20a9df; }
      #ict-solutions-s2 .left-square h3:not(:first-of-type) {
        margin-top: 50px; }
  #ict-solutions-s2 .right-square {
    position: absolute;
    z-index: -2;
    top: -153px;
    left: 157px;
    height: 88%;
    width: 32%;
    background-size: cover;
    background-image: url(../images/ICT-Professional-Solutions/down.png);
    background-repeat: no-repeat;
    border-radius: 50px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #ict-solutions-s2 .left-square {
      padding: 70px !important; }
    #ict-solutions-s2 .right-square {
      top: -153px;
      right: 100px;
      height: 88%;
      width: 40%; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #ict-solutions-s2 {
      margin-top: 100px; }
      #ict-solutions-s2 .left-square {
        padding: 50px !important;
        width: 60% !important;
        right: 85px; }
      #ict-solutions-s2 .right-square {
        top: -153px;
        right: 52px;
        height: 88%;
        width: 45%; }
      #ict-solutions-s2 .right-square {
        left: 42px; } }
  @media (max-width: 767.98px) {
    #ict-solutions-s2 {
      padding-top: 100px; }
      #ict-solutions-s2 .right-square {
        display: none !important; }
      #ict-solutions-s2 .left-square {
        width: 88% !important; } }
  @media (max-width: 575.98px) {
    #ict-solutions-s2 {
      padding-top: 100px;
      height: 1170px !important;
      padding-bottom: 0 !important; }
      #ict-solutions-s2 .right-square {
        display: none !important; }
      #ict-solutions-s2 .left-square {
        width: 100% !important;
        padding: 25px;
        border-radius: 0 !important;
        left: 0 !important; } }
  @media (min-width: 992px) and (max-width: 1366.98px) {
    #ict-solutions-s2 {
      height: 1300px; }
      #ict-solutions-s2 .left-square {
        padding: 118px !important;
        top: 45px; }
      #ict-solutions-s2 .right-square {
        left: 95px; } }

#ict-vendor {
  margin-top: 100px; }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 42rem; }
  .mid {
    width: 377px !important;
    height: 88% !important; }
  #ict-solutions {
    height: 1300px !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #industries-main {
    height: 48rem !important; }
    #industries-main #right-square1 {
      width: 600px; }
      #industries-main #right-square1:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px; }
      #industries-main #right-square1:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px; }
      #industries-main #right-square1 .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat; }
  #ict-solutions {
    height: 1300px !important; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #ict-solutions {
    height: 1300px !important; } }

#advanced-tech-main {
  position: relative;
  height: 40rem; }
  #advanced-tech-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #advanced-tech-main #left-square {
    position: absolute;
    z-index: -1;
    height: 93%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/Advanced-tech/header.png);
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0 0 40px 0; }
    #advanced-tech-main #left-square .row p {
      width: 60%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px; }
    #advanced-tech-main #left-square .left-content {
      margin-left: 30px; }
    #advanced-tech-main #left-square h2 {
      margin-left: 74px;
      font-weight: 650;
      font-size: 6em;
      line-height: .8;
      margin-top: 250px;
      position: relative; }
      #advanced-tech-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -59px;
        left: -58px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #advanced-tech-main #left-square a {
      border-radius: 5px; }
    #advanced-tech-main #left-square:after {
      content: " ";
      background-image: url(../images/Advanced-tech/advanced-tech-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      transform: translateX(50%) translateY(-50%); }
  #advanced-tech-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #advanced-tech-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #advanced-tech-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #advanced-tech-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #advanced-tech-main .mid {
      right: 64% !important; }
    #advanced-tech-main #right-square1:before {
      right: 28% !important; }
    #advanced-tech-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #advanced-tech-main {
      height: 37rem; }
      #advanced-tech-main #left-square {
        width: 86%; }
        #advanced-tech-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #advanced-tech-main #right-square1:before {
        right: -32% !important; }
      #advanced-tech-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #advanced-tech-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #advanced-tech-main #left-square {
      width: 86%; }
      #advanced-tech-main #left-square .left-content {
        align-items: flex-start !important; }
      #advanced-tech-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #advanced-tech-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #advanced-tech-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #advanced-tech-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #advanced-tech-main {
      height: 34rem; }
      #advanced-tech-main .container-xl {
        padding: 0; }
      #advanced-tech-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #advanced-tech-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #advanced-tech-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: 280px !important;
          margin-left: 0 !important; }
        #advanced-tech-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important;
          margin-left: 5px !important; }
        #advanced-tech-main #left-square div {
          padding-left: 25px !important; }
      #advanced-tech-main #left-square:after {
        top: 43% !important;
        right: 26% !important;
        width: 160px;
        height: 190px; }
      #advanced-tech-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #advanced-tech-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #advanced-tech-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #advanced-tech-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

#advanced-cloud {
  width: 100%;
  padding: 100px 75px 100px 75px; }
  #advanced-cloud #left-square {
    padding-top: 40px;
    padding-bottom: 75px; }
  #advanced-cloud h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 27px; }
    #advanced-cloud h3:not(:first-of-type) {
      margin-top: 20px;
      color: #1e0094 !important;
      font-size: 22px;
      margin-left: 10px; }
  #advanced-cloud p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 16px !important;
    line-height: 1.4; }
  #advanced-cloud ul {
    padding: 0;
    margin: 0; }
    #advanced-cloud ul li {
      padding: 0;
      margin: 0;
      margin-left: 20px; }
      #advanced-cloud ul li::marker {
        color: black; }
    #advanced-cloud ul:not(:first-of-type) {
      margin-left: 10px;
      font-size: 14px !important; }
  #advanced-cloud #right-square {
    text-align: center; }
    #advanced-cloud #right-square img {
      width: 80%; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #advanced-cloud {
      padding-top: 50px !important;
      padding: 51px 25px 0 25px !important; }
      #advanced-cloud #right-square img {
        width: 100%;
        height: 80%;
        border-radius: 75px; }
      #advanced-cloud p {
        font-size: 15px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #advanced-cloud {
      padding: 50px 25px 50px 25px; }
      #advanced-cloud #left-square {
        padding-top: 0px !important; }
      #advanced-cloud p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
        padding: 0;
        margin: 0;
        width: 100%; }
      #advanced-cloud h3:not(:first-of-type) {
        margin-top: 30px; }
      #advanced-cloud #right-square img {
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    #advanced-cloud {
      padding: 50px 25px 50px 25px !important; }
      #advanced-cloud #left-square {
        padding-top: 0; }
      #advanced-cloud #right-square img {
        display: none; }
      #advanced-cloud p {
        font-size: 15px; } }

#advanced-backap {
  width: 100%;
  padding: 0 75px 100px 75px; }
  #advanced-backap #left-square {
    padding-top: 25px;
    padding-bottom: 100px; }
  #advanced-backap h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 27px; }
    #advanced-backap h3:not(:first-of-type) {
      margin-top: 20px;
      color: #1e0094 !important;
      font-size: 22px;
      margin-left: 10px; }
  #advanced-backap p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 90%;
    font-size: 16px !important;
    line-height: 1.4; }
  #advanced-backap ul {
    padding: 0;
    margin: 0; }
    #advanced-backap ul li {
      padding: 0;
      margin: 0;
      margin-left: 20px; }
      #advanced-backap ul li::marker {
        color: black; }
    #advanced-backap ul:not(:first-of-type) {
      margin-left: 10px;
      font-size: 14px !important; }
  #advanced-backap #right-square {
    text-align: left; }
    #advanced-backap #right-square img {
      width: 92%; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #advanced-backap {
      height: 550px;
      padding: 0 25px 50px 25px !important; }
      #advanced-backap #right-square img {
        width: 90%;
        height: 65%;
        border-radius: 75px; }
      #advanced-backap p {
        font-size: 15px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #advanced-backap {
      padding: 50px 25px 50px 25px; }
      #advanced-backap #left-square {
        padding-top: 0px !important; }
      #advanced-backap p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
        padding: 0;
        margin: 0;
        width: 100%; }
      #advanced-backap h3:not(:first-of-type) {
        margin-top: 30px; }
      #advanced-backap #right-square img {
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    #advanced-backap {
      padding: 50px 25px 50px 25px !important; }
      #advanced-backap #left-square {
        padding-top: 0; }
      #advanced-backap #right-square img {
        display: none; }
      #advanced-backap p {
        font-size: 15px; } }

#managed-service {
  margin-top: 100px;
  height: 650px;
  position: relative; }
  #managed-service .shared-frorm {
    height: 750px !important; }
  #managed-service .h3rap {
    position: absolute;
    top: 65px;
    left: 100px; }
    #managed-service .h3rap h3 {
      font-size: 3em;
      font-weight: 600;
      width: 70%; }
  #managed-service .managed-ul {
    position: absolute;
    left: 90px;
    top: 233px;
    line-height: 1.8;
    font-size: 21px; }
  @media (max-width: 575.98px) {
    #managed-service .h3rap h3 {
      font-size: 2.5em;
      font-weight: 600;
      width: 70%; } }

#advanced-cloud1 {
  width: 100%;
  padding: 100px 75px 100px 75px; }
  #advanced-cloud1 #left-square {
    padding-top: 35px;
    padding-bottom: 75px; }
  #advanced-cloud1 h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 27px; }
    #advanced-cloud1 h3:not(:first-of-type) {
      margin-top: 20px;
      color: #1e0094 !important;
      font-size: 22px;
      margin-left: 10px; }
  #advanced-cloud1 p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 16px !important;
    line-height: 1.4; }
  #advanced-cloud1 ul {
    padding: 0;
    margin: 0; }
    #advanced-cloud1 ul li {
      padding: 0;
      margin: 0;
      margin-left: 20px; }
      #advanced-cloud1 ul li::marker {
        color: black; }
    #advanced-cloud1 ul:not(:first-of-type) {
      margin-left: 10px;
      font-size: 14px !important; }
  #advanced-cloud1 #right-square {
    text-align: center; }
    #advanced-cloud1 #right-square img {
      width: 80%; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #advanced-cloud1 {
      height: 700px;
      padding-top: 50px !important;
      padding: 51px 25px 0 25px !important; }
      #advanced-cloud1 #right-square img {
        width: 92%;
        height: 70%;
        border-radius: 75px; }
      #advanced-cloud1 p {
        font-size: 15px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #advanced-cloud1 {
      padding: 50px 25px 50px 25px; }
      #advanced-cloud1 #left-square {
        padding-top: 0px !important; }
      #advanced-cloud1 p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
        padding: 0;
        margin: 0;
        width: 100%; }
      #advanced-cloud1 h3:not(:first-of-type) {
        margin-top: 30px; }
      #advanced-cloud1 #right-square img {
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    #advanced-cloud1 {
      padding: 50px 25px 50px 25px !important; }
      #advanced-cloud1 #left-square {
        padding-top: 0; }
      #advanced-cloud1 #right-square img {
        display: none; }
      #advanced-cloud1 p {
        font-size: 15px; } }

#advanced-backap1 {
  width: 100%;
  padding: 0 75px 100px 75px; }
  #advanced-backap1 #left-square {
    padding-top: 50px;
    padding-bottom: 100px; }
  #advanced-backap1 h3 {
    margin-bottom: 0.4em !important;
    padding-bottom: 0;
    font-weight: 650;
    color: #2196f3;
    font-size: 27px; }
    #advanced-backap1 h3:not(:first-of-type) {
      margin-top: 20px;
      color: #1e0094 !important;
      font-size: 22px;
      margin-left: 10px; }
  #advanced-backap1 p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    width: 90%;
    font-size: 16px !important;
    line-height: 1.4; }
  #advanced-backap1 ul {
    padding: 0;
    margin: 0; }
    #advanced-backap1 ul li {
      padding: 0;
      margin: 0;
      margin-left: 20px; }
      #advanced-backap1 ul li::marker {
        color: black; }
    #advanced-backap1 ul:not(:first-of-type) {
      margin-left: 10px;
      font-size: 14px !important; }
  #advanced-backap1 #right-square {
    text-align: left; }
    #advanced-backap1 #right-square img {
      width: 92%; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #advanced-backap1 {
      height: 600px;
      padding: 0 25px 50px 25px !important; }
      #advanced-backap1 #right-square img {
        width: 88%;
        height: 65%;
        border-radius: 75px; }
      #advanced-backap1 p {
        font-size: 15px; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #advanced-backap1 {
      padding: 50px 25px 50px 25px; }
      #advanced-backap1 #left-square {
        padding-top: 0px !important; }
      #advanced-backap1 p {
        color: #000;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;
        padding: 0;
        margin: 0;
        width: 100%; }
      #advanced-backap1 h3:not(:first-of-type) {
        margin-top: 30px; }
      #advanced-backap1 #right-square img {
        width: 100%;
        height: 100%; } }
  @media (max-width: 767.98px) {
    #advanced-backap1 {
      padding: 50px 25px 50px 25px !important; }
      #advanced-backap1 #left-square {
        padding-top: 0; }
      #advanced-backap1 #right-square img {
        display: none; }
      #advanced-backap1 p {
        font-size: 15px; } }

#adv-vendor {
  height: 1200px;
  overflow: hidden;
  position: relative;
  z-index: 2; }
  #adv-vendor > .container-xl.adv:after {
    content: " ";
    background-image: url(../images/Advanced-tech/img8.jpeg);
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 96%;
    height: 590px;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-top-left-radius: 75px; }
  #adv-vendor > .oth:after {
    content: " ";
    background-image: url(../images/Advanced-tech/img8.jpeg);
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 96%;
    height: 590px;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-top-left-radius: 75px; }
  #adv-vendor .container-xl {
    padding: 0;
    position: relative !important; }
  #adv-vendor .managed-text {
    position: absolute;
    left: 109px;
    top: 65px;
    font-size: 2.5rem;
    font-weight: 600;
    width: 100%;
    line-height: 1.2; }
    @media (max-width: 575.98px) {
      #adv-vendor .managed-text {
        position: absolute;
        left: 40px;
        top: 43pxpx;
        font-size: 1.8rem;
        font-weight: 600;
        width: 100%;
        line-height: 1.2; } }
  #adv-vendor .managed-ul {
    position: absolute;
    left: 90px;
    top: 135px;
    line-height: 1.8;
    font-size: 21px; }
    @media (max-width: 575.98px) {
      #adv-vendor .managed-ul {
        left: 16px;
        top: 147px;
        line-height: 1.8;
        font-size: 16px; } }
  #adv-vendor #vendor {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2; }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 42rem; }
  .mid {
    width: 377px !important;
    height: 88% !important; }
  #advanced-cloud #right-square img {
    width: 80% !important;
    height: 90% !important;
    border-radius: 75px !important; }
  #advanced-cloud1 #right-square img {
    width: 80% !important;
    height: 90% !important;
    border-radius: 75px !important; }
  #advanced-backap #right-square img {
    width: 95% !important;
    height: 57% !important;
    border-radius: 75px !important; }
  #advanced-backap1 #right-square img {
    width: 95% !important;
    height: 57% !important;
    border-radius: 75px !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #industries-main {
    height: 48rem !important; }
    #industries-main #right-square1 {
      width: 600px; }
      #industries-main #right-square1:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px; }
      #industries-main #right-square1:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px; }
      #industries-main #right-square1 .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat; }
  #advanced-cloud #right-square img {
    width: 78% !important;
    height: 73% !important;
    border-radius: 75px !important; }
  #advanced-cloud1 #right-square img {
    width: 78% !important;
    height: 73% !important;
    border-radius: 75px !important; }
  #advanced-cloud1 #left-square {
    padding-top: 60px !important;
    padding-bottom: 75px !important; }
  #advanced-backap #right-square img {
    width: 95% !important;
    height: 57% !important;
    border-radius: 75px !important; }
  #advanced-backap #left-square {
    padding-top: 60px !important;
    padding-bottom: 100px !important; }
  #advanced-backap1 #right-square img {
    width: 95% !important;
    height: 57% !important;
    border-radius: 75px !important; }
  #advanced-backap1 #left-square {
    padding-top: 60px !important;
    padding-bottom: 100px !important; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #advanced-cloud #right-square img {
    width: 78% !important;
    height: 73% !important;
    border-radius: 75px !important; }
  #advanced-cloud1 #right-square img {
    width: 78% !important;
    height: 73% !important;
    border-radius: 75px !important; }
  #advanced-cloud1 #left-square {
    padding-top: 60px !important;
    padding-bottom: 75px !important; }
  #advanced-backap #right-square img {
    width: 95% !important;
    height: 57% !important;
    border-radius: 75px !important; }
  #advanced-backap #left-square {
    padding-top: 90px !important;
    padding-bottom: 100px !important; }
  #advanced-backap1 #right-square img {
    width: 95% !important;
    height: 57% !important;
    border-radius: 75px !important; }
  #advanced-backap1 #left-square {
    padding-top: 90px !important;
    padding-bottom: 100px !important; } }

#cyper-main {
  overflow: hidden !important;
  position: relative;
  height: 37rem; }
  #cyper-main .container-xl {
    height: 100%;
    position: relative;
    padding-left: 0; }
  #cyper-main #left-square {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 78%;
    background-size: cover;
    background-image: url(../images/Cyber-security/header.png);
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0 0 40px 0; }
    #cyper-main #left-square .row p {
      width: 87%;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.4;
      margin-left: 75px; }
    #cyper-main #left-square .left-content {
      margin-left: 30px; }
    #cyper-main #left-square h2 {
      margin-left: 74px;
      font-weight: 650;
      font-size: 6em;
      line-height: 1;
      margin-top: 250px;
      position: relative; }
      #cyper-main #left-square h2:before {
        content: " ";
        background-image: url(../images/about-us/pattern.png);
        position: absolute;
        top: -34px;
        left: -62px;
        z-index: -1;
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-size: contain;
        background-size: contain !important; }
    #cyper-main #left-square a {
      border-radius: 5px; }
    #cyper-main #left-square:after {
      content: " ";
      background-image: url(../images/Cyber-security/cyber-icon.png);
      position: absolute;
      top: 55%;
      right: 0;
      z-index: -1;
      width: 245px;
      height: 245px;
      background-repeat: no-repeat;
      background-size: contain !important;
      -webkit-transform: translateX(50%) translateY(-50%);
      transform: translateX(50%) translateY(-50%);
      right: -20px !important; }
  #cyper-main #right-square1 {
    width: 22%;
    height: 100%;
    padding-left: 60px;
    position: absolute;
    top: 0;
    right: 0; }
    #cyper-main #right-square1 .mid {
      position: absolute;
      bottom: -2px;
      right: 282px;
      width: 362px;
      height: 86%;
      z-index: 0;
      border-radius: 0 0 0 40px;
      padding-left: 60px;
      background-size: cover;
      background-image: url(../images/about-us/man.png);
      background-repeat: no-repeat; }
    #cyper-main #right-square1:before {
      content: "";
      background-image: url(../images/about-us/iTE.png);
      position: absolute;
      z-index: -1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: 2;
      width: 209px;
      height: 256px;
      right: 201px;
      bottom: -1px; }
    #cyper-main #right-square1:after {
      content: " ";
      background-image: url(../images/about-us/AM.png);
      position: absolute;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: contain;
      background-size: contain !important;
      bottom: 259px;
      z-index: -1;
      width: 180px;
      height: 228px;
      right: 214px;
      top: 109px; }
  @media (min-width: 768px) and (max-width: 1366.98px) {
    #cyper-main .mid {
      right: 64% !important; }
    #cyper-main #right-square1:before {
      right: 28% !important; }
    #cyper-main #right-square1:after {
      right: 33% !important; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    #cyper-main {
      height: 37rem; }
      #cyper-main #left-square {
        width: 86%; }
        #cyper-main #left-square h2 {
          margin-top: 122px;
          font-weight: 610;
          font-size: 4.5em;
          line-height: 0.9; }
      #cyper-main #right-square1:before {
        right: -32% !important; }
      #cyper-main #right-square1:after {
        right: -22% !important;
        top: 65px; }
      #cyper-main .mid {
        right: 6% !important; } }
  @media (max-width: 767.98px) {
    #cyper-main #left-square {
      width: 86%; }
      #cyper-main #left-square .left-content {
        align-items: flex-start !important; }
      #cyper-main #left-square h2 {
        margin-top: 208px;
        font-weight: 610;
        font-size: 3.5em;
        line-height: 0.9;
        margin-left: 16px; }
        #cyper-main #left-square h2:before {
          top: -51px;
          left: -34px;
          width: 39%;
          height: 275%; }
    #cyper-main #right-square1:before {
      width: 209px;
      height: 256px;
      right: -32% !important; }
    #cyper-main #right-square1:after {
      right: -22% !important;
      top: 93px; } }
  @media (max-width: 575.98px) {
    #cyper-main {
      height: 34rem; }
      #cyper-main .container-xl {
        padding: 0; }
      #cyper-main #left-square {
        width: 100% !important;
        height: 100% !important;
        border-radius: 0; }
        #cyper-main #left-square .left-content {
          display: flex;
          margin-left: 0 !important;
          align-items: center !important; }
        #cyper-main #left-square h2 {
          font-size: 2.3em !important;
          margin-top: -70px !important;
          margin-left: 0 !important;
          font-size: 2.3em !important;
          margin-top: 280px !important;
          margin-left: 0 !important; }
          #cyper-main #left-square h2:before {
            top: -51px;
            left: -34px;
            width: 39%;
            height: 350%; }
        #cyper-main #left-square p {
          width: 92% !important;
          margin-top: 0px !important;
          width: 92% !important;
          margin-top: 0 !important;
          margin-left: 5px !important; }
        #cyper-main #left-square div {
          padding-left: 25px !important; }
        #cyper-main #left-square:after {
          top: 41% !important;
          right: 25% !important;
          width: 140px !important;
          height: 200px !important; }
      #cyper-main #right-square1 {
        position: relative !important;
        border-radius: 0 !important;
        width: 100% !important;
        padding: 30px !important;
        height: 450px !important;
        text-align: center; }
        #cyper-main #right-square1:before {
          width: 150px;
          height: 203px;
          right: 0% !important;
          bottom: -94px; }
        #cyper-main #right-square1:after {
          right: 8% !important;
          top: 158px;
          width: 92px;
          height: 76%; }
        #cyper-main #right-square1 .mid {
          position: absolute;
          bottom: -96px;
          right: 30px;
          width: 253px;
          height: 84%;
          z-index: 0;
          border-radius: 0 0 0 40px;
          padding-left: 60px;
          background-size: cover;
          background-image: url(../images/about-us/man.png);
          background-repeat: no-repeat; } }

#cyper-form {
  height: 800px;
  position: relative; }
  #cyper-form .shared-frorm {
    top: 100px !important; }
  @media (max-width: 575.98px) {
    #cyper-form {
      height: 1150px !important; } }

#cyper-vendor {
  height: 1200px;
  overflow: hidden;
  position: relative;
  z-index: 2; }
  #cyper-vendor > .adv:after {
    content: " ";
    background-image: url(../images/Advanced-tech/img5.jpeg);
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 96%;
    height: 590px;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-top-left-radius: 75px; }
  #cyper-vendor > .oth:after {
    content: " ";
    background-image: url(../images/Advanced-tech/img7.jpeg);
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 96%;
    height: 590px;
    background-repeat: no-repeat;
    background-size: cover !important;
    border-top-left-radius: 75px; }
  #cyper-vendor .container-xl {
    padding: 0;
    position: relative !important; }
  #cyper-vendor .managed-text {
    position: absolute;
    left: 109px;
    top: 65px;
    font-size: 2.5rem;
    font-weight: 600;
    width: 100%;
    line-height: 1.2; }
    @media (max-width: 575.98px) {
      #cyper-vendor .managed-text {
        position: absolute;
        left: 38px;
        top: 45px;
        font-size: 1.8rem;
        font-weight: 600;
        width: 100%;
        line-height: 1.2; } }
  #cyper-vendor .managed-ul {
    position: absolute;
    left: 90px;
    top: 150px;
    line-height: 1.8;
    font-size: 21px; }
    @media (max-width: 575.98px) {
      #cyper-vendor .managed-ul {
        position: absolute;
        left: 15px;
        top: 150px;
        line-height: 1.8;
        font-size: 16px; } }
  #cyper-vendor #vendor {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2; }

@media (min-width: 1400px) and (max-width: 1570px) {
  .container-xl {
    max-width: 100%; }
  #about-main {
    height: 42rem; }
  .mid {
    width: 377px !important;
    height: 88% !important; } }

@media (min-width: 1570px) and (max-width: 1750px) {
  .container-xl {
    max-width: 100%; }
  #industries-main {
    height: 48rem !important; }
    #industries-main #right-square1 {
      width: 600px; }
      #industries-main #right-square1:after {
        width: 211px;
        height: 266px;
        right: 272px;
        top: 126px; }
      #industries-main #right-square1:before {
        width: 237px;
        height: 331px;
        right: 275px;
        bottom: -1px; }
      #industries-main #right-square1 .mid {
        position: absolute;
        bottom: -2px;
        right: 333px;
        width: 452px;
        height: 88%;
        z-index: 0;
        border-radius: 0 0 0 40px;
        padding-left: 60px;
        background-size: cover;
        background-image: url(../images/about-us/man.png);
        background-repeat: no-repeat; } }

@media (min-width: 1750px) {
  .container-xl {
    max-width: 1750px; }
  #cyper-framework .left h2 {
    margin-top: 20px !important; }
  #cyper-framework .right .circle3 li:nth-child(3) span {
    left: 24%;
    top: 78%; } }

@keyframes right {
  from {
    left: -35% !important; }
  to {
    left: 0 !important; } }

@keyframes left {
  0% {
    right: 0%; }
  100% {
    right: -200%; } }

.test {
  display: none; }
