.shared-frorm {
  position: absolute;
  bottom: 30px;
  right: 4%;
  width: 92%;
  height: 77%;
  background-image: url(../images/Advanced-tech/Assessment-bg.png);
  border-radius: 90px;
  padding: 75px 50px;

  h3 {
    font-size: 3em;
    font-weight: 600;
    width: 70%;
    margin-bottom: 50px;
  }

  .form-row {
    margin-top: 10px;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  .form-control {
    background-color: white;
    color: black;
    border-radius: 10px;
    height: 52px;
    width: 90%;
  }

  label {
    font-size: 1.3em;
    font-weight: 300;
  }

  button {
    width: 150px;
    height: 50px;
    border-radius: 8px;
    margin-top: 20px;
    font-size: 16px;
  }


  @include media-breakpoint-only(xs) {
    .form-control {
      width: 100% !important;
    }

  }
  
}